<template>
  <!-- v-if="$route.name != 'album' && isShowBottomBar" -->
  <div
    class="bottom-bar"
    v-if="isShowBottomBar && mvIsHide"
    @click="handleClickDefaultBottombar"
    v-log="{
      event_type: 'show',
      event_name: 30019,
      event_data: {
        str1: '通用',
        str2: '歌词条',
        str3: '展示歌词条',
        str4: 'show',
      },
    }"
  >
    <div class="bottom-bar-btn" v-show="paused">
      <svg
        class="bottom-bar-img margin_26"
        @click.stop="handleVideoPlay"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.7413 18.4253C35.7991 20.9225 37.828 22.171 37.828 23.9998C37.828 25.8286 35.7991 27.0772 31.7413 29.5743L19.5757 37.0608C15.1602 39.7781 12.9524 41.1367 11.2761 40.2C9.5998 39.2633 9.5998 36.671 9.5998 31.4863V16.5133C9.5998 11.3286 9.5998 8.73632 11.2761 7.79962C12.9524 6.86292 15.1602 8.22156 19.5757 10.9388L31.7413 18.4253Z"
          fill="#1D1D1F"
        />
      </svg>
    </div>
    <div class="bottom-bar-btn" v-show="!paused">
      <svg
        class="bottom-bar-img"
        @click.stop="handleVideoPause"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 6.99902C11.3431 6.99902 10 8.34217 10 9.99902V37.999C10 39.6559 11.3431 40.999 13 40.999C14.6569 40.999 16 39.6559 16 37.999V9.99902C16 8.34217 14.6569 6.99902 13 6.99902ZM35 6.99902C33.3431 6.99902 32 8.34217 32 9.99902V37.999C32 39.6559 33.3431 40.999 35 40.999C36.6569 40.999 38 39.6559 38 37.999V9.99902C38 8.34217 36.6569 6.99902 35 6.99902Z"
          fill="#1D1D1F"
        />
      </svg>
    </div>
    <div class="bottom-bar-btn">
      <svg
        class="bottom-bar-img"
        @click.stop="handleVideoNext"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.7273 6.5459C37.221 6.5459 36 7.76694 36 9.27317V22.7857C35.8592 22.6019 35.6819 22.438 35.4682 22.3044L15.06 9.54931C13.7279 8.71675 12 9.67443 12 11.2453V36.7556C12 38.3265 13.7279 39.2841 15.06 38.4516L35.4682 25.6964C35.6819 25.5629 35.8592 25.399 36 25.2152V38.7277C36 40.2339 37.221 41.455 38.7273 41.455C40.2335 41.455 41.4545 40.2339 41.4545 38.7277V9.27317C41.4545 7.76694 40.2335 6.5459 38.7273 6.5459Z"
          fill="#1D1D1F"
        />
      </svg>
    </div>
    <div class="bottom-bar-lyc theme-color">
      <div class="bottom-bar-lyc-item">
        <template v-if="!lycListNum || currIrcIndex === -1">
          <p
            v-if="!isAlreadyEnterMv && paused && orderedListNum > 0"
            class="bottom-bar-lyc-item-p"
            style="color: #1d1d1f"
          >
            已点{{ orderedListNum }}首歌，点我开始演唱
          </p>
          <p v-else-if="orderedListNum > 0" class="bottom-bar-lyc-item-p">
            {{ lycName }}
          </p>
          <p v-else class="bottom-bar-lyc-item-p" style="color: #1d1d1f">
            还没有要演唱的歌曲哦！快去点歌吧！
          </p>
        </template>
        <p v-else class="bottom-bar-lyc-item-p animate-fade-in">
          <span
            v-for="(text, ind) in lycTxt.irc"
            :key="ind + lycTxt.t"
            :class="{
              'active-scan': true,
              'active-pause': paused,
            }"
            :style="{
              'animation-duration': `${lycTxt.textT[ind]}ms`,
              'animation-delay': `${lycTxt.delayTime[ind]}ms`,
            }"
          >
            {{ text.trim() }}
            {{ text.includes(' ') ? '&nbsp;' : '' }}
          </span>
        </p>
      </div>
      <div class="flag">MV</div>
    </div>
  </div>
  <OperationSus
    :class="botPos"
    v-if="isShowOperationSus"
    @close="handleCloseOperationSus"
  />
</template>

<script>
import getComponentLrcData from '@/components/mv/utils'
import OperationSus from '@/components/operation'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useSongItem from '@/composables/useSongItem'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import Toast from '@/utils/toast'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

let isPlaying = false
export default {
  name: 'BottomBar',
  components: {
    OperationSus,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    // const { showLoginQrcode, isLogin } = useLogin()
    const { browserType } = useShareBrowserSize()
    const { orderSong } = useSongItem()
    const mvIsHide = computed(() => store.state.mvIsHide)

    // 未进入过欢唱页，不显示歌词条
    const isAlreadyEnterMv = computed(() => store.state.videoInitAutoPlay)

    const paused = computed(() => store.state.videoPaused)
    const videoPlayerHistory = computed(() => store.state.videoPlayerHistory)
    const orderedList = computed(() => store.state.orderedList)
    const orderedListNum = computed(() => orderedList.value.length)
    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap)
    // const isShowBottomBar = computed(
    //   () => !!(mvIsHide.value && orderedListNum.value)
    // )
    const mvCoreTop = computed(() => store.state.mvCoreTop)

    // watch(mvCoreTop, (val) => {
    //   console.log('mvCoreTop', String(val))
    // })

    const isShowBottomBar = computed(() => store.state.isShowBottomBar)
    const isShowOperationSus = ref(true)
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber))
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const threshold = computed(() =>
      browserType.value === 'landscape' ? -277 : -228
    )
    const botPos = computed(() => {
      // if (isShowBottomBar.value) {
      // if (route.name === 'search') return 'bot-pos-search'
      // if (route.name === 'singer') return 'bot-pos-singer'
      // console.log(
      //   'isShowBottomBar',
      //   route.name === 'home',
      //   !isShowBottomBar.value,
      //   Math.abs(mvCoreTop.value) > threshold.value,
      //   mvCoreTop.value < threshold.value
      // )
      if (
        route.name === 'home' &&
        !isShowBottomBar.value &&
        // Math.abs(mvCoreTop.value) < threshold.value &&
        mvCoreTop.value > threshold.value
      )
        return 'bot-pos-home'
      return 'bot-pos'
      // }
      // return ''
    })

    const lycList = computed(() => {
      return getComponentLrcData(store.state.videoPlayerHistory.songItemLrc)
    })
    const lycListNum = computed(() => lycList.value.length)
    const currIrcIndex = computed(() => store.state.currIrcIndex)
    const lycTxt = computed(() => {
      if (lycListNum.value) {
        if (currIrcIndex.value) {
          return lycList.value[currIrcIndex.value]
        }
        return lycList.value[0]
      }
      return []
    })

    let lycName = computed(() => {
      if (orderedListNum.value) {
        if (currIrcIndex.value === -1 || !lycListNum.value) {
          return `${orderedList.value[0].music_name} - ${orderedList.value[0].singer}`
        }
      }
      return ''
    })

    const root = ref(null)
    const { showVipQrcode } = useVip()
    // 切歌校验
    const checkOrderedHasSong = () => {
      // if (!isLogin.value) {
      //   showLoginQrcode()
      //   return false
      // }
      // 切歌时当前未播放歌曲的情况
      if (!videoPlayerHistory.value.songItem.songid) {
        if (Object.keys(orderedSongIdMap.value).length) {
          orderSong(orderedList.value[0], {
            position: 'recovery',
            isPushOrdered: false,
          })
          return false
        }
        if (route.name != 'climax') {
          Toast('请点播歌曲')
        }
        return false
      }
      return true
    }

    const checkMvIsPlaying = () => {
      // if (!isLogin.value) {
      //   showLoginQrcode()
      //   return
      // }
      if (!videoPlayerHistory.value.songItem.songid) {
        if (Object.keys(orderedSongIdMap.value).length) {
          orderSong(orderedList.value[0], {
            position: 'recovery',
            isPushOrdered: false,
          })
          return
        }
        if (route.name != 'climax') {
          Toast('请点播歌曲')
        }
        return
      }
    }

    const handleVideoPlay = () => {
      checkMvIsPlaying()
      eventBus.emit('handle-video-play')
      store.commit('UPDATE_MV_INIT_AUTOPLAY', 1)
      store.commit('UPDATE_IS_SING_STATUS', true)
      sendLog({
        event_type: '10000~50000',
        event_name: 30020,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '播放',
          str4: 'click',
        },
      })
    }

    const handleVideoPause = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30205,
        event_data: {
          str1: '通用',
          str2: '歌词条-暂停',
          str3: '点击',
          str4: 'click',
        },
      })
      checkMvIsPlaying()
      eventBus.emit('handle-video-pause')
    }

    const handleVideoNext = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30205,
        event_data: {
          str1: '通用',
          str2: '歌词条-下一首',
          str3: '点击',
          str4: 'click',
        },
      })
      if (!checkOrderedHasSong()) return
      eventBus.emit('handle-video-next')
      store.commit('UPDATE_MV_INIT_AUTOPLAY', 1)
      store.commit('UPDATE_IS_SING_STATUS', true)
    }

    const handleCloseOperationSus = () => {
      isShowOperationSus.value = false
    }

    const handleClickDefaultBottombar = () => {
      // console.log(
      //   'handleClickDefaultBottombar',
      //   freeVipNumber.value,
      //   orderedListNum.value
      // )
      sendLog({
        event_type: '10000~50000',
        event_name: 30205,
        event_data: {
          str1: '通用',
          str2: '歌词条',
          str3: '点击交互区进入欢唱',
          str4: 'click',
        },
      })

      if (freeVipNumber.value && orderedListNum.value) {
        orderSong(orderedList.value[0], {
          position: 'recovery',
          isPushOrdered: false,
          enabledMvShow: true,
          useFirstSongAutoPlay: true,
        })
        store.commit('UPDATE_IS_SING_STATUS', true)
        store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
        return
      }
      // if (!orderedListNum.value && !isVip.value) {
      //   showVipQrcode({})
      //   return
      // }
      if (!orderedListNum.value) return
      if (
        Object.keys(orderedSongIdMap.value).length &&
        !isAlreadyEnterMv.value
      ) {
        orderSong(orderedList.value[0], {
          position: 'recovery',
          isPushOrdered: false,
          enabledMvShow: true,
          useFirstSongAutoPlay: true,
        })
        store.commit('UPDATE_IS_SING_STATUS', true)
        store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
        return
      }
      orderSong(videoPlayerHistory.value.songItem, {
        position: 'recovery',
        isPushOrdered: false,
        enabledMvShow: true,
        useFirstSongAutoPlay: true,
      })
      store.commit('UPDATE_IS_SING_STATUS', true)
      store.dispatch('searchTips/updateIsShowSingTips', false) // 关闭tips弹窗
    }
    watch(isShowBottomBar, (val) => {
      console.log(val)
      if (val) {
        sendLog({
          event_type: '10000~50000',
          event_name: 10095,
          event_data: {
            str1: '任意页',
            str2: '歌词条',
            str3: '展示',
            str4: 'show',
          },
        })
      }
    })

    watch(
      route,
      (newVal) => {
        if (newVal.name === 'climax') {
          isPlaying = !paused.value
          handleVideoPause()
        } else if (isPlaying) {
          handleVideoPlay()
          isPlaying = false
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
    const handleClickStartSing = () => {
      handleClickDefaultBottombar()
    }

    onMounted(() => {
      eventBus.on('handle-click-start-sing', handleClickStartSing)
    })
    const isShowMini = computed(
      () => store.state.mvIsHide && store.state.orderedList.length == 0
    )
    return {
      root,
      isShowBottomBar,
      botPos,
      paused,
      lycTxt,
      lycName,
      lycListNum,
      currIrcIndex,
      handleVideoPlay,
      handleVideoPause,
      handleVideoNext,
      isShowOperationSus,
      handleCloseOperationSus,
      isAlreadyEnterMv,
      orderedListNum,
      handleClickDefaultBottombar,
      mvIsHide,
      route,
      isShowMini,
    }
  },
}
</script>

<style lang="stylus" scoped>
.bottom-bar
  width 100%
  height 110px
  display flex
  flex-direction row
  align-items center
  padding 0 286px
  position fixed
  bottom 0
  left 0
  z-index 5
  background: rgba(200, 205, 218, 1)
  backdrop-filter blur(200px)
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-right 230px
  &-btn
    width 110px
    height 110px
    display flex
    align-items center
    justify-content center
    position absolute
    bottom 0
    left 42px
    &:nth-child(3)
      left 176px
  &-img
    width 44px
    height 44px
  &-lyc
    flex 1
    height 100%
    display flex
    align-items center
    justify-content center
    font-size 40px
    color #1D1D1F
    @media screen and (max-width 1200px) and (min-height 1200px)
      font-size 32px
    .active-scan
      background #1D1D1F66 -webkit-linear-gradient(left, #1D1D1F, #1D1D1F) no-repeat 0 0
      -webkit-text-fill-color transparent
      -webkit-background-clip text
      background-size 0 100%
      animation scan 2s linear
      animation-fill-mode forwards
    .active-load
      background-size 100% 100%
    .active-pause
      animation-play-state paused !important
    .animate-fade-in
      animation fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both
    p
      font-weight 400
    &-item
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center
      &-p
        max-width 1348px
        height 100%
        line-height 110px
        display flex
        align-items center
        flex-wrap wrap
        color #1D1D1F
        @media screen and (max-width 1200px) and (min-height 1200px)
          max-width 850px
          font-size 32px
      span
        font-weight 400
        height 42px
        line-height 42px

  .margin_26
    margin 0 26px
  .margin_left_20
    margin-left 20px
  .flag
    background #313137
    padding 7px 8px
    border-radius 8px
    color #F9F8FF
    font-weight 700
    font-size 22px
    position absolute
    right 71px
    top 35px
  @keyframes scan
    0%
      background-size 0 100%
    100%
      background-size 100% 100%
  @keyframes fade-in
    0%
      opacity 0.2
    100%
      opacity 1

.bot-pos
  bottom 134px !important
.bot-pos-home
  bottom 24px !important
// .bot-pos-search
//   bottom 140px !important
// .bot-pos-singer
//   bottom 110px !important
</style>
