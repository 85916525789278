<template>
  <div class="progress-bg2">
    <div class="video-progress">
      <span class="current-time">{{ formatTime(currentTime) }}</span>
      <div class="progress-bar">
        <div class="progress-background"></div>
        <div class="progress2" :style="{ width: progressWidth }"></div>
        <div class="progress-dot" :style="{ left: progressWidth }"></div>
      </div>
      <span class="duration">{{ formatTime(duration) }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  currentTime: {
    type: Number,
    default: 0,
  },
  duration: {
    type: Number,
    default: 0,
  },
});

const progressWidth = computed(() => {
  return `${(props.currentTime / props.duration) * 100}%`;
});

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

// const handleProgressClick = (event) => {
//   const rect = event.target.getBoundingClientRect();
//   const offsetX = event.clientX - rect.left;
//   const width = rect.width;
//   const newTime = (offsetX / width) * props.duration;
//   emit('update:currentTime', newTime);
// };

// const emit = defineEmits(['update:currentTime']);
</script>

<style lang="stylus" scoped>
.progress-bg2
  position fixed
  left 0
  bottom 0
  width 100vw
  height 150px
  z-index 9!important
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
  @media screen and (max-width 1200px) and (min-height 1200px)
    bottom 960px!important
.video-progress
  position absolute
  bottom 36px
  left 60px
  right 60px
  display flex
  z-index 10
  align-items center
  justify-content space-between
  color white
  font-size: 32px;
  font-weight: 400;
  @media screen and (max-width 1200px) and (min-height 1200px)
    left 20px
    right 20px
  span
    display inline-block
    width 130px
    text-align center
.progress-bar
  position relative
  flex-grow 1
  height 8px
  background-color black
  margin 0 30px
  cursor pointer
  border-radius 2px

.progress-background
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background-color black

.progress2
  position absolute
  top 0
  left 0
  height 100%
  background-color white
  transition width 0.2s ease

.progress-dot
  position absolute
  top 50%
  transform translate(-50%, -50%)
  width 18px
  height 20px
  background-color white
  border-radius 4px
  transition left 0.2s ease
</style>
