<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="lower-vip-m-qrcode"
      :style="{ backgroundImage: `url(${currBgImg[selected]})` }"
    >
      <div @click="handleCloseModal" class="close"></div>

      <div class="m-vip-tips">
        <p>
          <span>{{ selected == 1 ? 2 : 1 }}</span>
          年
        </p>
        <p>微信扫码登录支付</p>
        <p>有效期至-{{ expirationDate }}</p>
        <div class="m-vip-tips-qrcode">
          <img :src="qrCodeURL" />
        </div>
      </div>

      <div class="pkg">
        <div class="pkg-item" @click="handleChoosePkg(0)"></div>
        <div class="pkg-item" @click="handleChoosePkg(1)"></div>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import store from '@/store'
import { getUserRoleTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import { computed, onBeforeMount, onMounted, ref, toRefs } from 'vue'

export default {
  name: 'LowerVipMobileQrcode',
  components: {
    CommonModal,
  },
  props: {
    songid: {
      type: Number,
      default: 0,
    },
    log: {
      type: String,
      default: '',
    },
    fromType: {
      type: String,
      default: '',
    },
    logInfo: {
      type: Object,
      default: () => {},
    },
    closeCallback: {
      type: Function,
      default: null,
    },
    fr: {
      type: Number,
      default: null,
    },
    isVideoEnd: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { songid, log, logInfo, closeCallback, isVideoEnd, fr } = toRefs(props)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )
    const bgImgs = {
      new: [
        'https://qncweb.ktvsky.com/20241205/vadd/323fc40ab1716e6c3ac881988df0c092.png',
        'https://qncweb.ktvsky.com/20241205/vadd/f8db64f0b4c28b330b1ad2bcc82f4c0f.png',
      ], // 新用户
      old: [
        'https://qncweb.ktvsky.com/20241205/vadd/3450325fc6a7814d5e1fd9007e1c80ce.png',
        'https://qncweb.ktvsky.com/20241205/vadd/fff510a969a58e41b950b9cc5961c0ce.png',
      ], // 老用户
    }
    // 视频播放完成后图片
    const videoEndImgs = {
      new: [
        'https://qncweb.ktvsky.com/20250120/vadd/6ef9f4b3a79d43935636858facf522a2.png',
        'https://qncweb.ktvsky.com/20250120/vadd/b3fe0bc95bc7b6037447c121af23a822.png',
      ], // 新用户
      old: [
        'https://qncweb.ktvsky.com/20250120/vadd/83852745db68deae30e2ab41b3047551.png',
        'https://qncweb.ktvsky.com/20250120/vadd/c35ce17ce1a1ab374568153c3a1b7b64.png',
      ], // 老用户
    }
    const isNewUser = ref(false)
    const currBgImg = ref(['', ''])

    const selected = ref(0)

    const end_time = computed(() => store.state.vipInfo.end_time)

    const isVip = computed(() => !!end_time.value)

    const userType = computed(() => store.state.userInfo.userType)

    const expirationDate = computed(() => {
      const currentDate = isVip.value ? new Date(end_time.value) : new Date()
      const expire = addDays(currentDate, selected.value === 0 ? 365 : 730)

      return format(expire, 'yyyy.MM.dd')
    })

    const getVipQrcode = async () => {
      // console.log('vipQRcode:', songid.value)
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const logName = log.value ? log.value : '其他'
        let logEvent = null
        if (payLogFrom.has(logName)) {
          logEvent = payLogFrom.get(logName).m
        } else if (vipLogFrom.has(logName)) {
          logEvent = vipLogFrom.get(logName)
        }
        console.log('PAY LOG:', logEvent, log.value, fr.value)
        let from =
          ['试唱老用户付费弹窗', '试唱新用户付费弹窗'].includes(log.value)
            ? fr.value
            : log.value === '免费歌曲主动弹'
              ? vipLogFrom.get('免费歌曲主动弹-lower-m')
              : 1900
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&songid=${
            songid.value ? songid.value : ''
          }&log=${from}&ut=${userType.value}`
        )
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleCloseModal = () => {
      // 手动关闭时统一做一次用户信息更新 处理用户在个人中心模块-前往开通时，关闭弹窗刷新用户会员信息
      store.dispatch('getCarplayInfo')
      root.value.hide()
      // eventBus.emit('handle-close-vip-qrcode')

      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')
      sendLog({
        event_type: '10000~50000',
        event_name: 10094,
        event_data: {
          str1: '任意页',
          str2: 'VIP弹窗',
          str3: '关闭弹窗',
          str4: 'click',
        },
      })
      closeCallback.value && closeCallback.value()
    }

    const handleChoosePkg = (id) => {
      selected.value = id
    }

    onBeforeMount(getVipQrcode)

    onMounted(() => {
      isNewUser.value = getUserRoleTag()
      const newBgimgs = isVideoEnd.value ? videoEndImgs : bgImgs
      currBgImg.value = isNewUser.value == 2 ? newBgimgs.old : newBgimgs.new
      // console.log(tag)
      sendLog({
        event_type: '30000～35000',
        event_name: 30325,
        event_data: {
          str1: '通用',
          str2: `新付费弹窗1`,
          str3: '展示',
          str4: 'show',
          str5: '未登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })
    })

    return {
      bgImgs,
      videoEndImgs,
      currBgImg,
      selected,
      qrCodeURL,
      root,
      isLogin,
      expirationDate,
      handleChoosePkg,
      handleCancel,
      handleCloseModal,
    }
  },
}
</script>

<style lang="stylus" scoped>
.lower-vip
  &-m-qrcode
    position relative
    width 1000px
    height 720px
    padding-top 0 !important
    background url('') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .close
      position absolute
      top 25px
      right 30px
      left unset!important
      width 40px
      height 40px
    .m-vip-tips
      width 176px
      height 350px
      position absolute
      top 166px
      right 114px
      display flex
      flex-direction column
      align-items center
      p
        &:nth-child(1)
          font-size 22px
          font-weight 500
          color rgba(136, 55, 0, 1)
          span
            font-size 64px
        &:nth-child(2)
          font-size 22px
          font-weight 400
          color rgba(0, 0, 0, 0.8)
          margin 8px 0
        &:nth-child(3)
          font-size 16px
          font-weight 400
          color rgba(0, 0, 0, 0.6)
          margin-bottom 8px
      &-qrcode
        // position absolute
        // bottom 3px !important
        // left 50%
        // margin-left -70px
        width 160px
        height 160px
        display flex
        justify-content center
        align-items center
        background #FFFFFF
        border-radius 12px
        img
          width 146px
          height 146px
          border-radius 5px
    .pkg
      position absolute
      top 220px
      left 114px
      width 440px
      height 252px
      display flex
      .pkg-item
        width 200px
        height 252px
</style>
