import { createComponent } from '@/components/modal/utils/index.js'
import MobileVipModalVue from './m.vue'
import ZdVipModalVue from './zd.vue'

export default function useLowerVipQrcode(globalProps = {}, globalSlots = {}) {
  function show(props = globalProps, slots = globalSlots) {
    const forceProps = {
      programmatic: true,
      lockScroll: true,
      isFullPage: true,
      active: true,
    }

    const propsData = { ...globalProps, ...props, ...forceProps }
    const container = document.body

    const mergedSlots = { ...globalSlots, ...slots }
    const instance = createComponent(
      props.isLogin ? ZdVipModalVue : MobileVipModalVue,
      propsData,
      container,
      mergedSlots
    )

    return {
      hide: instance.refs.root.hide,
    }
  }
  return {
    show,
  }
}
