<template>
  <div class="mv-core" :class="[!mvIsHide && 'mv-core-full-screen']">
    <MvPageComponent v-show="!isShowMini" ref="mvPageComponentRef" />
  </div>
</template>

<script>
import MvPageComponent from '@/components/mv/index.vue'
import eventBus from '@/utils/event-bus'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MvCore',
  components: {
    MvPageComponent,
  },
  props: {},
  setup() {
    const store = useStore()
    const isShowMini = computed(() => store.state.miniMv.isShowMini)
    const orderedList = computed(() => store.state.orderedList)
    const videoPaused = computed(() => store.state.videoPaused)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const mvIsHide = computed(() => store.state.mvIsHide)
    const freeVipNumber = computed(() => store.state.freeVipNumber)
    const mvPageComponentRef = ref(null)

    watch(
      [mvIsHide, orderedList, isVip],
      () => {
        if (!videoPaused.value && orderedList.value.length) {
          store.dispatch('miniMv/setIsShowMini', false)
          return
        }

        if (document.querySelector('.vip-modal-content')) {
          return
        }

        const shouldShowMini =
          mvIsHide.value &&
          (orderedList.value.length === 0 ||
            (orderedList.value[0].is_vip &&
              !isVip.value &&
              !freeVipNumber.value))

        store.dispatch('miniMv/setIsShowMini', shouldShowMini)

        if (shouldShowMini) {
          eventBus.emit('handle-video-pause')
        }
      },
      {
        deep: true,
      }
    )

    const onCloseVipQrcode = () => {
      store.dispatch('miniMv/setIsShowMini', true)
    }

    onMounted(() => {
      eventBus.on('handle-close-vip-qrcode', onCloseVipQrcode)
    })

    onUnmounted(() => {
      eventBus.off('handle-close-vip-qrcode', onCloseVipQrcode)
    })

    return {
      mvIsHide,
      mvPageComponentRef,
      isShowMini,
    }
  },
}
</script>

<style lang="stylus" scoped>
.mv-core
  // position: fixed;
  position relative
  // top: 162px;
  // right: 80px;
  // z-index: 2;
  // transform: translateY(var(--mv-top, 0))
  // transition: transform 0.05s cubic-bezier(0.33, 1, 0.68, 1)
  // will-change: transform
  @media screen and (max-width 1200px) and (min-height 1200px)
    // top 162px
    // top 0px;
    // right: 54px;
.mv-core-full-screen
  position fixed
  top 0px;
  left 0
  right 0
  bottom 0
  transform translateY(0)
</style>
