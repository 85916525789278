<template>
  <div class="promotion-banner" :style="{ backgroundImage: `url(${currBg})` }">
    <div class="left">
      <div v-if="isLogin" class="login" :class="{ vip: isVip }">
        <div class="user-info" @click="handleGoMine">
          <div class="avatar">
            <img :src="userInfo.avatar" />
            <span class="vip-icon"></span>
          </div>
          <div class="nickname">{{ userInfo.username }}</div>
          <p v-if="notActivityFreeVip" class="vip-desc">{{ vipDesc }}</p>
          <p v-else>限时会员：{{ remainingTime }}</p>
        </div>
        <div class="vip-entry" @click="handleClickPromotion">
          <div class="left">
            <template v-if="isExpire || isVip">
              <p>
                {{ isVip ? '续费低至' : '8大权益低至' }}<span>0.2元/天</span>
              </p>
              <h3>
                {{ isVip ? '倾心回馈，感恩一路有你' : '会员权益全新升级！' }}
              </h3>
            </template>
            <template v-else>
              <p>您有一张VIP卡待领取</p>
              <h3 class="new">8项会员权益，尽享奢华体验</h3>
            </template>
          </div>
          <div class="right">去看看</div>
        </div>
      </div>

      <div v-else class="unlogin">
        <div class="code">
          <div class="qrcode">
            <img :src="qrCodeURL" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import { addDays, differenceInMilliseconds, isAfter, parseISO } from 'date-fns'
import get from 'lodash/get'
import { CountDown, Toast } from 'vant'
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    [CountDown.name]: CountDown,
  },
  setup() {
    const { getQRCodeURL } = useQRCode()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { showVipQrcode } = useVip()

    const bgImgs = {
      unlogin:
        'https://qncweb.ktvsky.com/20241226/vadd/2fae126a292a71feeb8f105889d74ed7.png',
      // 'https://qncweb.ktvsky.com/20241220/vadd/58869ba62145449ab1ec9352e542fbe9.png',
      // 'https://qncweb.ktvsky.com/20241128/vadd/d3cf961790d774e1790ab78e01e4568e.png',
      isVip:
        'https://qncweb.ktvsky.com/20241128/vadd/89adc00a395d61aba09bceb4e6d13b0f.png',
      noVip:
        'https://qncweb.ktvsky.com/20241226/vadd/c9e352cf82bc7533d262d889ac1e5c56.png',
    }

    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    // const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
    const isExpire = computed(() => vipInfo.value.expire)
    const isClimaxFree = computed(() => !store.state.climax.freeSing)
    const isShowClimax = computed(() => !isVip.value && isClimaxFree)
    const userType = computed(() => store.state.userInfo.userType)

    const currBg = computed(() => {
      if (!isLogin.value) return bgImgs.unlogin
      if (isVip.value) return bgImgs.isVip
      return bgImgs.noVip
    })

    const vipFreeActivity = computed(() => store.state.act.vipFreeActivity)

    const notActivityFreeVip = computed(() => {
      if (!vipFreeActivity.value) return true
      const activityEndTime = addDays(parseISO(vipFreeActivity.value), 3)
      return isAfter(new Date(), activityEndTime)
    })

    const vipDesc = computed(() => {
      if (isVip.value) {
        const endDate = vipInfo.value.end_time
          .split(' ')[0]
          .replaceAll('-', '.')
        return `会员有效期至: ${endDate}`
      }
      return '您的会员已过期'
    })

    const remainingTime = ref('')
    let timer = null

    const activityVipEndTime = computed(() => {
      if (!vipFreeActivity.value) return null
      return addDays(parseISO(vipFreeActivity.value), 3)
    })

    const updateRemainingTime = () => {
      if (!activityVipEndTime.value) {
        if (timer) {
          clearInterval(timer)
          timer = null
        }
        remainingTime.value = ''
        return
      }

      const now = new Date()
      const diff = differenceInMilliseconds(activityVipEndTime.value, now)
      console.log(activityVipEndTime.value, 'activityVipEndTime.value test')
      console.log(now, 'now test')
      console.log(diff, 'diff test')
      if (diff <= 0) {
        remainingTime.value = '00:00:00'
        if (timer) {
          clearInterval(timer)
          timer = null
        }
        // update vip info
        store.dispatch('getCarplayInfo')
        // 开启开屏弹窗
        showVipQrcode({
          log: '我的入口-头像',
          isLogin: isLogin.value,
          fr: 1891,
        })

        store.dispatch('act/resetVipFreeActivity')
        return
      }

      const hours = Math.floor(diff / 3600000)
      const minutes = Math.floor((diff % 3600000) / 60000)
      const seconds = Math.floor((diff % 60000) / 1000)

      remainingTime.value = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    watch(vipFreeActivity, (val) => {
      console.log(val, 'vipFreeActivity')
      if (val) {
        updateRemainingTime()
        if (timer) clearInterval(timer)
        timer = setInterval(updateRemainingTime, 1000)
      }
    })

    onMounted(() => {
      // test
      // const now = new Date();
      // const test = "2024-09-17 18:30:08";
      // const testDate = parseISO(test);
      // const testdate1 = addDays(testDate, 3);
      // const testDiff = differenceInMilliseconds(testdate1, now);
      // console.log(testdate1, "testdate1");
      // console.log(now, "testnow");
      // console.log(testDiff, "testDiff");

      updateRemainingTime()
      timer = setInterval(updateRemainingTime, 1000)
    })

    // const preferentialTime = ref(30 * 60 * 1000);
    // const timeData = ref({});
    // const userTime = ref(null);

    onUnmounted(() => {
      if (timer) clearInterval(timer)
    })

    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const banners = ['Banner 1', 'Banner 2', 'Banner 3']

    const getLoginQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'login_qr', '')) {
        const loginUrl = `${data.pay_qr}&log=${
          payLogFrom.get('我的入口-头像').m
        }`
        const qrCodeData = await getQRCodeURL(loginUrl)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleGoMine = () => {
      router.push({
        name: 'mine',
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10010,
        event_data: {
          str1: '首页',
          str2: '我的入口',
          str3: '进入我的',
          str4: 'click',
        },
      })
    }

    const handleClickPromotion = () => {
      showVipQrcode({
        log: '扫码登录-我的',
        isLogin: isLogin.value,
        fr: 1740,
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30237,
        event_data: {
          str1: '首页',
          str2: '用户卡片运营位',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
    }

    const handleTimeFinish = () => {
      store.dispatch('climax/updateFreeSing', true)
    }

    const handleClickBanner = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30235,
        event_data: {
          str1: '首页',
          str2: '“特别定制”歌单',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
      router.push({
        name: 'songList',
        query: {
          name: '“特”别定制，会员专享歌单',
          image:
            'https://qncweb.ktvsky.com/20241224/vadd/21217d957b4cc5a65530dc0e8c362217.png',
          // 'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
        },
      })
    }

    onBeforeMount(() => {
      if (!isLogin.value) {
        getLoginQrcode()
      }
    })

    // watch(
    //   route,
    //   (val) => {
    //     console.log(val.name, "route");
    //     // if (val.name === 'climax') {
    //     //   nextTick(() => userTime.value.start());
    //     // } else {
    //     //   nextTick(() => userTime.value.pause());
    //     // }
    //   },
    //   { deep: true, immediate: true }
    // );

    watch(isLogin, (val) => {
      if (!val) {
        getLoginQrcode()
      }
    })

    return {
      currBg,
      banners,
      qrCodeURL,
      isLogin,
      userInfo,
      vipDesc,
      isVip,
      handleGoMine,
      isExpire,
      isClimaxFree,
      handleTimeFinish,
      handleClickPromotion,
      isShowClimax,
      handleClickBanner,
      notActivityFreeVip,
      activityVipEndTime,
      remainingTime,
    }
  },
}
</script>

<style lang="stylus" scoped>
.promotion-banner
  width 445px
  height 420px
  border-radius 32px
  display flex
  margin-right 24px
  position relative
  background url('') no-repeat // rgba(255, 255, 255, 0.9)
  background-size 100% 100%
  background-position center
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 400px
    height 100%
    margin-right 0px
  .left
    width 100%
    height 100%
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex 1
      height 100%
      width 100%
      margin-top 0px
    .login
      width 100%
      height 100%
      margin 0
      padding 32px 24px 18px 28px
      position relative
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 28px 0px 14px
        margin 0
        position static
        font-size 16px
        height 100%
        display flex
        flex-direction column
      .vip-entry
        width 100%
        height 68px
        margin-top 64px
        display flex
        justify-content space-between
        align-items center
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin 21px auto 0
          padding-left 20px
        .left
          flex 1
          margin-top 0

          p
            font-size var(--font-size-26)
            font-weight 700
            color rgba(42, 44, 53, 1)
            @media screen and (max-width 1200px) and (min-height 1200px)
              font-size 24px

            span
              color #D80000
              font-weight 700


          h3
            font-size var(--font-size-xsmall)
            font-weight 400
            display flex
            align-items center
            color rgba(29, 29, 31, 0.5)
            @media screen and (max-width 1200px) and (min-height 1200px)
              font-size 18px

        .right
          width 116px
          height 68px
          font-size 24px
          display flex
          align-items center
          justify-content center
          background-color rgba(160, 74, 240, 1)
          color rgba(255, 255, 255, .8)
          border-radius 20px
          margin-right 0
          @media screen and (max-width 1200px) and (min-height 1200px)
            font-size 24px
            widht 108px
            height 61px

      .user-info
        text-align left
        color rgba(255, 255, 255, 0.6)
        font-size 20px
        @media screen and (max-width 1200px) and (min-height 1200px)
          height calc(284px - 28px)
          padding-left 28px
        .avatar
          width 96px
          height 96px
          position relative
          &:after
            content ""
            width 46px
            height 24px
            border-radius: 100px;
            background-color: none;
            position absolute
            bottom -10px
            left 50%
            margin-left -23px
            z-index 4
          img
            border-radius 50%
          .vip-icon
            width 46px
            height 24px
            position absolute
            left 50%
            margin-left -23px
            bottom -8px
            background url('https://qncweb.ktvsky.com/20241128/vadd/d879eed56bc2ca476c1f941d5faebc1c.png') no-repeat
            background-size 100% auto
            z-index 0
        .nickname
          font-size var(--font-size-large)
          color #fff
          line-height 1
          margin 50px auto 8px
          font-weight 500
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          max-width 385px
          @media screen and (max-width 1200px) and (min-height 1200px)
            font-size 32px
        p
          font-size var(--font-size-medium)
          color rgba(255, 255, 255, .5)
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          max-width 385px
          @media screen and (max-width 1200px) and (min-height 1200px)
            font-size 24px
      &.vip
        .nickname
          color rgba(78, 53, 13, 1)
        .vip-desc
          color rgba(109, 80, 22, 1)
        .avatar
          .vip-icon
            width 46px
            height 24px
            position absolute
            left 50%
            margin-left -23px
            bottom -8px
            background url('https://qncweb.ktvsky.com/20241128/vadd/b8819af1a0971f09c8e180354695d85a.png') no-repeat
            background-size 100% auto
            z-index 0

    .unlogin
      width 100%
      height 100%
      position relative
      .code
        background #fff
        border-radius 15px
        width 154px
        height 154px
        color rgba(0, 0, 0, 0.6)
        position absolute
        right 40px
        top 40px
        .qrcode
          width 154px
          height 154px
          img
            width 100%
            height 100%
        @media screen and (max-width 1200px) and (min-height 1200px)
          right 20px
          top 36px
          background none
          .qrcode
            width 138px
            height 138px
</style>
