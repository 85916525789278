<template>
  <div
    class="operation-sus"
    :class="[
      route_page === 'search' && 'operation-sus-search',
      route_page === 'singer' && 'operation-sus-singer',
      route_page === 'home' && 'operation-sus-home',
      route_page === 'album' && 'operation-sus-album',
      route_page === 'songList' && 'operation-sus-songlist',
      route_page === 'mine' && 'operation-sus-mine',
      route_page === 'profile' && 'operation-sus-profile',
      isSingerDetailPage && 'operation-sus-singer-detail',
      route_page === 'vipaudition' && 'operation-sus-mv-hide',
      !mvIsHide && 'operation-sus-mv-hide',
    ]"
    @click="handleShowVip"
  >
    <div @click.stop="$emit('close')" class="operation-sus-close"></div>
  </div>
</template>

<script>
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'OperationSus',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { showVipQrcode } = useVip()
    const mvIsHide = computed(() => store.state.mvIsHide)

    // 行为埋点 str1 event_name(二维码展示、支付码展示)
    const logEvents = {
      home: ['首页', 30120, 30121],
      search: ['搜索页/结果页', 30124, 30125],
      singing: ['唱过的歌页', 30128, 30129],
      singer: ['歌星列表页', 30132, 30133],
      songList: ['歌星详情页', 30136, 30137],
      mine: ['我的页', '', 30139],
      profile: ['会员中心页', '', 30141],
      vipExchange: ['兑换vip页', '', 30142],
    }

    // 搜索页和歌星页的来源埋点 - 车机端
    const frObj = {
      search: 1765,
      singer: 1767,
      songList: 1819,
      mine: 1825,
      profile: 1827,
      vipExchange: 1828,
    }

    const route_page = computed(() => route.name)
    const userInfo = computed(() => store.state.userInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isSingerDetailPage = computed(() => {
      // console.log(route_page.value, 'route_page')
      if (route_page.value === 'songList') {
        try {
          const params = new URLSearchParams(window.location.hash.split('?')[1])
          // console.log(params, 'params', window.location)
          const singerId = params.get('singerid')
          // console.log(singerId, 'singerId')
          if (singerId) return true
          return false
        } catch (error) {
          console.log(error)
          return false
        }
      }
      return false
    })

    const handleShowVip = () => {
      store.dispatch('vipAddSong/resetSong')
      // if (route_page.value === 'search') {
      //   sendLog({
      //     event_type: '10000~50000',
      //     event_name: 30143,
      //     event_data: {
      //       str1: '搜索结果页',
      //       str2: '底部运营位',
      //       str3: '底部运营位点击',
      //       str4: 'click',
      //     },
      //   })
      //   router.push({
      //     path: '/climax',
      //   })
      //   return
      // }
      sendLog({
        event_type: 'click',
        event_name: 1737,
      })
      // 此埋点先不区分页面 产品提供的埋点方案暂时没区分
      showVipQrcode({
        log: frObj[route.name] ? `${route.name}-底部运营位` : `首页-底部运营位`,
        isLogin: isLogin.value,
        fr: frObj[route.name] ? frObj[route.name] : 1759,
      })
      sendLog({
        event_type: 'show',
        event_name: 1738,
      })
      if (logEvents[route.name]) {
        if (isLogin.value) {
          sendLog({
            event_type: '10000~50000',
            event_name: logEvents[route.name][2],
            event_data: {
              str1: logEvents[route.name][0],
              str2: '底部运营位',
              str3: '支付码展示',
              str4: 'show',
            },
          })
        } else {
          sendLog({
            event_type: '10000~50000',
            event_name: logEvents[route.name][1],
            event_data: {
              str1: logEvents[route.name][0],
              str2: '底部运营位',
              str3: '二维码展示',
              str4: 'show',
            },
          })
        }
      }
    }

    return {
      mvIsHide,
      route_page,
      isSingerDetailPage,
      handleShowVip,
    }
  },
}
</script>

<style lang="stylus" scoped>
.operation-sus
  // width 748px
  // height 80px
  // width 770px
  // height 90px
  width 886px
  height 116px
  display flex
  align-items center
  justify-content right
  position fixed
  bottom 118px
  left calc(50vw - 385px)
  // background url(https://qncweb.ktvsky.com/20240805/vadd/a2354c7be544d289d2a14f2310f423bc.png) no-repeat
  background url(https://qncweb.ktvsky.com/20240925/vadd/e61898e583d9fb28192b874d2c3841e2.png) no-repeat
  background-size 100% 100%
  background-position center
  z-index 0
  // @media screen and (max-width: 1200px)
  //   width 708px
  //   height 92px
  //   left calc(50vw - 308px)
  //   background url(https://qncweb.ktvsky.com/20240925/vadd/4b24512d898aef4b33f0ffa7be5bbd8d.png) no-repeat
  //   background-size 100% 100%
  //   background-position center
  &-close
    width 40px
    height 100px
    margin-right 20px
// .operation-sus-search
//   bottom 20px !important
// .operation-sus-album
//   bottom 20px !important
// .operation-sus-singer
//   bottom 20px !important
// .operation-sus-songlist
//   bottom 20px !important
// .operation-sus-mine
//   bottom 20px !important
// .operation-sus-profile
//   bottom 20px !important
.operation-sus-mv-hide
  display none !important
</style>
