<template>
  <div class="fast-order-page" v-show="pageViewName === 'searchTabPage'">
    <div
      class="close-side"
      :class="isSearch && 'isSearch'"
      @click="$emit('close')"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.74219 26.3708L5.62781 28.2564L16 17.8842L26.3724 28.2566L28.258 26.371L17.8856 15.9986L28.2552 5.629L26.3696 3.74338L16 14.113L5.6306 3.74358L3.74498 5.6292L14.1144 15.9986L3.74219 26.3708Z"
            fill="#1D1D1F"
          />
        </g>
      </svg>
    </div>
    <div
      class="fast-order-page-back"
      v-if="isSearch"
      @click="handleBackFastSearch"
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.71 3.33595L18.3464 2.69955L17.0736 1.42676L16.4372 2.06315L1.16374 17.3367L0.527344 17.9731L1.16374 18.6095L16.4372 33.883L17.0736 34.5194L18.3464 33.2466L17.71 32.6102L3.99942 18.8995H33.3018V17.0995H3.94643L17.71 3.33595Z"
          fill="#1D1D1F"
        />
      </svg>
      搜索
    </div>
    <div class="tab" v-else>
      <div
        class="tab-item"
        :class="{ active: currPageTab === 'search' }"
        @click="handleSwitchPageTab('search')"
      >
        快速点歌
      </div>
      <div
        class="tab-item"
        :class="{ active: currPageTab == 'singer' }"
        @click="handleSwitchPageTab('singer')"
      >
        歌手点歌
      </div>
    </div>
    <div class="fast-order-page-main" v-if="currPageTab === 'search'">
      <SearchBar
        ref="searchInputRef"
        :isSearch="isSearch"
        @go-search="handleGoSearch"
        @search="handleSearch"
      />
      <div class="song-list-wrapper" v-if="keyword">
        <div class="search-tab">
          <div
            class="tab-item tab-right"
            :class="{ active: curSearchTab === 'song' }"
            @click="handleSwitchSearchTab('song')"
          >
            歌曲
          </div>
          <div
            class="tab-item"
            :class="{ active: curSearchTab == 'singer' }"
            @click="handleSwitchSearchTab('singer')"
          >
            歌手
          </div>
        </div>
        <div v-if="curSearchTab === 'song'" class="song-list">
          <LoadMore
            v-if="resultData.song.length"
            @load-more="getSearchResult"
            safeAreaHeight="20.6991vw"
          >
            <SongItem
              v-for="(songItem, index) in resultData.song"
              :key="index"
              :songItem="songItem"
              :log-from="{
                song_list_source: 10,
                fr2: '欢唱页快速点歌会员歌曲',
              }"
              :isStickButton="false"
              @singer-click="handleClickSinger"
            ></SongItem>
          </LoadMore>
          <div class="empty" v-if="resultData.song.length <= 0 && !isRequest">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4" clip-path="url(#clip0_346_155436)">
                <circle
                  cx="62.1964"
                  cy="33.4815"
                  r="18.5557"
                  stroke="#1D1D1F"
                  stroke-width="4"
                />
                <path
                  d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244"
                  stroke="#1D1D1F"
                  stroke-width="4"
                />
                <path
                  d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z"
                  fill="#1D1D1F"
                />
              </g>
              <defs>
                <clipPath id="clip0_346_155436">
                  <rect width="90" height="90" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>抱歉，暂无“{{ keyword }}”的结果</p>
          </div>
          <p class="hint" v-if="isEmpty && resultData.song.length">
            已加载全部
          </p>
        </div>
        <div v-else>
          <LoadMore
            class="singer-list singer-list-padding"
            ref="loadMoreRef"
            v-if="resultData.singer.length"
            @load-more="getSearchResult"
            safeAreaHeight="20.6991vw"
          >
            <SingerItem
              v-for="(item, index) in resultData.singer"
              :key="index"
              :singer="item"
              @click="handleClickSinger(item, 'search')"
            ></SingerItem>
          </LoadMore>
          <div class="empty" v-if="resultData.singer.length <= 0 && !isRequest">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4" clip-path="url(#clip0_346_155436)">
                <circle
                  cx="62.1964"
                  cy="33.4815"
                  r="18.5557"
                  stroke="#1D1D1F"
                  stroke-width="4"
                />
                <path
                  d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244"
                  stroke="#1D1D1F"
                  stroke-width="4"
                />
                <path
                  d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z"
                  fill="#1D1D1F"
                />
              </g>
              <defs>
                <clipPath id="clip0_346_155436">
                  <rect width="90" height="90" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>抱歉，暂无“{{ keyword }}”的结果</p>
          </div>
          <p class="hint" v-if="isEmpty && resultData.singer.length > 20">
            已加载全部
          </p>
        </div>
      </div>
      <GuessSongList
        class="guess-song-list"
        showAllData
        v-if="!isSearch"
        pageRoute="3"
        :song_list_source="11"
        :ponitActionLog="{
          // 这里不上报了，传空
        }"
        renderType="list"
        :isStickButton="false"
        @singer-click="handleClickSinger"
      />
      <SearchHistory
        v-if="isSearch && !keyword"
        @clickWord="handleChangeSearchInput"
        @delete-word="handleDeleteSearchWord"
      />
    </div>
    <SingerOrder
      v-if="currPageTab === 'singer'"
      @singer-click="handleClickSinger"
    />
  </div>
  <SingerDetail
    v-if="pageViewName === 'singerDetail'"
    :singerData="singerData"
    @close="$emit('close')"
    @back="handlechangePageViewName"
  />
</template>

<script>
import GuessSongList from '@/components/guess-song/songlist.vue'
import SearchHistory from '@/components/search-history/index.vue'
import SingerItem from '@/components/singer-item/index.vue'
import SongItem from '@/components/song-item/index.vue'
import { sendLog } from '@/directives/v-log/log'
import { search } from '@/service/search'
import { setSearchCache } from '@/utils/historyCache'
import get from 'lodash/get'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import SingerDetail from './../singer-detail/index.vue'
import SingerOrder from './../singer-order/index.vue'
import SearchBar from './search.vue'

export default {
  name: 'FastOrderPage',
  components: {
    SearchBar,
    SongItem,
    SingerItem,
    GuessSongList,
    SearchHistory,
    SingerOrder,
    SingerDetail,
  },
  emits: ['close'],
  setup() {
    const store = useStore()
    const searchCacheList = computed(() => store.state.search.searchCache)

    let pageViewName = ref('searchTabPage')
    let singerData = ref({
      singerid: '',
      name: '',
      image: '',
    }) // 侧边栏 - 歌手详情 -歌手数据

    let currPageTab = ref('search')

    let searchInputRef = ref(null)
    let isSearch = ref(false)
    let keyword = ref('')
    let curSearchTab = ref('song')
    let isEmpty = ref(false)
    let resultData = ref({
      singer: [],
      song: [],
    })
    let pagination = {
      singer: 1,
      song: 1,
    }
    let isRequest = ref(false)

    const handleGoSearch = () => {
      isSearch.value = true
      sendLog({
        event_type: '10000~50000',
        event_name: 10080,
        event_data: {
          str1: '快速点歌',
          str2: '搜索',
          str3: '点击搜索栏',
          str4: 'click',
        },
      })
    }

    const handleBackFastSearch = () => {
      isSearch.value = false
      curSearchTab.value = 'song'
      keyword.value = ''
      resultData.value.singer = []
      resultData.value.song = []
      if (searchInputRef.value) searchInputRef.value.changeInput('')
    }

    const handleSearch = async (k) => {
      keyword.value = k
    }

    const handleChangeSearchInput = (v) => {
      if (searchInputRef.value) searchInputRef.value.changeInput(v)
      sendLog({
        event_type: '10000~50000',
        event_name: 10084,
        event_data: {
          str1: '搜索',
          str2: '搜索历史',
          str3: '点击歌曲',
          str4: 'click',
        },
      })
    }

    const handleDeleteSearchWord = (v) => {
      sendLog({
        event_type: '10000~50000',
        event_name: v !== -1 ? 10085 : 10086, // 10085 单首删除 10086 全部删除
        event_data: {
          str1: '搜索',
          str2: '搜索历史',
          str3: v !== -1 ? '单首删除' : '全部删除',
          str4: 'click',
        },
      })
    }

    const getSearchReportStatus = (res) => {
      if (res.errcode !== 200) {
        return 12
      }
      return res.singer.length || res.song.length ? 10 : 11
    }

    // 搜索词条时 如果第一页中有一首歌名与搜索内容一致 则符合一键演唱功能
    const setSearchSongList = (list) => {
      if (!!keyword.value && !resultData.value.song.length) {
        list.some((v) => {
          const music_name = get(v, 'music_name', '').split('(HD)')[0]
          if (music_name === keyword.value) {
            store.dispatch('search/addSearchSong', {
              ...v,
              searchname: keyword.value,
            })
            return true
          }
          return false
        })
      }
    }

    // 存储搜索历史
    const setSearchCacheList = (k) => {
      if (!searchCacheList.value.includes(k)) {
        let newSearchCacheList = [k, ...searchCacheList.value]
        newSearchCacheList = newSearchCacheList.slice(0, 10)
        store.dispatch('search/updateSearchCache', newSearchCacheList)
        setSearchCache(newSearchCacheList)
      }
    }

    // 优先展示搜索的vip歌曲
    const toSongvipSort = (arr) => {
      let arr1 = [],
        arr2 = []
      for (let i in arr) {
        if (arr[i].is_vip) {
          arr1.push(arr[i])
        } else {
          arr2.push(arr[i])
        }
      }
      return arr1.concat(arr2)
    }

    const searchHandler = {
      singer: async () => {
        isRequest.value = true
        const res = await search(keyword.value, pagination.singer, 'singer')
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(res),
          },
        })
        if (res.singer.length) {
          resultData.value.singer = resultData.value.singer.concat(res.singer)
          pagination.singer++
        }
        isRequest.value = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10081,
          event_data: {
            str1: '快速点歌',
            str2: '搜索',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
      song: async () => {
        isRequest.value = true
        const res = await search(keyword.value, pagination.song, 'song')
        // 搜索上报 10:搜索到结果，11:未搜索到结果，12:接口异常
        sendLog({
          event_type: 'click',
          event_name: 122,
          event_data: {
            key_words: keyword.value,
            status: getSearchReportStatus(res),
          },
        })
        if (res.song.length) {
          const songvipsort = toSongvipSort(res.song)
          setSearchSongList(songvipsort)
          resultData.value.song = resultData.value.song.concat(songvipsort)
          pagination.song++
        }
        isRequest.value = false
        sendLog({
          event_type: '10000~50000',
          event_name: 10081,
          event_data: {
            str1: '快速点歌',
            str2: '搜索',
            str3: '点击搜索',
            str4: 'click',
          },
        })
      },
    }

    const getSearchResult = async () => {
      if (isRequest.value) {
        return
      }
      isRequest.value = true
      searchHandler[curSearchTab.value].call()
    }

    const handleSwitchSearchTab = (tab) => {
      curSearchTab.value = tab
      if (!resultData.value[tab].length) {
        isRequest.value = true
        searchHandler[tab].call()
      }
    }

    const handleSwitchPageTab = (tab) => {
      currPageTab.value = tab
    }

    const handleClickSinger = ({ singer, singerhead, singerid }, type) => {
      if (type === 'search') {
        sendLog({
          event_type: '10000~50000',
          event_name: 10103,
          event_data: {
            str1: '快速点歌',
            str2: '搜索结果',
            str3: '点击任意歌手',
            str4: 'click',
          },
        })
      }
      singerData.value = {
        singerid,
        name: singer,
        image: singerhead,
      }
      pageViewName.value = 'singerDetail'
    }

    const handlechangePageViewName = () => {
      singerData.value = {
        singerid: '',
        name: '',
        image: '',
      }
      pageViewName.value = 'searchTabPage'
    }

    watch(keyword, (k) => {
      if (k) {
        resultData.value = {
          singer: [],
          song: [],
        }
        pagination = {
          singer: 1,
          song: 1,
        }
        getSearchResult()
        setSearchCacheList(k)
      }
    })

    return {
      pageViewName,
      singerData,
      currPageTab,
      searchInputRef,
      isSearch,
      keyword,
      curSearchTab,
      resultData,
      isEmpty,
      handleSearch,
      handleGoSearch,
      handleBackFastSearch,
      handleChangeSearchInput,
      handleDeleteSearchWord,
      getSearchResult,
      handleSwitchPageTab,
      handleSwitchSearchTab,
      handleClickSinger,
      handlechangePageViewName,
      isRequest,
    }
  },
}
</script>

<style lang="stylus" scoped>
.fast-order-page
  width 100%
  height 100vh
  position relative
  background #fff
  @media screen and (max-width 1200px) and (min-height 1200px)
    height 1080px
  &-back
    color #1D1D1F
    font-size 32px
    display flex
    align-items center
    margin 63px 0 65px 48px
    width 200px
    height 48px
    svg
      width 36px
      height 36px
      margin-right 30px
  .close-side
    position absolute
    top 58px
    right 48px
    width 32px
    height 32px
    svg
      width 100%
      height 100%
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 100px
      height 100px
      display flex
      justify-content center
      align-items center
      background #1D1D1F1A
      top 40px
      right 40px
      border-radius 16px
      &.isSearch
        background none
      svg
        width 36px
        height 36px
  .tab
    width 497px
    height 100px
    background #E8EAEE
    display flex
    align-items center
    justify-content center
    border-radius 28px
    margin-left 120px
    margin-top 32px
    margin-bottom 30px
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 577px
      height 100px !important
      margin-top 40px !important
      margin-bottom 40px !important
      margin-left calc((100vw - 506px) / 2)
    &-item
      display flex
      justify-content center
      align-items center
      width 240px
      height 84px
      font-size 32px
      color #1D1D1F99
      border-radius 20px
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 280px
      &.active
        color #FFFFFF
        background #A04AF0
    &-right
      margin-right 128px !important
  .singer-order
    width 202px
    height 90px
    color #fff
    opacity 0.8
    font-size 28px
    display flex
    align-items center
    justify-content center
    position absolute
    top 43px
    right 158px
    background #383A3E
    border-radius 14px
    img
      width 29.6px
      height 28.8px
      margin-right 9px
  &-main
    width 100%
    height auto
    padding 0
    .singer-list
      padding-top 68px !important
    .search-tab
      width 100%
      color #1D1D1F66
      border-bottom 2px solid #1D1D1F1A
      display flex
      justify-content center
      .tab-item
        width 96px
        background none
        font-size 32px
        position relative
        margin 0px
      .tab-right
        margin-right 128px
      .active
        color #A04AF0
        &:after
          content ''
          position absolute
          bottom -2px
          left 0
          width 100%
          height 2px
          background #A04AF0
          position absolute
    @media screen and (max-width 1200px) and (min-height 1200px)
      display flex
      flex-direction column
      align-items center
    .guess-song-list
      margin 0 !important
      padding 0px !important
      min-height calc(100vh - 276px) !important

      ::v-deep .section-container-header
        padding-bottom 10px
        .section-container-header-title
          font-size 32px
          font-weight 400
          color #1D1D1F66
          padding 46px 48px 0
          @media screen and (max-width 1200px) and (min-height 1200px)
            padding 40px 84px 16px
      ::v-deep .sec-gusse-sing-list
        grid-template-columns repeat(1, 100%) !important
        overflow-y scroll
        height 711px
        .song-item
          margin 0
          // .right .svg
          //   width 64px
          //   height 64px
          //   &:nth-child(1)
          //     margin-right 64px
        @media screen and (max-width 1200px) and (min-height 1200px)
          padding 0 64px 100px !important

    ::v-deep .search-history
      margin-top 48px
      padding 0 48px
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 0 64px !important
      .search-history-title
        height 32px
        @media screen and (max-width 1200px) and (min-height 1200px)
          padding 0px !important
        span
          color #1D1D1F66
          font-size 32px
          margin-right 12px
        img
          // width 36px
          // height 36px
          opacity 0.6
      .search-history-content
        margin-top 26px
        .search-history-content-item
          margin-right 24px
          margin-bottom 24px
          span
            margin-right 24px
    .song-list-wrapper
      width 100%
      box-sizing border-box
      padding 0
      margin-top 16px
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 0 64px
      ::-webkit-scrollbar
        display none
      ::v-deep .song
        height 141px
        padding 0
      ::v-deep .song-item
        height 141px
        margin 0 0px
        padding 0
        .right
          margin-right 0
      .empty
        display flex
        flex-direction column
        justify-content center
        align-items center
        margin-top 30px
        font-size 28px
        color #1D1D1F66
        text-align center
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 280px
        svg
          width 80px
          height 80px
          margin-bottom 40px
        p
          height 32px
          line-height 32px
      .hint
        text-align center
        color #555555
      .singer-list
        margin 0 auto
        display grid
        grid-template-columns repeat(4, 140px)
        justify-content space-between
        padding-top 12px
        padding-left 48px
        padding-right 48px
        ::v-deep .singer-item
          width 140px
          margin-bottom 40px
          font-size 24px
          margin-right 45px
          &:nth-child(4n)
            margin-right 0
          .singer-item-cover
            width 140px
            height 140px
            margin-bottom 20px
          p
            width 140px
      .singer-list-padding
        padding-left 48px !important
        padding-right 48px !important
        @media screen and (max-width 1200px) and (min-height 1200px)
          padding-left 32px !important
          padding-right 32px !important
          grid-template-columns repeat(4, 200px)
          ::v-deep .singer-item
            width 200px
            margin-bottom 60px
            font-size 22px
            .singer-item-cover
              width 200px
              height 200px
            p
              width 200px
</style>
