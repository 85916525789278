<template>
  <div class="search-bar-ver3">
    <div class="right">
      <div class="logo">
        <img
          class="horizontal"
          src="https://qncweb.ktvsky.com/20231222/other/587b94b627ce3bb928c3b16a26225698.png"
        />
        <img
          class="vertical"
          src="https://qncweb.ktvsky.com/20240718/other/19b7f989952e180c554225f03ade494d.png"
        />
      </div>

      <div class="times-info" @click="handleClickTimes">
        <template v-if="!isLogin">
          <!-- <span class="light">登录</span>即可享千元特权，好礼送不停！ -->
          <span class="light">登录领取</span>VIP权益，好礼送不停！
          <img
            src="https://qncweb.ktvsky.com/20240925/vadd/08e914c55df65ba848439f6e12293197.png"
            alt=""
          />
        </template>
        <template v-else-if="isVip"
          >尊敬的VIP用户，拉满状态开启您狂欢时刻！</template
        >
        <template v-else-if="isExpire"
          >权益重磅升级，邀您回归！<span class="light"
            >续费低至¥0.2/天</span
          ></template
        >
        <template v-else-if="!isExpire && !isShowClimax && freeVipNumber >= 3"
          >每日更新曲库，紧跟实时热点，<span class="light">解锁</span
          >VIP，海量歌曲免费唱！</template
        >
        <template v-else>
          <p v-if="!isExpire" class="free-order">
            VIP歌曲免费点唱次数:
            <span class="free-order-zero">{{ 3 - freeVipNumber }}/3</span>
          </p>
          <p v-if="isShowClimax" class="climax-free">
            快唱高潮限免时长
            <CountDown
              class="climax-free-countdown"
              ref="userTime"
              :time="preferentialTime"
              @finish="handleTimeFinish"
              :auto-start="false"
            >
              <template #default="timeData">
                <span class="block">00</span>
                <span class="colon">:</span>
                <span
                  class="block"
                  >{{ (timeData.minutes &lt; 10) ? ('0' + timeData.minutes) : timeData.minutes }}</span
                >
                <span class="colon">:</span>
                <span
                  class="block"
                  >{{ (timeData.seconds &lt; 10) ? ('0' + timeData.seconds) : timeData.seconds }}</span
                >
              </template>
            </CountDown>
          </p>
        </template>
      </div>
    </div>

    <div class="left">
      <div class="search-box" @click="handleToSearch">
        <i class="icon-search">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_9_5013"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="40"
              height="40"
            >
              <rect
                width="40"
                height="40"
                fill="#D9D9D9"
                style="
                  fill: #d9d9d9;
                  fill: color(display-p3 0.851 0.851 0.851);
                  fill-opacity: 1;
                "
              />
            </mask>
            <g mask="url(#mask0_9_5013)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28 18C28 23.5228 23.5228 28 18 28C12.4772 28 8 23.5228 8 18C8 12.4772 12.4772 8 18 8C23.5228 8 28 12.4772 28 18ZM26.0706 28.1921C23.8534 29.9501 21.0493 31 18 31C10.8203 31 5 25.1797 5 18C5 10.8203 10.8203 5 18 5C25.1797 5 31 10.8203 31 18C31 21.0494 29.95 23.8536 28.1919 26.0708L34.9163 32.7952L32.7949 34.9165L26.0706 28.1921Z"
                fill="white"
                fill-opacity="0.3"
                style="fill: white; fill-opacity: 0.3"
              />
            </g>
          </svg>
        </i>
        <p>搜索歌曲、歌手</p>
      </div>

      <div class="button-container" @click="handleOpenOrderControl">
        <div class="button">
          <i class="icon">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5C1 4.44772 1.44772 4 2 4L14 4C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6L2 6C1.44772 6 1 5.55228 1 5Z"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
              <path
                d="M1 14C1 13.4477 1.44772 13 2 13L10.5 13C11.0523 13 11.5 13.4477 11.5 14C11.5 14.5523 11.0523 15 10.5 15L2 15C1.44772 15 1 14.5523 1 14Z"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
              <path
                d="M1 23C1 22.4477 1.44772 22 2 22H7C7.55228 22 8 22.4477 8 23C8 23.5523 7.55228 24 7 24H2C1.44772 24 1 23.5523 1 23Z"
                fill="white"
                style="fill: white; fill-opacity: 1"
              />
              <circle
                cx="18"
                cy="20.3"
                r="5"
                stroke="white"
                style="stroke: white; stroke-opacity: 1"
                stroke-width="2"
              />
              <path
                d="M23 19.5L23 4.94173C23 4.09278 23.9902 3.6292 24.6411 4.17424C25.1124 4.56892 25.7191 5.07694 26.5 5.73088"
                stroke="white"
                style="stroke: white; stroke-opacity: 1"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          已点
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useLoginValid from '@/composables/useLoginValid'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import { CountDown } from 'vant'
import { computed, defineProps, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const route = useRoute()
const { showVipQrcode } = useVip()

const isVip = computed(() => !!store.state.vipInfo.end_time)
const vipInfo = computed(() => store.state.vipInfo)
const freeVipNumber = computed(() => Number(store.state.freeVipNumber1))
const isExpire = computed(() => vipInfo.value.expire)
const isClimaxFree = computed(() => !store.state.climax.freeSing)
const isShowClimax = computed(() => !isVip.value && isClimaxFree)
// const isLogin = computed(() => !!store.state.userInfo.unionid)
const { showLoginQrcode, isLogin } = useLoginValid()

const preferentialTime = ref(30 * 60 * 1000)
const timeData = ref({})
const userTime = ref(null)

const props = defineProps({
  isSearch: Boolean,
})

const handleToSearch = () => {
  sendLog({
    event_type: '10000~50000',
    event_name: 10003,
    event_data: {
      str1: '首页',
      str2: '搜索栏',
      str3: '进入搜索',
      str4: 'click',
    },
  })
  router.push({
    name: 'search',
    query: { defaultTab: props.defaultTab },
  })
}

const handleTimeFinish = () => {
  store.dispatch('climax/updateFreeSing', true)
}

const handleOpenOrderControl = () => {
  eventBus.emit('show-order-song-control-popup')
  sendLog({
    event_type: '10000~50000',
    event_name: 10005,
    event_data: {
      str1: '首页',
      str2: '已点',
      str3: '打开已点',
      str4: 'click',
    },
  })
}

const handleClickTimes = () => {
  sendLog({
    event_type: '10000~50000',
    event_name: 30256,
    event_data: {
      str1: '首页',
      str2: '右上角喇叭通知',
      str3: '点击',
      str4: 'click',
    },
  })
  if (!isVip.value) {
    if (isLogin.value) {
      showVipQrcode({
        isLogin: isLogin.value,
        fr: 1868,
        log: '权益顶部运营位',
      })
    } else {
      showLoginQrcode({
        log: '权益顶部运营位',
      })
    }
  }
}

// watch(
//   route,
//   (val) => {
//     console.log(val.name, "route");
//     // if (val.name === 'climax') {
//     //   nextTick(() => userTime.value.start());
//     // } else {
//     //   nextTick(() => userTime.value.pause());
//     // }
//   },
//   { deep: true, immediate: true }
// );
</script>

<style lang="stylus" scoped>
$white-color = #FFFFFF
$white-opacity-20 = rgba(255, 255, 255, 0.2)
$white-opacity-15 = rgba(255, 255, 255, 0.15)
$white-opacity-30 = rgba(255, 255, 255, 0.3)
$white-opacity-80 = rgba(255, 255, 255, 0.8)
$border-radius-24 = 24px
$border-radius-16 = 16px
$font-size-24 = 24px
$font-size-30 = 30px

.search-bar-ver3
  display flex
  align-items center
  justify-content space-between
  height 132px
  font-size $font-size-30
  @media screen and (max-width 1200px) and (min-height 1200px)
    font-size $font-size-24
    margin-bottom 26px
    height 60px

.search-box
  display flex
  align-items center
  height 76px !important
  border 1px solid $white-opacity-20
  border-radius $border-radius-24
  background-color $white-opacity-15
  width 596px
  color $white-opacity-30
  font-size $font-size-24
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 240px
    height 60px !important
    font-size 20px
    border-radius $border-radius-16
    svg
      width 32px
      height 32px
  .icon-search
    margin 0 10px 0 30px
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin 0 3px 0 16px

.button-container
  display flex
  width auto !important
  margin-left 31px

  @media screen and (max-width 1200px) and (min-height 1200px)
    margin-left 20px

  .button
    display flex
    align-items center
    justify-content center
    width 180px
    height 76px
    border 1px solid $white-opacity-20
    border-radius $border-radius-24
    background-color $white-opacity-20
    color $white-opacity-80
    font-size $font-size-24
    margin 0px
    i
      margin-right 6px

    @media screen and (max-width 1200px) and (min-height 1200px)
      width 144px
      height 60px
      padding-left 0px
      font-size 20px
      border-radius 16px
      margin-left 0px !important
      margin-right 0px !important
      i
        margin-right 10px
        width 22px
        height 22px
      &:first-child
        margin 0 20px

.logo
  width 225px
  margin 0px
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 160px

.left, .right
  display flex
  align-items center

.right
  .times-info
    display flex
    align-items center
    background-color rgba(0, 0, 0, 0.2)
    height 76px
    background-image url('https://qncweb.ktvsky.com/20240710/other/b8108ad12e7dcc159db670aa63f8daa6.png')
    background-size 40px auto
    background-repeat no-repeat
    background-position 34px center
    border-radius $border-radius-24
    color $white-opacity-80
    font-size 20px
    padding-left 84px
    padding-right 34px
    margin-left 32px
    img
      width 34px
      height 34px

    @media screen and (max-width 1200px) and (min-height 1200px)
      height 60px
      margin-left 20px
      font-size 18px
      padding-left 58px
      padding-right 20px
      background-size 28px auto
      background-position 20px center
      border-radius $border-radius-16
      img
        width 26px
        height 26px
    .light
      color #E5B875

    p
      color $white-opacity-80

    .free-order
      position relative
      padding-right 20px
      margin-right 20px
      &:after
        content ''
        position absolute
        top 50%
        margin-top -8px
        right 0
        width 2px
        height 20px
        background rgba(255, 255, 255, 0.2)
    .climax-free
      display flex

      &-countdown
        color rgba(0, 0, 0, 0.2)
        margin-left 10px
        .block
          display inline-block
          background rgba(0, 0, 0, 0.2)
          width 28px
          height 28px
          line-height 28px
          text-align center
          font-size 18px
          border-radius 4px
          color $white-opacity-80
        .colon
          margin 0 4px
</style>
