// 全局记忆点歌次数，用于判断是否显示点歌弹窗
// 累计点免费歌次数超过3、7、13、20次，则显示点歌弹窗
// 触发点歌弹窗后，通过store2存储，已触发过的弹窗当天不再显示
// vip用户不触发点歌弹窗

// 帮我实现这个功能，通过vuex记录当天累积的次数 noVipOrderTime, 通过全局监听orderedList变化，如果用户非vip且orderedList.length增加时，则更新noVipOrderTime和store2（‘noVipOrderTime’， {
//    time: currDate,
//    tag,
//  }）
// 通过监听noVipOrderTime变化，在特定次数触发弹窗 - useVip里的showVipQrcode()

import { sendLog } from '@/directives/v-log/log'
import { getUserRoleTag } from '@/utils/historyCache'
import { format } from 'date-fns'
import store2 from 'store2'

const state = {
  noVipOrderTime: 0
}

const mutations = {
  UPDATE_ORDER_TIME(state, count) {
    state.noVipOrderTime = count
  }
}

const actions = {
  updateNoVipOrderTime({ commit, state }, { isVip, showVipQrcode, isLogin, showVipQrcodeParam = {} }) {
    // VIP用户不计数
    if (isVip) return
    
    const currDate = format(new Date(), 'yyyy-MM-dd')
    const storedData = store2.get('noVipOrderTime')
    
    // 超过20次不再统计
    if (storedData?.count >= 20) return
    
    // 新的一天或首次记录
    if (!storedData || storedData.time !== currDate) {
      const newCount = 1
      commit('UPDATE_ORDER_TIME', newCount)
      store2.set('noVipOrderTime', {
        time: currDate,
        count: newCount
      })
      return
    }

    // 更新点歌次数
    const newCount = storedData.count + 1
    commit('UPDATE_ORDER_TIME', newCount)
    store2.set('noVipOrderTime', {
      time: currDate,
      count: newCount
    })

    // 在特定次数触发VIP弹窗
    const triggerPoints = [2, 7, 13, 20]
    if (triggerPoints.includes(newCount)) {
      if (typeof showVipQrcode === 'function') {
        showVipQrcode(showVipQrcodeParam)
        sendLog({
          event_type: '10000~50000',
          event_name: isLogin ? 30330 : 30329,
          event_data: {
            str1: `新挽留弹窗${ isLogin ? '2' : '1' }（主动）`,
            str2: '通用',
            str3: '展示',
            str4: 'show',
            str5: isLogin ? '已登录' : '未登录',
            str7: getUserRoleTag() === 2 ? '2' : '1',
          },
        })
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}