<template>
  <div class="song" :class="'song-item-' + renderType">
    <div
      v-if="renderType === 'list'"
      class="song-item"
      :class="[
        className,
        {
          ordered: isOrdered,
        },
      ]"
    >
      <div class="left" @click="handleOrder">
        <div class="name">
          <span class="name-txt" v-html="highlightedName"></span>
        </div>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="singerCanClick && 'clickable'"
              @click="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <span v-if="songItem.flag" class="flag">{{
            songItem.flag.toString()
          }}</span>
          <img
            v-if="songItem.is_vip"
            class="song-block-vip"
            src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png"
            alt=""
          />

          <i v-else class="free-icon">免费</i>

          <span v-if="songItem.sing_cnt" class="sing-cnt">
            演唱<span>{{ songItem.sing_cnt }}</span
            >次
          </span>
          <img
            v-if="show1080Icon"
            class="icon-1080"
            src="https://qncweb.ktvsky.com/20241224/vadd/ac1f06b6de858fdd0aa0415eccc54421.png"
            alt=""
          />
          <img
            v-if="showNewSongIcon"
            class="icon-newsong"
            src="https://qncweb.ktvsky.com/20240325/vadd/6387b6385220cb3385dba761ab5aac23.png"
            alt=""
          />
        </div>
      </div>
      <div class="right">
        <div class="immediate-play" @click.stop="handlePlay('play')">
          <svg
            class="iplay"
            width="72"
            height="73"
            viewBox="0 0 72 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_332_8257"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="11"
              y="11"
              width="50"
              height="51"
            >
              <rect
                x="11.25"
                y="11.75"
                width="49.5"
                height="49.5"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_332_8257)">
              <circle
                cx="43.0957"
                cy="30.3125"
                r="12.9375"
                stroke="#5500C3"
                stroke-width="3.375"
              />
              <path
                d="M30.4746 33.2539L18.8216 48.8049C17.7163 50.2799 17.8634 52.343 19.1667 53.6463V53.6463C20.47 54.9496 22.5331 55.0966 24.0081 53.9914L39.559 42.3383"
                stroke="#5500C3"
                stroke-width="3.375"
              />
            </g>
          </svg>

          <svg
            v-if="isShowAudition"
            class="audition-play"
            width="51"
            height="28"
            viewBox="0 0 51 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 5C3 2.23858 5.23858 0 8 0H46C48.7614 0 51 2.23858 51 5V23C51 25.7614 48.7614 28 46 28H1.56291C0.833117 28 0.349125 27.2436 0.65495 26.5809L2.63184 22.2977C2.87439 21.7722 3 21.2003 3 20.6215V5Z"
              fill="url(#paint0_linear_7471_48364)"
              style=""
            />
            <path
              d="M3 5C3 2.23858 5.23858 0 8 0H46C48.7614 0 51 2.23858 51 5V23C51 25.7614 48.7614 28 46 28H1.56291C0.833117 28 0.349125 27.2436 0.65495 26.5809L2.63184 22.2977C2.87439 21.7722 3 21.2003 3 20.6215V5Z"
              fill="url(#paint1_linear_7471_48364)"
              style=""
            />
            <path
              d="M26.316 17.724C26.244 18.18 26.136 18.696 25.992 19.272C25.86 19.848 25.74 20.31 25.632 20.658C25.308 21.714 24.798 22.242 24.102 22.242C23.31 22.242 22.632 21.666 22.068 20.514C21.696 19.71 21.396 18.564 21.168 17.076C20.94 15.588 20.754 13.53 20.61 10.902H14.814V9.282H20.556L20.466 5.826H22.032C22.068 7.362 22.098 8.514 22.122 9.282H26.028V10.902H22.176C22.296 13.266 22.458 15.108 22.662 16.428C22.866 17.736 23.13 18.756 23.454 19.488C23.694 19.98 23.916 20.226 24.12 20.226C24.324 20.226 24.498 19.842 24.642 19.074C24.75 18.45 24.852 17.814 24.948 17.166L26.316 17.724ZM24.642 9.048C24.39 8.772 24.048 8.418 23.616 7.986C23.184 7.542 22.83 7.2 22.554 6.96L23.544 5.97C23.808 6.198 24.156 6.522 24.588 6.942C25.02 7.35 25.38 7.71 25.668 8.022L24.642 9.048ZM12.942 10.29C12.63 9.87 12.21 9.342 11.682 8.706C11.154 8.058 10.722 7.548 10.386 7.176L11.718 6.096C12.066 6.468 12.498 6.972 13.014 7.608C13.542 8.232 13.962 8.748 14.274 9.156L12.942 10.29ZM15.228 18.444C14.568 19.104 13.806 19.914 12.942 20.874C12.69 21.162 12.42 21.48 12.132 21.828L11.034 20.622C11.214 20.418 11.34 20.214 11.412 20.01C11.496 19.806 11.538 19.548 11.538 19.236V13.278H9.558V11.55H13.122V18.534C13.35 18.258 13.764 17.784 14.364 17.112L15.228 18.444ZM18.324 18.822C18.528 18.774 18.834 18.714 19.242 18.642C19.542 18.57 19.992 18.474 20.592 18.354L20.7 20.01C19.104 20.346 17.28 20.7 15.228 21.072L14.742 21.162L14.562 19.542L16.776 19.128V14.934H14.886V13.296H20.268V14.934H18.324V18.822ZM34.794 6.852H42.894V13.35H34.794V6.852ZM33.282 19.812H28.584V7.626H33.282V19.812ZM41.31 9.444V8.22H36.342V9.444H41.31ZM31.734 9.3H30.132V18.102H31.734V9.3ZM36.342 10.722V11.982H41.31V10.722H36.342ZM34.308 22.314V14.682H43.416V22.296H41.832V21.486H35.856V22.314H34.308ZM41.832 17.454V16.05H35.856V17.454H41.832ZM35.856 18.768V20.19H41.832V18.768H35.856Z"
              fill="#2B2B49"
              style="
                fill: #2b2b49;
                fill: color(display-p3 0.1686 0.1686 0.2863);
                fill-opacity: 1;
              "
            />
            <defs>
              <linearGradient
                id="paint0_linear_7471_48364"
                x1="51"
                y1="14"
                x2="3.00001"
                y2="14"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  stop-color="#B2D8FF"
                  style="
                    stop-color: #b2d8ff;
                    stop-color: color(display-p3 0.6997 0.8473 1);
                    stop-opacity: 1;
                  "
                />
                <stop
                  offset="1"
                  stop-color="#CAB2FD"
                  style="
                    stop-color: #cab2fd;
                    stop-color: color(display-p3 0.7917 0.6971 0.9913);
                    stop-opacity: 1;
                  "
                />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7471_48364"
                x1="51"
                y1="14"
                x2="3.00001"
                y2="14"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  stop-color="#A8D3FF"
                  style="
                    stop-color: #a8d3ff;
                    stop-color: color(display-p3 0.6606 0.8274 1);
                    stop-opacity: 1;
                  "
                />
                <stop
                  offset="1"
                  stop-color="#CBB2FF"
                  style="
                    stop-color: #cbb2ff;
                    stop-color: color(display-p3 0.7963 0.6999 1);
                    stop-opacity: 1;
                  "
                />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div class="order-play" @click.stop="handlePlay('order')">
          <svg
            class="order-icon"
            width="72"
            height="73"
            viewBox="0 0 72 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.3807 18.2C38.3807 17.2611 37.6196 16.5 36.6807 16.5C35.7418 16.5 34.9807 17.2611 34.9807 18.2V34.6818H18.2625C17.3236 34.6818 16.5625 35.4429 16.5625 36.3818C16.5625 37.3207 17.3236 38.0818 18.2625 38.0818H34.9807V54.8C34.9807 55.7389 35.7418 56.5 36.6807 56.5C37.6196 56.5 38.3807 55.7389 38.3807 54.8V38.0818H54.8625C55.8014 38.0818 56.5625 37.3207 56.5625 36.3818C56.5625 35.4429 55.8014 34.6818 54.8625 34.6818H38.3807V18.2Z"
              fill="#5500C3"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      v-else
      class="song-item song-block"
      :class="{
        ordered: isOrdered,
      }"
      @click="handleOrder"
    >
      <div class="left">
        <span class="name">{{ songItem.music_name }}</span>
        <div class="desc">
          <template v-if="songItem.singer">
            <span
              class="author"
              :class="singerCanClick && 'clickable'"
              @click="handleClickSinger"
              >{{ songItem.singer }}</span
            >
          </template>
          <span v-if="songItem.flag" class="flag">{{
            songItem.flag.toString()
          }}</span>

          <div class="center">
            <img
              v-if="songItem.is_vip"
              class="song-block-vip"
              src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png"
              alt=""
            />
            <i v-else class="free-icon">免费</i>
          </div>

          <span v-if="songItem.sing_cnt" class="sing-cnt">
            演唱<span>{{ songItem.sing_cnt }}</span
            >次
          </span>
        </div>
      </div>

      <div class="right">
        <svg
          v-if="isShowPlayBtn"
          @click.stop="handleAudition"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_332_8257"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="11"
            y="11"
            width="50"
            height="51"
          >
            <rect
              x="11.25"
              y="11.75"
              width="49.5"
              height="49.5"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_332_8257)">
            <circle
              cx="43.0957"
              cy="30.3125"
              r="12.9375"
              stroke="#5500C3"
              stroke-width="3.375"
            />
            <path
              d="M30.4746 33.2539L18.8216 48.8049C17.7163 50.2799 17.8634 52.343 19.1667 53.6463V53.6463C20.47 54.9496 22.5331 55.0966 24.0081 53.9914L39.559 42.3383"
              stroke="#5500C3"
              stroke-width="3.375"
            />
          </g>
        </svg>
        <svg
          v-if="isShowOrderBtn"
          class="song-block-order"
          @click.stop="handlePlay('order')"
          width="72"
          height="73"
          viewBox="0 0 72 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.3807 18.2C38.3807 17.2611 37.6196 16.5 36.6807 16.5C35.7418 16.5 34.9807 17.2611 34.9807 18.2V34.6818H18.2625C17.3236 34.6818 16.5625 35.4429 16.5625 36.3818C16.5625 37.3207 17.3236 38.0818 18.2625 38.0818H34.9807V54.8C34.9807 55.7389 35.7418 56.5 36.6807 56.5C37.6196 56.5 38.3807 55.7389 38.3807 54.8V38.0818H54.8625C55.8014 38.0818 56.5625 37.3207 56.5625 36.3818C56.5625 35.4429 55.8014 34.6818 54.8625 34.6818H38.3807V18.2Z"
            fill="#5500C3"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import useOrder from '@/composables/useOrder'
import useSongItem from '@/composables/useSongItem'
import useVip from '@/composables/useVip'
import logParams from '@/log'
import { sendLog } from '@/directives/v-log/log'
import eventBus from '@/utils/event-bus'
import { computed, inject, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'SongItem',
  props: {
    className: {
      type: String,
      default: '',
    },
    songItem: Object,
    index: Number,
    logFrom: {
      type: Object,
      default: () => {},
    },
    ponitActionLog: {
      type: Object,
      default: () => {},
    },
    singerEnable: {
      type: Boolean,
      default: true,
    },
    renderType: {
      type: String,
      default: 'list',
    },
    isStickButton: {
      type: Boolean,
      default: false,
    },
    show1080Icon: {
      type: Boolean,
      default: false,
    },
    showNewSongIcon: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    isShowPlayBtn: {
      type: Boolean,
      default: false,
    },
    isShowOrderBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['singer-click', 'order-click'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const {
      songItem,
      logFrom,
      keyword,
      ponitActionLog,
      singerEnable,
      position,
      showNewSongIcon,
    } = toRefs(props)
    const store = useStore()
    const { isVipUser } = useVip()
    const orderedSongIdMap = inject('orderedSongIdMap')
    const { orderSong } = useSongItem()
    const { orderSong: immediatePlay, orderedList } = useOrder()
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const userType = computed(() => store.state.userInfo.userType)
    const isOrdered = computed(
      () => orderedSongIdMap.value[songItem.value.songid]
    )
    const mvIsHide = computed(() => store.state.mvIsHide)
    const singerCanClick = computed(
      () =>
        (mvIsHide.value && !route.query.singerid) ||
        (!mvIsHide.value && singerEnable.value)
    )

    const isLogin = computed(() => !!userInfo.value.unionid)

    const isShowAudition = computed(() => {
      if (!songItem.value.is_vip) return false
      if (!isLogin.value || !vipInfo.value?.end_time) return true
      return false
    })

    const handleOrder = (event, options = { type: 'order' }) => {
      emit(options.type === 'order' ? 'order-click' : '')
      const orderOptions = {
        from: logFrom.value,
        ponitActionLog: ponitActionLog.value,
        isValidNewSong: showNewSongIcon.value,
        isNotSingImmediately: options.type === 'order',
      }
      if (Object.keys(orderedSongIdMap.value).length === 0) {
        orderSong(songItem.value, orderOptions)
      } else {
        orderSong(songItem.value, { ...orderOptions, immediate: false })
      }
    }

    const handleClickSinger = (e) => {
      if (!singerCanClick.value) return
      e.stopPropagation()
      sendLog({
        event_type: '10000~50000',
        event_name: 10108,
        event_data: {
          str1: '任意点歌页',
          str2: '歌曲列表',
          str3: '点击任意歌手',
          str4: 'click',
        },
      })
      if (store.state.mvIsHide) {
        router.push({
          name: 'songList',
          query: {
            name: songItem.value.singer.split(',')[0],
            image: songItem.value.singer_head,
            singerid: songItem.value.singerid,
          },
        })
      } else {
        emit('singer-click', {
          singer: songItem.value.singer.split(',')[0],
          singerhead: songItem.value.singer_head,
          singerid: songItem.value.singerid,
        })
        sendLog({
          event_type: '10000~50000',
          event_name: 10109,
          event_data: {
            str1: 'MV点歌页',
            str2: '歌曲列表',
            str3: '点击任意歌手',
            str4: 'click',
          },
        })
      }
    }

    const handleAudition = () => {
      store.commit('SET_VIP_AUDITION_SONG', songItem.value)
      if (isLogin.value) {
        sendLog({
          ...logParams.get(30341),
          event_data: {
            ...logParams.get(30341).event_data,
            str5: isLogin.value ? '已登录' : '未登录',
            str7: userType.value,
          }
        })
      } else {
        sendLog({
          ...logParams.get(30340),
          event_data: {
            ...logParams.get(30340).event_data,
            str5: isLogin.value ? '已登录' : '未登录',
            str7: userType.value,
          }
        })
      }
      if (!mvIsHide.value) {
        eventBus.emit('handle-mv-back')
      }

      eventBus.emit('video-control-pause')
      store.commit('RESET_VIDEO_PLAYER_HLS')

      setTimeout(() => {
        router.push({
          name: 'vipaudition',
        })
      }, 800)

      // setTimeout(() => {
      //   Toast('VIP歌曲试唱中，完整版请开通VIP')
      // }, 1000)
    }

    const handlePlay = async (type) => {
      console.log(type, isShowAudition.value)
      if (type === 'play' && isShowAudition.value) {
        handleAudition()
        return
      }

      await Promise.all([
        handleOrder(null, {
          type: type,
        }),
      ])
      if (type === 'play') {
        sendLog({
          event_type: '10000~50000',
          event_name: 30239,
          event_data: {
            str1: '通用',
            str2: '立即演唱',
            str3: '点击',
            str4: 'click',
          },
        })
        immediatePlay(songItem.value, orderedList.value.length - 1)
      }
    }

    const highlightedName = computed(() => {
      if (!keyword.value) return songItem.value.music_name
      const regex = new RegExp(`(${keyword.value})`, 'gi')
      return songItem.value.music_name.replace(
        regex,
        '<span class="highlight">$1</span>'
      )
    })

    return {
      isOrdered,
      isShowAudition,
      userInfo,
      vipInfo,
      userType,
      highlightedName,
      handleOrder,
      handleClickSinger,
      singerCanClick,
      handleAudition,
      handlePlay,
    }
  },
}
</script>

<style lang="stylus" scoped>
.song
  width 100%
  height fit-content !important
  padding 0px
  &.song-item-list
    padding 0 40px
    border-radius 16px
    &:active
      background #0000000D
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 0 18px
      border-radius 8px
  .song-item
    width 100%
    display flex
    align-items center
    justify-content space-between
    height 144px
    color #1D1D1FCC
    border-bottom 1px solid #1D1D1F1A
    padding 0px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 136px
    .icon-1080
      width 72px
      margin-left 12px
    .free-icon
      width 56px
      height 26px
      display flex
      align-items center
      justify-content center
      background: linear-gradient(90deg, #009BCB -0.89%, #3D73FF 99.11%);
      color #fff
      font-size 18px
      border-radius 100px
      margin-left 11px
    .icon-newsong
      width 102px
      margin-left 12px

    .song-block-vip
      width 50px
      height 26px
      margin-left 13px
    .left
      flex 1
      display flex
      flex-direction column
      max-width 68%
      @media screen and (max-width 1200px) and (min-height 1200px)
        max-width 65%
    .name
      font-size var(--font-size-song-name)
      margin-bottom 10px
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 19vw
      @media screen and (max-width 1200px)
        max-width: 24vw
    .desc
      display flex
      align-items center
      height 33px
      font-size var(--font-size-song-subtitle)
      color #1D1D1F80
      .clickable
        position relative
        padding-right 26px
        &::after
          content ""
          position absolute
          right 0
          top 50%
          margin-top -10px
          width 22px
          height 22px
          background url('https://qncweb.ktvsky.com/20241127/other/04c0294caf0a05c6cd2e59439c73cdab.png') no-repeat
          background-size 100% 100%
      .divider
        margin -4px 16px 0 16px
      .sing-cnt
        margin-left 4px
        border none
        font-size 18px
        color #1D1D1F80
        border 2px solid rgba(29, 29, 31, 0.5)
        border-radius 30px
        white-space nowrap
        padding 0 10px
        margin-left 10px
        span
          color #A04AF0
      .author
        max-width 320px
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
      .flag
        position relative
        padding-left 14px
        margin-left 12px
        white-space nowrap
        &:after
          content ""
          position absolute
          left 0px
          top 50%
          margin-top -11px
          width 2px
          height 22px
          background #1D1D1F66
    .right
      display flex
      align-items center
      margin-right 20px
      .immediate-play
        margin-right 72px
        position relative
        .iplay
          width 72px
          height 72px
        .audition-play
          position absolute
          top -7px
          right -37px
          width 51px !important
          height 28px !important

      .order-play
        svg
          width 72px
          height 72px

        @media screen and (max-width 1200px) and (min-height 1200px)
          width 64px
          height 64px
          &:first-child
            margin-right 48px
    &.ordered
      .left
        .name, .desc
          color #A04AF0 !important
        .clickable::after
          background-image url('https://qncweb.ktvsky.com/20241127/other/50b7f338d68b13c0236b56276c2e5c1d.png')

  .song-block
    width 498px !important
    height 160px !important
    background #fff
    padding-left 36px !important
    padding-right 36px !important
    border-radius 16px
    .left
      max-width 100%
    &:active
      background #fff !important
    .name
      max-width 400px
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size var(--font-size-large) !important
    .desc
      font-size var(--font-size-medium) !important
</style>
