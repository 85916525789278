<template>
  <CommonModal ref="root">
    <div class="phone-song-box">
      <div class="close-box" @click="handleClose">
        <img
          src="https://qncweb.ktvsky.com/20241217/vadd/2ac181bd1d62dfe3f0881ef08ae6c196.png"
        />
      </div>
      <!-- <img src="../../../../assets/images/phone-song-title.png" class="title"/> -->
      <div class="qrcode">
        <img :src="qrCodeURL" />
      </div>
      <!-- <div class="tip">扫码即可进入手机点歌</div> -->
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import { getBaseInfo } from '@/service/base'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { onBeforeMount, ref } from 'vue'

export default {
  name: 'MobileOrderQRcode',
  components: {
    CommonModal,
  },
  setup() {
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const init = async () => {
      const { data } = await getBaseInfo()
      const url = get(data, 'phone_control_qr', '')
      if (url) {
        // const qrcodeUrl = props.from === 'loginSendVipActivity' ? `${data.login_qr}&fr=1` : data.login_qr
        const qrCodeData = await getQRCodeURL(url)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到手机点歌二维码')
    }

    const handleClose = () => {
      root.value.hide()
    }

    onBeforeMount(init)

    return {
      qrCodeURL,
      root,
      handleClose,
    }
  },
}
</script>
<style lang="stylus" scoped>
//.mobile-order-modal {
//  &-content {
//    padding-top: 52px;
//    position: relative;
//    width: 1000px;
//    height: 700px;
//    background: #1E1F21;
//    border-radius: 20px;
//    color: rgba(255, 255, 255, 0.8);
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//
//    .close {
//      position: absolute;
//      top: 30px;
//      left: 30px;
//      width: 90px;
//      height: 90px;
//    }
//
//    .title {
//      display: flex;
//      align-items: center;
//      margin-bottom: 77px;
//      font-size: 40px;
//
//      img {
//        width: 44px;
//        height: 44px;
//        margin-right: 10px;
//      }
//    }
//
//    .qrcode {
//      width: 320px;
//      height: 320px;
//      margin-bottom: 70px;
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      background: #ffffff;
//      border-radius: 10px;
//
//      img {
//        width: 300px;
//        height: 300px;
//      }
//    }
//
//    .tip {
//      font-size: 32px;
//    }
//  }
//}
.phone-song-box
  width: 1000px;
  height: 675px;
  border-radius: 40px;
  background rgba(37, 39, 48, 1)
  display flex
  align-items center
  flex-direction column
  position: relative;
  justify-content center
  background url('https://qncweb.ktvsky.com/20241217/vadd/2885bfac63ddd130b4456d0c27ba0b9e.png') no-repeat
  background-size 100% 100%
  background-position center
  .qrcode {
    width: 214px;
    height: 214px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 27px;
    position absolute
    bottom 142px
    left 393px
    img {
      width: 100%;
      height: 100%;
    }
  }
  .close-box
    width 70px
    height 70px
    border-radius 20px
    background rgba(255, 255, 255, 0.1)
    position absolute
    top 20px
    right 16px
    display flex
    align-items center
    justify-content center
    img
      width 64px
      height 64px
  .title
    //background: linear-gradient(90deg, #A6A4FF 0%, #EA94FF 100%);
    //-webkit-background-clip: text;
    //background-clip: text;
    //color: transparent;
    //font-weight bold
    width 286px
    height 35px
  .tip
    color rgba(255, 255, 255, 0.6)
    font-size 24px
</style>
