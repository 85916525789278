<template>
  <div class="mv-mini" @click.stop="handleClickMini" ref="mvMiniRef">
    <div class="default">
      <button @click.stop="handleClickDefaultButton">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.8">
            <mask
              id="mask0_362_24420"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="2"
              y="2"
              width="32"
              height="32"
            >
              <rect x="2" y="2" width="32" height="32" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_362_24420)">
              <circle
                cx="22.5864"
                cy="14"
                r="8"
                stroke="white"
                stroke-width="2.90909"
              />
              <path
                d="M14.4287 15.902L6.89545 25.9552C6.18094 26.9087 6.27601 28.2424 7.11854 29.0849V29.0849C7.96107 29.9275 9.29481 30.0225 10.2483 29.308L20.3015 21.7748"
                stroke="white"
                stroke-width="2.90909"
              />
            </g>
          </g>
        </svg>
        就唱这首
      </button>

      <div
        class="default-drawer"
        :class="{ show: showDrawer }"
        @click.stop="toggleDrawer"
      >
        <div class="default-drawer-content">
          <Carousel
            :itemsToShow="itemsToShow"
            :wrapAround="true"
            :transition="300"
            @slide-end="onSlideEnd"
            ref="myCarousel"
            @click.stop
            :breakpoints="carouselBreakpoints"
          >
            <Slide v-for="(slide, index) in defaultList" :key="slide">
              <div class="carousel__item" @click="handleItemClick(index)">
                <img :src="slide.cover" />
                <img
                  v-if="slide.song.is_vip"
                  class="song-block-vip"
                  src="https://qncweb.ktvsky.com/20241206/other/5589fc8a7694d35d4808e90e206b56d0.png"
                  alt=""
                />
                <i v-else class="free-icon">免费</i>
              </div>
            </Slide>
          </Carousel>
        </div>

        <div v-if="!showDrawer" class="default-drawer-paination">
          <span
            v-for="(i, index) in defaultList"
            :key="i"
            :class="{
              active: index === activeIndex,
            }"
          ></span>
        </div>
      </div>

      <div
        v-if="showMvMiniGuide && !showDrawer"
        class="default-guide"
        @click="handleClickGuide"
      >
        <img
          src="https://qncweb.ktvsky.com/20240710/other/c2e4820b1a75524f58aa37af3b4abbaa.png"
          alt=""
        />
      </div>
      <video-player
        v-if="defaultSong.video_url"
        v-touch:swipe="handleSwipe"
        from="indexDefault"
        ref="videoPlayerRef"
        :autoplay="true"
        :startPosition="0"
        :src="defaultSong.video_url"
        :token="''"
        :isSizeInherit="roundToNearestMinutes"
        :poster="require('./poster.png')"
        @ended="playNext"
      />
    </div>
  </div>
</template>

<script setup>
import VideoPlayer from '@/components/video-player/mp4.vue'
import useSongItem from '@/composables/useSongItem'
import eventBus from '@/utils/event-bus'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes/index.js'
import store2 from 'store2'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { useStore } from 'vuex'
// import useOrder from '@/composables/useOrder'
import logParams from '@/log'
import { sendLog } from '@/directives/v-log/log'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { orderSong } = useSongItem()

const showMvMiniGuide = ref(!store2.get('showMvMiniGuide'))
const showDrawer = ref(true)
const myCarousel = ref(null)
const activeIndex = ref(0)
const videoPlayerRef = ref(null)

const isLogin = computed(() => !!store.state.userInfo.unionid)
const vipInfo = computed(() => store.state.vipInfo)
const userType = computed(() => store.state.userInfo.userType)
const isShowAudition = computed(() => {
  if (!defaultSong.value.song?.is_vip) return false
  if (!isLogin.value || !vipInfo.value?.end_time) return true
  return false
})

const defaultSong = computed(() => store.state.miniMv.defaultSong)
const defaultList = computed(() => store.state.miniMv.defaultList)
const isDefault = computed(() => !store.state.orderedList.length)
const mvMiniRef = ref(null)
const itemsToShow = ref(4.2)
const carouselBreakpoints = {
  900: {
    itemsToShow: 4.2,
  },
}

let resizeObserver = null

const handleResize = (entries) => {
  for (let entry of entries) {
    const { width } = entry.contentRect
    if (width <= 900) {
      itemsToShow.value = 3.2
    } else {
      itemsToShow.value = 4.2
    }
    if (myCarousel.value) {
      myCarousel.value.updateSlideWidth()
    }
  }
}

onMounted(() => {
  if (typeof ResizeObserver !== 'undefined') {
    resizeObserver = new ResizeObserver(handleResize)
    if (mvMiniRef.value) {
      resizeObserver.observe(mvMiniRef.value)
    }
  }
})

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

const handleClickGuide = () => {
  store2.set('showMvMiniGuide', true)
  showMvMiniGuide.value = false
}

const toggleDrawer = () => {
  console.log('toggleDrawer', showDrawer.value)
  sendLog({
    event_type: '10000~50000',
    event_name: 30243,
    event_data: {
      str1: '首页',
      str2: `${showDrawer.value ? '下拉-隐藏' : '展开-显示'}定制歌单`,
      str3: '点击',
      str4: 'click',
    },
  })

  showDrawer.value = !showDrawer.value
}

const handleItemClick = (index) => {
  const carousel = myCarousel.value.data
  const currentSlide = carousel.currentSlide.value
  const totalSlides = defaultList.value.length

  if (index === 0 && currentSlide === totalSlides - 1) {
    carousel.next()
  } else if (index === totalSlides - 1 && currentSlide === 0) {
    carousel.prev()
  } else {
    myCarousel.value.slideTo(index)
  }

  activeIndex.value = index
  store.commit('miniMv/SET_DEFAULT_SONG', defaultList.value[activeIndex.value])
}

const playNext = async () => {
  activeIndex.value = (activeIndex.value + 1) % defaultList.value.length
  handleItemClick(activeIndex.value)
}

const playPrev = async () => {
  activeIndex.value =
    (activeIndex.value - 1 + defaultList.value.length) %
    defaultList.value.length
  handleItemClick(activeIndex.value)
}

const handleClickMini = () => {
  eventBus.emit('mp4-control-play')
  showDrawer.value = false
  // toggleDrawer()
}

const onSlideEnd = ({ currentSlideIndex }) => {
  console.log(currentSlideIndex)
  handleItemClick(currentSlideIndex)
}

const handleSwipe = (payload) => {
  console.log(payload)
  if (payload === 'left') {
    playNext()
  } else {
    playPrev()
  }
}

const handleOrder = () => {
  orderSong(defaultSong.value.song, {
    immediate: true,
    log: '就唱这首',
    fr: 1876,
    from: 'xifen',
  })
}

const handleAudition = () => {
  store.commit('SET_VIP_AUDITION_SONG', defaultSong.value.song)
  if (isLogin.value) {
    sendLog({
      ...logParams.get(30341),
      event_data: {
        ...logParams.get(30341).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: userType.value,
      }
    })
  } else {
    sendLog({
      ...logParams.get(30340),
      event_data: {
        ...logParams.get(30340).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: userType.value,
      }
    })
  }
  eventBus.emit('video-control-pause')
  store.commit('RESET_VIDEO_PLAYER_HLS')

  setTimeout(() => {
    router.push({
      name: 'vipaudition',
    })
  }, 800)

  // setTimeout(() => {
  //   Toast('VIP歌曲试唱中，完整版请开通VIP')
  // }, 1000)
}

const handleClickDefaultButton = async () => {
  console.log(isShowAudition.value)
  if (isShowAudition.value) {
    handleAudition()
    return
  }

  sendLog({
    event_type: '10000~50000',
    event_name: 30242,
    event_data: {
      str1: '首页',
      str2: '就唱这首',
      str3: '点击',
      str4: 'click',
      str5: isLogin.value ? '已登录' : '未登录',
      str7: userType.value,
    },
  })

  await Promise.all([handleOrder()])
}

watch(
  route,
  (val) => {
    if (videoPlayerRef.value) {
      if (val.name === 'climax') {
        videoPlayerRef.value.handleControlVideoPause()
      } else {
        videoPlayerRef.value.handleControlVideoPlay()
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
)
</script>

<style lang="stylus" scoped>
.mv-mini
  width 715px
  height 420px
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius var(--border-radius-large)
  margin-bottom 0
  position relative

  @media screen and (max-width 1200px) and (min-height 1200px)
    width 644px
    height 378px
    margin-bottom 0px

  & > div
    width 100%
    height 100%
    position absolute
    top 0
    left 0

  .video-player
    width 100%
    height 100%

    #video-player, video
      width 100% !important
      height 100% !important

  .default
    border-radius 32px
    overflow hidden
    button
      width 176px
      height 64px
      // background rgba(156, 174, 184, 0.6)
      backdrop-filter: blur(20px);
      color rgba(255, 255, 255, 1)
      position absolute
      right 24px
      top 24px
      display flex
      align-items center
      justify-content center
      border-radius 20px
      overflow hidden
      z-index 10
      font-size 24px
      font-weight bold

      @media screen and (max-width 1200px) and (min-height 1200px)
        // transform scale(calc(137 / 202))
        // transform-origin right

      svg
        margin-right 10px

    &-guide
      position absolute
      z-index 10
      left 0
      top 0
      width 100%
      height 100%
      background rgba(0, 0, 0, 0.8)
      display flex
      align-items center
      justify-content center
      z-index 9999
      img
        width 388px
        height auto

    &-drawer
      position absolute
      z-index 20
      bottom 48px
      left 0
      width 100%
      min-height 30px
      height auto //154px
      background-color rgba(0, 0, 0, 0.8)
      transition transform 0.3s ease-in-out
      transform translateY(145px)
      &:after
        content ''
        position absolute
        top 2px
        left 50%
        margin-left -34px
        width 68px
        height 24px
        // background #D9D9D9
        background url('https://qncweb.ktvsky.com/20241224/vadd/1d3d06736b6f4e1e94f17b693d49878c.png') no-repeat
        background-size 100% 100%
        background-position center

      @media screen and (max-width 1200px) and (min-height 1200px)
        height auto
        bottom 53px
        // transform translateY(60px)

      &-paination
        display flex
        justify-content center
        align-items center
        margin-top 30px
        height auto
        position absolute
        width 100%
        top 0
        left 0

        span
          width 8px
          height 8px
          border-radius 50%
          background #FFFFFF33
          margin 0 5px
          &.active
            background #FFFFFFE5
            // width 32px
            // border-radius 10px

      &.show
        bottom 0
        transform translateY(0)
        border-top-left-radius 0px
        border-top-right-radius 0px
        &:after
          content ''
          position absolute
          top 6px
          left 50%
          margin-left -34px
          width 68px
          height 24px
          // background #D9D9D9
          background url('https://qncweb.ktvsky.com/20241226/vadd/025735e8e67a7821cf5837ec13ed92ad.png') no-repeat
          background-size 100% 100%
          background-position center
          // border-radius 10px

          @media screen and (max-width 1200px) and (min-height 1200px)
            width 33px
            height 4px
            margin-left -16px

        .carousel
          position relative
          margin-top 43px

          @media screen and (max-width 1200px) and (min-height 1200px)
            margin-top 20px

      &-content
        ::v-deep .carousel
          margin-top 41px
          height 101px

          @media screen and (max-width 1200px) and (min-height 1200px)
            height 90px !important

          &__viewport
            height 100%

          &__track
            height 100%

          &__item
            width 240px
            height 100%
            border-radius 20px
            overflow hidden
            transform scale(calc(154 / 240)) translateX(60px)
            position relative

            p
              width 100%
              height 40px
              line-height 40px
              background #00000033
              font-size 20px
              color #FFFFFF
              text-align center
              position absolute
              bottom 0
              left 0

            .free-icon
              width 48px
              height 24px
              display flex
              align-items center
              justify-content center
              background: linear-gradient(90deg, #4ADBEF -0.89%, #1CA8C7 99.11%);
              color #fff
              font-size 16px
              border-radius 12px
              position absolute
              right 5px
              top 5px
            .song-block-vip
              width 48px
              height 24px
              position absolute
              right 5px
              top 5px
              @media screen and (max-width 1200px) and (min-height 1200px)
                width 35px
                height auto

          &__slide--next ~ .carousel__slide
            .carousel__item
              transform scale(calc(154 / 240)) translateX(-60px)

          &__slide--prev, &__slide--next
            .carousel__item
              transform scale(calc(192 / 240))

          &__slide--active
            .carousel__item
              transform scale(1)
</style>
