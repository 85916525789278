<template>
  <div class="singer-detail-side">
    <div class="singer-detail-side-back" @click="$emit('back')">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.71 3.33595L18.3464 2.69955L17.0736 1.42676L16.4372 2.06315L1.16374 17.3367L0.527344 17.9731L1.16374 18.6095L16.4372 33.883L17.0736 34.5194L18.3464 33.2466L17.71 32.6102L3.99942 18.8995H33.3018V17.0995H3.94643L17.71 3.33595Z"
          fill="#1D1D1F"
        />
      </svg>

      {{ singerData.name }}
    </div>
    <svg
      class="close-side"
      @click="$emit('close')"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.74219 26.3708L5.62781 28.2564L16 17.8842L26.3724 28.2566L28.258 26.371L17.8856 15.9986L28.2552 5.629L26.3696 3.74338L16 14.113L5.6306 3.74358L3.74498 5.6292L14.1144 15.9986L3.74219 26.3708Z"
          fill="#1D1D1F"
        />
      </g>
    </svg>
    <div class="singer-detail-side-main">
      <img
        class="fixed-header"
        v-if="singerHeaderIsfixed"
        :src="singerData.image"
        v-img-fallback="imgFallback"
      />
      <div class="list">
        <LoadMore
          class="song-list"
          ref="loadMoreRef"
          v-if="dataList.length"
          @load-more="fetchData"
          safeAreaHeight="8.6991vw"
        >
          <img
            class="header"
            :src="singerData.image"
            v-img-fallback="imgFallback"
          />
          <SongItem
            v-for="(songItem, index) in dataList"
            :key="index"
            :songItem="songItem"
            :log-from="{
              song_list_source: 12,
              fr2: '欢唱页歌手点歌会员歌曲',
            }"
            :singerEnable="false"
            :isStickButton="false"
          />
        </LoadMore>
        <div v-else-if="!isRequest" class="empty">
          <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.4" clip-path="url(#clip0_346_155436)">
              <circle
                cx="62.1964"
                cy="33.4815"
                r="18.5557"
                stroke="#1D1D1F"
                stroke-width="4"
              />
              <path
                d="M45.0664 38.9043L21.6423 68.7885L28.1023 75.2485L57.9865 51.8244"
                stroke="#1D1D1F"
                stroke-width="4"
              />
              <path
                d="M18.8096 77.4078L20.1455 78.8963L20.1455 78.8963L18.8096 77.4078ZM9.2533 68.1959L10.7898 69.4763L9.2533 68.1959ZM15.6039 60.5749L17.1404 61.8552L17.1404 61.8552L15.6039 60.5749ZM12.9925 54.9995L12.9925 52.9995L12.9925 52.9995L12.9925 54.9995ZM12.75 52.9995C11.6454 52.9995 10.75 53.8949 10.75 54.9995C10.75 56.1041 11.6454 56.9995 12.75 56.9995L12.75 52.9995ZM9.79607 77.293L11.1694 75.8391L11.1694 75.8391L9.79607 77.293ZM15.2047 55.8179L13.9031 57.3364L15.2047 55.8179ZM24.6137 69.5115L17.4738 75.9194L20.1455 78.8963L27.2854 72.4884L24.6137 69.5115ZM10.7898 69.4763L17.1404 61.8552L14.0675 59.2945L7.71683 66.9156L10.7898 69.4763ZM12.9925 52.9995L12.75 52.9995L12.75 56.9995L12.9925 56.9995L12.9925 52.9995ZM11.1694 75.8391C9.38241 74.1512 9.21616 71.3647 10.7898 69.4763L7.71683 66.9156C4.79078 70.427 5.09992 75.6084 8.42274 78.747L11.1694 75.8391ZM17.4738 75.9194C15.6715 77.5369 12.9299 77.502 11.1694 75.8391L8.42274 78.747C11.6964 81.8391 16.7942 81.904 20.1455 78.8963L17.4738 75.9194ZM16.5063 54.2993C15.5277 53.4606 14.2814 52.9995 12.9925 52.9995L12.9925 56.9995C13.3265 56.9995 13.6495 57.119 13.9031 57.3364L16.5063 54.2993ZM17.1404 61.8552C19.0306 59.5869 18.7481 56.2209 16.5063 54.2993L13.9031 57.3364C14.4841 57.8344 14.5573 58.7067 14.0675 59.2945L17.1404 61.8552Z"
                fill="#1D1D1F"
              />
            </g>
            <defs>
              <clipPath id="clip0_346_155436">
                <rect width="90" height="90" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>暂无歌曲</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SongItem from '@/components/song-item/index.vue'
import { searchSinger } from '@/service/search'
import { onBeforeUnmount, onMounted, ref, toRefs } from 'vue'

export default {
  name: 'SingerDetail',
  props: {
    singerData: {
      default: () => {
        return {
          singerid: '',
          name: '',
          image: '',
        }
      },
      type: Object,
    },
  },
  components: {
    SongItem,
  },
  setup(props) {
    const { singerData } = toRefs(props)

    const imgFallback = {
      loading:
        'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png',
      error:
        'https://qncweb.ktvsky.com/20231226/vadd/a3056a76c9626aa4d68318625ae87a35.png',
    }

    let p = 1
    let version = ref({
      current: '',
      latest: '',
    })
    let isRequest = ref(false)
    let dataList = ref([])
    let loadMoreRef = ref(null)
    let singerHeaderIsfixed = ref(false)

    const requestBussinessData = async () => {
      let responseData = []
      const { singerid } = singerData.value
      if (singerid) {
        const { data } = await searchSinger(singerid, p)
        responseData = {
          data: data.song,
        }
      }
      return responseData
    }

    const fetchData = async () => {
      if (isRequest.value) {
        return
      }
      isRequest.value = true

      const res = await requestBussinessData()
      if (res.data.length !== 0) {
        if (p === 1 && res.version) {
          version.value = res.version
        }
        dataList.value = dataList.value.concat(res.data)
        p++
      }
      isRequest.value = false
    }

    const handleScroll = (e) => {
      const scrollTop = e.target.scrollTop
      singerHeaderIsfixed.value = scrollTop > 210
    }

    onMounted(() => {
      // 请求数据
      fetchData()
      setTimeout(() => {
        if (loadMoreRef.value)
          loadMoreRef.value.root.addEventListener('scroll', handleScroll, false)
      }, 800)
    })

    onBeforeUnmount(() => {
      if (loadMoreRef.value)
        loadMoreRef.value.root.removeEventListener('scroll', handleScroll)
    })

    return {
      imgFallback,
      dataList,
      singerHeaderIsfixed,
      fetchData,
      loadMoreRef,
      isRequest,
    }
  },
}
</script>

<style lang="stylus" scoped>
.singer-detail-side
  width 100%
  height 100vh
  padding 0 48px
  position relative
  .empty
    svg
      width 80px
      height 80px
      margin 300px auto 40px
    p
      height 32px
      line-height 32px
      color #1D1D1F66
      font-size 28px
      text-align center
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 0 0px
  ::-webkit-scrollbar
    display none
  &-title
    color rgba(255, 255, 255, 0.8)
    font-size 40px
    margin 64px 0 65px
    width 500px
    height 48px
    line-height 48px
    font-weight 600
  &-back
    color #1D1D1FCC
    font-size 32px
    font-weight 400
    display flex
    align-items center
    margin 63px 0 63px
    width 500px
    height 38px
    line-height 38px
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin 0px 0 36px
      padding 0 28px 0 60px
      height 130px
      width 100%
      border-bottom: 2px solid #0000001A
    svg
      width 36px
      height 36px
      margin-right 30px
  .close-side
    position absolute
    top 61px
    right 48px
    width 32px
    height 32px
    img
      width 100%
      height 100%
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 48px
      width 38px
      height 38px
  &-main
    width 100%
    height 88vh
    display flex
    flex-direction column
    align-items center
    overflow-y scroll
    @media screen and (max-width 1200px) and (min-height 1200px)
      padding 0 64px
    @keyframes fadeIn {
      from {
        opacity 0
      }
      to {
        opacity 1
      }
    }
    .visi-hidden
      visibility hidden
    .fixed-header
      position absolute
      top 52px
      left 50%
      margin-left -30px
      width 60px
      height 60px
      border-radius 50%
      animation fadeIn 1s ease-in-out
      @media screen and (max-width 1200px) and (min-height 1200px)
        top 28px
    .list
      width 100%
      ::-webkit-scrollbar
        display none
      .song-list
        width 100% !important
        padding 0 !important
        .header
          width 200px
          height 200px
          border-radius 50%
          margin 0 auto
          margin-bottom 48px
          padding 0 !important
        ::v-deep .song-item-list
          padding 0
          .song-item
            margin 0px
            .right
              margin-right 0
</style>
