<template>
  <div
    class="user-vip personal-user-vip"
    :class="{
      vip: isVip,
      vertical: browserType !== 'landscape',
    }"
  >
    <div class="personal-user-vip-top">
      <div class="user-vip-info">
        <div>
          <img
            class="avatar"
            :src="
              userInfo.avatar ||
              'https://qncweb.ktvsky.com/20211108/vadd/978f457f5f485e19364f234b2cfcbf07.png'
            "
          />
        </div>
        <div class="user-vip-info-user">
          <div class="username">
            {{ userInfo.username }}
          </div>
          <div v-if="isLogin" class="desc">{{ vipDesc }}</div>
        </div>

        <div class="user-vip-info-entry">
          <template v-if="from === 'profile'">
            <div class="user-vip-info-entry-active" @click="handleExchange">
              兑换VIP
            </div>
          </template>
          <template v-else-if="from === 'mine'">
            <div class="user-vip-info-entry-active" @click="handleProfile">
              会员中心
            </div>
            <div @click="handleSetting">设置</div>
          </template>
        </div>
      </div>
    </div>

    <div class="dis_flex pkg-bg">
      <div class="flex_1">
        <div class="personal-user-vip-pkg-title">
          <div class="pkg-title-left pkg-title-bg pkg-title-bg1">VIP权益</div>
          <div class="pkg-title-right">
            <div class="pkg-title-bg pkg-title-bg2">海量曲库</div>
            <div class="pkg-title-bg pkg-title-bg3">1080P MV</div>
            <div class="pkg-title-bg pkg-title-bg4">无广告</div>
          </div>
        </div>

        <div
          v-if="browserType === 'landscape'"
          class="personal-user-vip-packages"
        >
          <div class="vip-packages">
            <div class="ps">
              <div
                class="vip-packages-item"
                v-for="item in packages"
                :key="item.id"
                @click="handleClickItem(item)"
              >
                <div
                  class="vip-packages-item-content"
                  :class="[item.id === selectItem.id && 'active']"
                >
                  <div class="days">{{ item.title }}</div>
                  <div class="day-price">
                    <span>¥</span>{{ formatValue(item.day_fee)
                    }}<span>元/天</span>
                  </div>
                  <div class="price">
                    <span>¥{{ formatValue(item.fee) }}</span>
                    <span v-if="item.fee !== item.old_fee" class="origin"
                      >¥{{ formatValue(item.old_fee) }}</span
                    >
                  </div>
                </div>

                <div
                  v-if="item.tips"
                  class="tips"
                  :class="item.isOrange && 'orange'"
                >
                  {{ item.tips }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="personal-user-vip-openvip">
        <div v-if="browserType !== 'landscape'" class="vip-packages">
          <div
            class="vip-packages-item"
            v-for="item in packages"
            :key="item.id"
            @click="handleClickItem(item)"
            :class="[item.id === selectItem.id && 'active']"
          >
            <div class="left">
              <div class="days">{{ item.title }}</div>
              <div class="price">
                <span>¥{{ formatValue(item.fee) }}</span>
                <span v-if="item.fee !== item.old_fee" class="origin"
                  >¥{{ formatValue(item.old_fee) }}</span
                >
              </div>
            </div>
            <div class="day-price">
              ¥<span>{{ formatValue(item.day_fee) }}</span
              >/天
            </div>
            <div
              v-if="item.tips"
              class="tips"
              :class="item.isOrange && 'orange'"
            >
              {{ item.tips }}
            </div>
          </div>
        </div>
        <div class="pay-info">
          <div class="left">
            <div class="price" v-html="formatPayTitle"></div>
            <h3>微信扫码支付</h3>
            <p>有效期至-{{ expirationDate }}</p>
          </div>
          <div class="code">
            <img :src="qrCodeURL" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getVipPkg, getVipPkgQr } from '@/service/vip'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import { computed, onBeforeMount, ref, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import { useStore } from 'vuex'

export default {
  name: 'vipComponent',
  components: {
    // PerfectScrollbar
  },
  props: {
    from: {
      type: String,
      default: 'mine',
    },
    fr: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const { from, fr } = toRefs(props)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { getQRCodeURL } = useQRCode()
    const { browserType } = useShareBrowserSize()

    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    const isVip = computed(() => !!vipInfo.value.end_time)
    const setting = computed(() => store.state.setting)
    const loginSendVip = computed(() => store.state.activityInfo.loginSendVip)
    const isShowSignIn = computed(() => store.state.signin.isShowSignIn)
    const unionid = computed(() => store.state.userInfo.unionid)

    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const packages = ref([])
    const selectItem = ref({})

    const vipDesc = computed(() => {
      return isVip.value
        ? `会员有效期至: ${vipInfo.value.end_time
            .split(' ')[0]
            .replaceAll('-', '.')}`
        : '未开通 VIP'
    })

    const expirationDate = computed(() => {
      if (!selectItem.value.days) return ''
      const currentDate = isVip.value
        ? new Date(vipInfo.value.end_time)
        : new Date()
      const expirationDate = addDays(currentDate, selectItem.value.days)

      return format(expirationDate, 'yyyy.MM.dd')
    })

    const formatValue = (value) => {
      if (value === undefined || isNaN(value)) {
        return 'N/A'
      }
      return value / 100
    }

    const frObj = {
      mine: 1830,
      profile: 1831,
    }

    const getPayLog = () => {
      if (fr.value) return fr.value
      if (from.value === 'mv') return 1834
      if (frObj[route.name]) {
        return frObj[route.name]
      }
      return 1759
    }

    const getVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: selectItem.value.id,
          fr: getPayLog(),
        })

        const qr = get(data, 'qr', '')
        if (qr) {
          const qrCodeData = await getQRCodeURL(qr)
          if (qrCodeData) {
            qrCodeURL.value = qrCodeData
            // store.dispatch('startVipInfoStatus');
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    const formatPayTitle = computed(() => {
      const regex = /(\d+)(年|天)/g
      if (!selectItem.value.title) return ''

      const matches = selectItem.value.title.match(regex)
      if (!matches) return ''

      const formatted = matches
        .map((match) => {
          const [_, number, unit] = match.match(/(\d+)(年|天)/)
          return `<span class="user-vip-year-num">${number}</span>${unit}`
        })
        .join(' ')

      return formatted
    })

    const fetchConfig = async () => {
      if (!unionid.value) return
      const res = await getVipPkg(unionid.value)
      packages.value = res.data
      if (packages.value.length === 3) {
        const index = packages.value.findIndex((item) => !!item.tips)
        packages.value[index].isOrange = true
      } else {
        const reversedIndex = packages.value
          .slice()
          .reverse()
          .findIndex((item) => !!item.tips)
        const index = packages.value.length - 1 - reversedIndex
        packages.value[index].isOrange = true
      }

      const active =
        packages.value.find((item) => item.id === res.recommend_pkg) ||
        packages.value[0]
      selectItem.value = active

      getVipQrcode()
    }

    const handleClickItem = (item) => {
      qrCodeURL.value =
        'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
      selectItem.value = item
      getVipQrcode()
    }

    const handleProfile = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
        },
      })
      router.push({
        name: 'profile',
      })
    }

    const handleExchange = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10030,
        event_data: {
          str1: '我的',
          str2: '开通VIP',
          str3: '点击进入',
          str4: 'click',
          str5: '会员兑换',
          str6: 'click',
        },
      })
      router.push({
        name: 'vipExchange',
      })
    }

    const handleSetting = () => {
      router.push({
        name: 'setting',
      })
    }

    watch(unionid, (val) => {
      if (val) {
        fetchConfig()
      }
    })

    onBeforeMount(() => {
      fetchConfig()
    })

    return {
      vipInfo,
      userInfo,
      isLogin,
      isVip,
      setting,
      isShowSignIn,
      loginSendVip,
      vipDesc,
      formatValue,
      packages,
      expirationDate,
      selectItem,
      handleClickItem,
      qrCodeURL,
      handleProfile,
      handleExchange,
      handleSetting,
      formatPayTitle,
      browserType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.personal-user-vip
  width 100%
  height auto
  position relative
  right unset
  top unset
  display flex
  flex-direction column
  background none
  color #1D1D1F
  &.vip
    .username
      color rgba(221, 153, 73, 1) !important
    .desc
      background-image url(https://qncweb.ktvsky.com/20241202/vadd/bb6d14f78822c0a383d9633ef46fc370.png) !important
  .tips
    padding 0 10px
    height 40px
    line-height 40px
    font-size 22px
    color #fff
    position absolute
    left 0
    top 0px
    background linear-gradient(90deg, #FF3D6B 0%, #8C1AFF 100%)
    border-radius 10px 10px 0px 0
    z-index 1
    &.orange
      background linear-gradient(90deg, #ff3d3d 0%, #ff881a 100%)

  // justify-content space-between
  &-top
    display flex
    flex-direction column
    justify-content center
    width 100%
    height 152px
    margin-bottom 32px
    background transparent
    border-radius 10px
    background rgba(255, 255, 255, 0.05)
    @media screen and (max-width 1200px) and (min-height 1200px)
      background transparent
      height 110px
  &-packages
    border-top 1px solid rgba(29, 29, 31, 0.1)
    padding-top 38px
    margin 0 70px 0 40px
    .vip-packages
      min-width 640px
      height fit-content
      .ps
        display flex
        padding-top 0
        overflow-x auto
      &-item
        width: 200px;
        height: 280px;
        position relative
        text-align center
        margin-right 20px
        flex-shrink 0
        overflow auto
        &-content
          width: 200px;
          height: 260px;
          display flex
          flex-direction column
          justify-content center
          align-items center
          position absolute
          bottom 0
          left 0
          z-index 1
          border-radius: 10px;
          background rgba(255, 255, 255, 1)
          backdrop-filter blur(100px)
          &.active
            background linear-gradient(321.82deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%)
            backdrop-filter blur(100px)

            .days
              color rgba(67, 57, 31, 1)
            .day-price
              color rgba(136, 55, 0, 1)
            .price
              color rgba(172, 98, 0, 1)
              .origin
                color rgba(172, 98, 0, 0.5)
        .days
          color rgba(221, 153, 73, 1)
          font-size: 28px;
          font-weight: 300;
          margin-top 4px
          line-height 1
        .day-price
          color rgba(221, 153, 73, 1)
          font-size: 42px;
          margin 24px 0
          line-height 1
          span
            font-size: 20px;
        .price
          font-size: 24px;
          color rgba(29, 29, 31, 1)
          display flex
          justify-content center
          font-weight: 300;
          line-height 1
          .origin
            color rgba(29, 29, 31, 0.5)
            font-size: 24px;
            text-decoration line-through
            margin-left 8px
  .user-vip
    &-info
      display flex
      align-items center
      position relative
      margin 0
      padding 0 36px
      .avatar
        width 120px
        height 120px
        border-radius 100%
        margin-right 24px
      &-user
        .username
          max-width 439px
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          color rgba(29, 29, 31, 1)
          font-size var(--font-size-large)
          line-height 1
          margin-bottom 16px
        .desc
          color rgba(29, 29, 31, 0.5)
          font-size var(--font-size-small)
          line-height 1
          padding-left 66px
          background-repeat no-repeat
          background-size 50px auto
          background-image url(https://qncweb.ktvsky.com/20241202/vadd/00bd1182cdaceea72e78fa3a916b14e1.png)
          background-position left center
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 0px
        .avatar
          width 110px
          height 110px
          &-user
            .username
              font-size 32px
          .desc
            font-size 28px

      &-entry
        display flex
        position absolute
        right 0
        top 20px
        padding-right 0
        div
          display flex
          justify-content center
          align-items center
          width: 200px;
          height: 80px;
          border-radius: 100px;
          border 2px solid rgba(29, 29, 31, 0.2)
          background transparent
          color rgba(29, 29, 31, 1)
          font-size var(--font-size-medium)
          &:not(:last-child)
            margin-right 32px
            @media screen and (max-width 1200px) and (min-height 1200px)
              margin-right 24px
        &-active
          color rgba(221, 153, 73, 1) !important
          background rgba(255, 255, 255, 1) !important
  &-openvip
    width 372px
    height 500px
    // background rgba(255, 255, 255, 0.06)
    // backdrop-filter blur(50px)
    display flex
    border-radius 10px
    // overflow hidden
    position relative
    &::before
      content ''
      position absolute
      top 0
      left -143px
      width 143px
      height 500px
      background url(https://qncweb.ktvsky.com/20241202/vadd/60fe1c78fabb7cd4e870a6d01475ac1f.png) no-repeat
      background-size auto 100%
      background-position center
      z-index 0
    .pay-info
      width 372px
      height 500px
      padding 24px 24px 24px 36px
      display flex
      flex-direction column
      align-items center
      justify-content center
      .left
        width 220px
        text-align left
        padding 0
        ::v-deep .price
          color rgba(219, 174, 106, 1)
          font-size 28px
          margin-bottom 4px
          height 82px
          text-align center
          span
            font-size 70px
            line-height 70px
            // margin-left 16px
        h3
          color rgba(29, 29, 31, 0.8)
          font-weight 400
          font-size var(--font-size-medium)
          margin 20px 0 12px
          text-align center
        p
          color rgba(29, 29, 31, 0.6)
          font-size 22px
          opacity 0.8
          text-align center
      .code
        width 200px
        height 200px
        background #fff
        border-radius 8px
        padding 0px
        margin-top 20px
        overflow hidden
        img
          width 188px
          height 188px
          margin 6px
  &-pkg-title
    width 1120px
    height 94px
    display flex
    align-items center
    justify-content space-between
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 100%
    .pkg-title-left
      font-size var(--font-size-large)
      font-weight 500
      background: -webkit-linear-gradient(180deg, #5C390F 17.07%, #A48663 80.49%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width 208px
      height 42px
      padding-left 78px !important
      margin-left 40px
      position relative
      @media screen and (max-width 1200px) and (min-height 1200px)
        font-size 32px
    .pkg-title-right
      font-size var(--font-size-medium)
      font-weight 400
      color rgba(29, 29, 31, 0.8)
      display flex
    .pkg-title-bg
      height 33px
      width auto
      padding-left 52px
      position relative
      line-height 1
      &::before
        content ''
        position absolute
        top 0
        left 0px
        width 42px
        height 32px
        background url('') no-repeat
        background-size auto 100%
        background-position center
    .pkg-title-bg1::before
      width 66px
      height 36px
      background url('https://qncweb.ktvsky.com/20241202/vadd/360f4a71603d8074f81fb783f6fd0899.png') no-repeat
    .pkg-title-bg2
      width 194px
      &::before
        background url('https://qncweb.ktvsky.com/20241202/vadd/73a38d38e336449d0dcd02c26792a9d1.png') no-repeat
    .pkg-title-bg3
      width 243px
      padding-left 82px
      margin-right 30px
      border-left 2px solid rgba(29, 29, 31, 0.1)
      border-right 2px solid rgba(29, 29, 31, 0.1)
      &::before
        left 30px
        background url('https://qncweb.ktvsky.com/20241202/vadd/4aeb665df0110611f66a74a6c53cc261.png') no-repeat
    .pkg-title-bg4
      width 166px
      &::before
        background url('https://qncweb.ktvsky.com/20241202/vadd/0747d259d189722c0543e3fbc986d70f.png') no-repeat
  .pkg-bg
    background #FFFFFF80
    backdrop-filter blur(100px)
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction column
.vertical
  .pkg-bg
    border-radius 20px
  .flex_1
    border-bottom: 2px solid #1D1D1F1A
  .personal-user-vip-openvip
    width 100%
    height auto !important
    background url('https://qncweb.ktvsky.com/20241209/other/2e1b7ddbac1ef172ff1af0374d48243f.png') no-repeat 430px center
    background-size auto 684px
    margin 30px 0 28px
  .vip-packages
    margin-left 65px
    &-item
      margin-bottom 20px
      width 500px
      height 124px
      background #FFFFFF
      border-radius 12px
      position relative
      color #DD9949
      display flex
      justify-content space-between
      align-items center
      padding 0 40px
      font-size 32px
      .days
        font-weight 700
      .price
        font-size 28px
        color #1D1D1F
        .origin
          opacity 0.5
          margin-left 12px
      .day-price
        font-size 24px
        span
          font-size 50px
          font-weight 700
      .tips
        left unset
        right 0px
        top -10px
        border-radius 6px
        height 40px
        font-size 22px
    .active
      background: linear-gradient(321.82deg, #FFE093 5.61%, #FFEBB5 34.88%, #FFECB9 96.2%);
      .days
        color #43391F
      .price
        color #AC6200
      .day-price
        color #883700
  .pay-info
    padding 0px
    height auto
    flex 1
    padding-left 56px
    h3
      margin-top 17px !important
    .code
      margin-top 60px
    .price
      font-size 28px
      span
        font-size 88px
</style>
