<template>
    <div class="container">
      <!-- 第一个按钮 -->
      <div
        class="button1"
        :class="{ 'move-left': isMoveLeft, 'move-right': isMoveRight }"
      >
        <img src="https://qncweb.ktvsky.com/20250117/vadd/1b6e6fefd24049dfb86a0b98ee60413f.png"/>
        <span class="vip">开通会员</span>
        解锁完整歌曲
      </div>
      <!-- 第二个按钮 -->
      <div class="button2" :style="{ opacity: button2Opacity }">立即开通</div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ButtonAnimation",
    data() {
      return {
        isMoveLeft: false, // 控制第一个按钮向左移动
        isMoveRight: false, // 控制第一个按钮向右移动
        button2Opacity: 0, // 控制第二个按钮的透明度
      };
    },
    mounted() {
      // 第一个按钮向左移动
      setTimeout(() => {
        this.isMoveLeft = true;
      }, 0);
  
      // 第一个按钮停留 4 秒后向右移动，同时第二个按钮显示
      setTimeout(() => {
        this.isMoveLeft = false;
        this.isMoveRight = true;
        this.button2Opacity = 1;
      }, 4500); // 0.5s 移动 + 4s 停留
    },
  };
  </script>
  
  <style scoped>
  /* 容器样式 */
  .container {
    position: relative;
    width: 100%;
    height: 108px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  /* 第一个按钮样式 */
  .button1 {
    position: absolute;
    width: 461px;
    height: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.6);
    border-radius: 10px;
    color: #fff;
    z-index: 9;
    font-size: 32px;
    font-weight: 400;
    left: 100%; /* 初始位置在屏幕右侧之外 */
    transition: left 0.5s ease-in-out;
  }
  .button1 img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .button1 span {
    color: rgba(219, 174, 106, 1);
    margin-right: 16px;
  }
  /* 第一个按钮向左移动 */
  .button1.move-left {
    left: 0;
  }
  
  /* 第一个按钮向右移动 */
  .button1.move-right {
    left: 100%;
  }
  
  /* 第二个按钮样式 */
  .button2 {
    position: absolute;
    z-index: 10;
    width: 240px;
    height: 108px;
    right: 0;
    background: linear-gradient(269.91deg, #F6BC6B 0.07%, #F3D195 99.91%);
    font-size: 32px;
    font-weight: 500;
    color: rgba(88, 51, 13, 1);
    text-align: center;
    line-height: 108px;
    border-radius: 10px;
    opacity: 0; /* 初始隐藏 */
    transition: opacity 0.2s ease-in-out;
  }
  </style>