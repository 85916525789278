<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="lower-vip-v-qrcode"
      :style="{ backgroundImage: `url(${currBg})` }"
    >
      <div @click="handleCloseModal" class="close"></div>

      <div class="m-vip-tips">
        <p>
          <span>{{ currSelected == 1 ? 2 : 1 }}</span>
          年
        </p>
        <p>微信扫码支付</p>
        <p>有效期至-{{ expirationDate }}</p>
        <div class="m-vip-tips-qrcode">
          <img :src="qrCodeURL" />
        </div>
      </div>

      <div class="pkg">
        <div class="pkg-item" @click="handleClickPackage(0)"></div>
        <div class="pkg-item" @click="handleClickPackage(1)"></div>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
// import useRetainModal from '@/composables/useRetainModal'
import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { getUserRoleTag } from '@/utils/historyCache'
import { addDays, format } from 'date-fns'
import get from 'lodash/get'
import { computed, onBeforeMount, onMounted, ref, toRefs } from 'vue'

export default {
  name: 'LowerVipZdQrcode',
  components: {
    CommonModal,
  },
  props: {
    fr: {
      type: Number,
      default: 0,
    },
    closeCallback: {
      type: Function,
      default: null,
    },
    isVideoEnd: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { fr, closeCallback, isVideoEnd } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(0) // 1 新用户 2 老用户

    const currSelected = ref(0)
    const pkg = [
      {
        id: 22,
        value: '1年',
        img: {
          old: 'https://qncweb.ktvsky.com/20241205/vadd/3450325fc6a7814d5e1fd9007e1c80ce.png',
          new: 'https://qncweb.ktvsky.com/20241205/vadd/323fc40ab1716e6c3ac881988df0c092.png',
        },
      },
      {
        id: 18, // 需调整
        value: '2年',
        img: {
          old: 'https://qncweb.ktvsky.com/20241205/vadd/fff510a969a58e41b950b9cc5961c0ce.png',
          new: 'https://qncweb.ktvsky.com/20241205/vadd/f8db64f0b4c28b330b1ad2bcc82f4c0f.png',
        },
      },
    ]
    // 试唱结束后 弹窗内容
    const vidoeEndpkg = [
      {
        id: 22,
        value: '1年',
        img: {
          old: 'https://qncweb.ktvsky.com/20250120/vadd/83852745db68deae30e2ab41b3047551.png',
          new: 'https://qncweb.ktvsky.com/20250120/vadd/6ef9f4b3a79d43935636858facf522a2.png',
        },
      },
      {
        id: 18, // 需调整
        value: '2年',
        img: {
          old: 'https://qncweb.ktvsky.com/20250120/vadd/c35ce17ce1a1ab374568153c3a1b7b64.png',
          new: 'https://qncweb.ktvsky.com/20250120/vadd/b3fe0bc95bc7b6037447c121af23a822.png',
        },
      },
    ]
    const currBg = computed(
      () => isVideoEnd.value ? vidoeEndpkg[currSelected.value].img[isNewUser.value !== 2 ? 'new' : 'old'] : pkg[currSelected.value].img[isNewUser.value !== 2 ? 'new' : 'old']
    )

    const end_time = computed(() => store.state.vipInfo.end_time)

    const isVip = computed(() => !!end_time.value)

    const expirationDate = computed(() => {
      const currentDate = isVip.value ? new Date(end_time.value) : new Date()
      const expire = addDays(currentDate, currSelected.value === 0 ? 365 : 730)

      return format(expire, 'yyyy.MM.dd')
    })

    const handleClickPackage = (index) => {
      console.log(index)
      currSelected.value = index
      getVipQrcode()
    }

    const getZdVipQrcode = async () => {
      console.log('PAY LOG:', fr.value)
      let from =
        fr.value === vipLogFrom.get('免费歌曲主动弹-zd')
          ? vipLogFrom.get('免费歌曲主动弹-lower-zd')
          : ( fr.value ? fr.value : 1901 )
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: pkg[currSelected.value].id,
          fr: from,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          root.value.hide()
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getVipQrcode = async () => {
      sendLog({
        event_type: '30000～35000',
        event_name: 30326,
        event_data: {
          str1: '通用',
          str2: `新付费弹窗2`,
          str3: '展示',
          str4: 'show',
          str5: '已登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })

      getZdVipQrcode()
    }
    const handleCloseModal = () => {
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')
      root.value.hide()

      closeCallback.value && closeCallback.value()
    }
    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }

    onBeforeMount(getVipQrcode)

    onMounted(() => {
      isNewUser.value = getUserRoleTag()
      // console.log(tag)
    })

    return {
      qrCodeURL,
      root,
      currBg,
      currSelected,
      expirationDate,
      vidoeEndpkg,
      handleCancel,
      handleCloseModal,
      handleClickPackage,
    }
  },
}
</script>

<style lang="stylus" scoped>
.lower-vip
  &-v-qrcode
    position relative
    width 1000px
    height 720px
    padding-top 0 !important
    background url('') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .pkg
      position absolute
      top 220px
      left 114px
      width 440px
      height 252px
      display flex
      .pkg-item
        width 200px
        height 252px
    .close
      position absolute
      top 25px
      right 30px
      left unset!important
      width 40px
      height 40px
    .m-vip-tips
      width 172px
      height 350px
      position absolute
      top 166px
      right 114px
      display flex
      flex-direction column
      align-items center
      p
        &:nth-child(1)
          font-size 22px
          font-weight 500
          color rgba(136, 55, 0, 1)
          span
            font-size 64px
        &:nth-child(2)
          font-size 22px
          font-weight 400
          color rgba(0, 0, 0, 0.8)
          margin 8px 0
        &:nth-child(3)
          font-size 16px
          font-weight 400
          color rgba(0, 0, 0, 0.6)
          margin-bottom 8px
      &-qrcode
        // position absolute
        // bottom 3px !important
        // left 50%
        // margin-left -70px
        width 160px
        height 160px
        display flex
        justify-content center
        align-items center
        background #FFFFFF
        border-radius 12px
        img
          width 146px
          height 146px
          border-radius 5px
</style>
