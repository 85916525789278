import { vipLogFrom } from '@/constants/index'
import { sendLog } from '@/directives/v-log/log'
import store from '@/store'
import eventBus from '@/utils/event-bus'
import { getFreeSongNoTimeShow, setAlreadyList, setOrderedList } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import { get } from 'lodash'
import { computed } from 'vue'
import useLoginValid from './useLoginValid'
import useNewSong from './useNewSong'
import useQuality from './useQuality'
import useVip from './useVip'
import { useRouter } from 'vue-router'

export default function useSongItem() {
  const { showLoginQrcode, isLogin } = useLoginValid()
  const { showVipQrcode, isVipUser, showXifenQrcode } = useVip()
  const { show1080pModal } = useQuality()
  const { showNewSongModal } = useNewSong()
  
  const userType = computed(() => store.state.userInfo.userType)
  const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
  const router = useRouter()

  const actLogData = {
    30152: ['歌手详情页', '歌曲列表'],
    30153: ['搜索页', '歌曲列表'],
    30154: ['通用', '常唱列表'],
    30156: ['通用', '猜你会唱'],
    30157: ['首页', '歌单'],
    30159: ['通用', '已唱'],
    30200: ['首页', '新歌榜']
  }
 
  const validSong = (song, _option = {}) => {
    const { hls, is_vip, songid } = song;
  
    _option.fr = get(_option, 'from.fr1', '');
    _option.fr2 = get(_option, 'from.fr2', '');
    if (is_vip) {
      const isXifen = [1841, 1892].includes(_option.from.fr1) || _option.from === 'xifen';
      const isShowXifen = isXifen && store.state.isShowXifen;
  
      if (isLogin.value) {
        if (!isVipUser.value && !freeVipNumber.value) {
          if (isShowXifen) {
            showXifenQrcode({
              songInfo: song,
              isLogin: true,
              option: _option
            });
            return false;
          }
          showVipQrcode({
            fromType: 'vip-expired',
            isLogin: true,
            fr: _option.fr || 1873,
            onHide: () => {
              if (isLogin.value && isVipUser.value) {
                orderSong(song, _option);
              }
            }
          });
          return false;
        }
      } else {
        if (isXifen) {
          showXifenQrcode({
            songInfo: song,
            isLogin: false,
            option: _option
          });
          return false;
        }
        showVipQrcode({
          fromType: 'vip-expired',
          isLogin: false,
          fr: 1756,
          log: _option.fr2 || '',
          onHide: () => {
            if (isLogin.value && isVipUser.value) {
              orderSong(song, _option);
            }
          }
        });
        return false;
      }
    }
  
    if (!isVipUser.value && is_vip) {
      store.dispatch('vipAddSong/addSong', song);
    }
  
    const logData = {};
    const actLogName = get(_option, 'from.song_list_source', '') === 3 && get(_option, 'from.song_list_id', '') === '“特”别定制，会员专享歌单' ? '特别定制' : {
      2: '搜索-会员歌曲',
      10: '搜索-会员歌曲',
      3: '歌单-歌单名称',
      4: '已唱列表',
      5: '常唱列表',
      11: '常唱列表',
      6: '歌星-会员歌曲',
      12: '歌星-会员歌曲',
      9: '歌名-会员歌曲',
      13: '首页-画质歌单-会员歌曲弹窗',
      15: '首页-新歌歌单-vip权益弹窗',
      1000: '就唱这首',
      1001: '首页右上角小喇叭运营位'
    }[get(_option, 'from.song_list_source', '')] || '';
  
    if (actLogName) {
      logData.log = actLogName;
    }
  
    const is1080p = _option.isValid1080p;
    if (is1080p) {
      sendLog({
        event_type: '10000~50000',
        event_name: 30186,
        event_data: {
          str1: '首页',
          str2: '画质歌单',
          str3: '歌曲点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      });
      if (!isLogin.value || !isVipUser.value) {
        show1080pModal({
          songid,
          cancelEvent: () => {
            orderSong(song, {
              ..._option,
              isValid1080p: false,
              from: { song_list_source: 13 }
            });
            if (isLogin.value && !isVipUser.value && is_vip) {
              sendLog({
                event_type: '10000~50000',
                event_name: 30185,
                event_data: {
                  str1: '首页',
                  str2: '画质歌单',
                  str3: '会员歌曲弹窗展示',
                  str4: 'show',
                  str5: '已登录',
                  str6: userType.value,
                },
              });
            }
          }
        });
        return false;
      }
    }
  
    const isNewSong = _option.isValidNewSong;
    if (isNewSong) {
      sendLog({
        event_type: '10000~50000',
        event_name: 30202,
        event_data: {
          str1: '新歌榜',
          str2: '歌曲列表',
          str3: '歌曲点击',
          str4: 'show',
        },
      });
      if (!isLogin.value) {
        showNewSongModal({
          songid,
          cancelEvent: () => {
            if (isLogin.value && !isVipUser.value && is_vip) {
              sendLog({
                event_type: '10000~50000',
                event_name: 30201,
                event_data: {
                  str1: '首页',
                  str2: '新歌榜',
                  str3: '点歌弹窗扫码',
                },
              });
            }
          }
        });
        return false;
      }
    }
  
    if (is_vip) {
      if (isLogin.value && !isVipUser.value) {
        if (freeVipNumber.value) {
          return true;
        }

        showVipQrcode({
          songid,
          ...logData,
          isLogin: isLogin.value,
          fr: vipLogFrom.get(logData.log || '其他'),
        });
        return false;
      }
      if (!isLogin.value) {
        showLoginQrcode({ songid, ...logData });
        if (actLogName) {
          sendLog({
            event_type: '10000~50000',
            event_name: actLogName,
            event_data: {
              str1: actLogData[actLogName][0],
              str2: actLogData[actLogName][1],
              str3: '会员歌曲弹窗展示',
              str4: 'show',
              str5: '未登录',
              str6: 1,
            },
          });
        }
        return false;
      }
      if (!isVipUser.value) {
        showVipQrcode({
          songid,
          ...logData,
          isLogin: isLogin.value,
          fr: vipLogFrom.get(logData.log || '其他'),
        });
        if (actLogName) {
          sendLog({
            event_type: '10000~50000',
            event_name: actLogName,
            event_data: {
              str1: actLogData[actLogName][0],
              str2: actLogData[actLogName][1],
              str3: '会员歌曲弹窗展示',
              str4: 'show',
              str5: '已登录',
              str6: userType.value,
            },
          });
        }
        return false;
      }
    }
  
    return true;
  };

  const orderOption = {
    position: '',
    isPushOrdered: true,
    immediate: true,
    from: {}, // 点歌来源 - 打点使用
    ponitActionLog: {}, // 点歌的点位信息 - 10000 ~ 50000 打点使用
    enabledMvShow: false, // 启用MV页，默认关闭 - 当开启时自动打开mv页；immediate同为true时才生效
    useFirstSongAutoPlay: false, // 点播的第一首歌曲是否启用自动播放，默认关闭
    isValid1080p: false,
    isNotSingImmediately: true, // is sing immediately
    beforePushOrdered: () => {
      console.log('beforePushOrdered')
    },
    beforeImmediate: () => {
      console.log('beforeImmediate')
    },
    afterOrder: () => {
      console.log('afterOrder')
    },
    afterSelfOrder: (
      _song,
      _option,
    ) => {
      console.log('afterOrder')
      setOrderedList()
      setAlreadyList()

      const vipNoTimeShow = getFreeSongNoTimeShow()
      if (!vipNoTimeShow && _song.is_vip && !isVipUser.value) {
        // 不是立即演唱
        console.log('isNotSingImmediately:', _option.isNotSingImmediately)
        // console.log(_option)
        // 这块等调回之前的点歌流逻辑后释放
        // if (!_option.enabledMvShow && !_option.useFirstSongAutoPlay.value) {
        //   if (freeVipNumber.value === 3) {
        //     FNTInstance.value = showActivityFreeSongNoTime()
        //     setFreeSongNoTimeShow(true)
        //   }
        // }
        // 需求逻辑变更 - 不再这里处理了
        // if (_option.isNotSingImmediately) {
        //   if (freeVipNumber.value === 1) {
        //     FNTInstance.value = showActivityFreeSongNoTime()
        //     setFreeSongNoTimeShow(true)
        //   }
        // }
      }
    }
  }

  const orderSong = async (song, option) => {
    try {
      const _song = {...song}
      const _option = {...orderOption, ...option}
      console.log('1105', _option)
      // _song.hls = getMediaSoruce(_song) // 不在这里获取了
      console.log('media source: ', _song.hls)
      // if (store.state.freeVipNumber) {
      //   await store.dispatch('fetchFreeVip')
      // }
      if (!validSong(_song, _option)) {
        // 此功能暂不上线
        // delayAddSong(song, option)
        return
      }
      console.log('app: order: song', _song, _option)
      if (_option.isPushOrdered) {
        _option.beforePushOrdered.call()
        // VEC-873
        // orderedListNumber.value !== 0
        Toast({
          message: '歌曲已加入已点列表',
          position: 'center',
          className: 'toast-zoom-max'
        })
        store.commit('PUSH_SONG_TO_ORDERED_LIST', { song: _song, from: _option.from })
        // 10000~50000 的数据上报
        if (get(_option, 'ponitActionLog.event_type') === '10000~50000') {
          sendLog(_option.ponitActionLog)
        }
      }
      if (_option.immediate) {
        _option.beforeImmediate.call()
        store.commit('SAVE_VIDEO_PLAYER_HISTORY_SONG_ITEM', _song)
        if (_option.position !== 'recovery') {
          setTimeout(() => {
            store.commit('UPDATE_VIDEO_PLAYER_RECOVERY', true)
          }, 200)
        }
        // 需求策略调整 - 点播第一首时不进入mv页，且不立即播放；进入mv后才开始播放
        if(store.state.mvIsHide && _option.enabledMvShow) store.commit('UPDATE_MV_ISHIDE', false)
      }
      _option.afterOrder.call(this)
      _option.afterSelfOrder.call(this, _song, _option)

      // 特殊的业务逻辑 - 点播的第一首歌不直接播放
      if (_option.useFirstSongAutoPlay) {
        eventBus.emit('handle-video-play')
      }
      // else {
      //   if (orderedListNumber.value === 1) {
      //     setTimeout(() => {
      //       eventBus.emit('handle-video-pause')
      //     }, 500)
      //   }
      // }
    } catch (error) {
      console.error('orderSong error:', error)
    }
  }

  return {
    validSong,
    orderSong,
  }
}
