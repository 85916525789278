<template>
  <div class="video-player" @click="$emit('on-video-click')">
    <video
      id="video-player"
      autoplay
      :poster="poster"
      :class="{
        'full-video': useFullScreen == 0,
        'mini-video-left': useFullScreen == 1,
        'mini-video-right': useFullScreen == 2,
      }"
      @play="$emit('play')"
      @pause="$emit('pause')"
      @canplay="handleCanPlay"
      @ended="handleVideoEnded"
      @timeupdate="handleTimeupdate"
    ></video>
    <!-- <div class="video-player-bottom">
      <img src="https://qncweb.ktvsky.com/20220119/vadd/aea975fdf8f7718ca240a8b39260e0af.png" alt="">
    </div> -->
  </div>
</template>
<script>
import eventBus from '@/utils/event-bus'
import Hls from 'hls.js'
import { onMounted, onUnmounted, toRefs, watch } from 'vue'
// import { differenceInMilliseconds } from 'date-fns'
import { get } from 'lodash'

export default {
  props: {
    // 播放源地址
    src: String,
    // 播放鉴权token
    token: String,
    // 播放鉴权tokenExp
    // tokenExp: {
    //   type: Number,
    //   default: 300,
    // },
    // 是否开启自动播放
    autoplay: {
      type: Boolean,
      default: false,
    },
    // 延迟播放时间
    delay: {
      type: Number,
      default: 0,
    },
    // 视频默认封面
    poster: String,
    from: String,
    // 起始播放位置 如从第0毫秒播放
    startPosition: {
      type: Number,
      default: 0,
    },
    // 右侧小窗口播放
    useFullScreen: {
      type: Number,
      default: 0, // 0: 全屏 1：left 2：right
    },
    // 是否静音
    muted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, expose }) {
    const { src, token, autoplay, startPosition, from, delay, muted } =
      toRefs(props)
    // hls.js实例
    let hls = null
    // video dom实例
    let video = null

    // 无需做播放恢复的http code 清单 如遇清单code 直接抛错
    const notCanRecoverHttpCodeList = [404, 500, 502]

    // 需要补发mv鉴权token的http code
    const retryHttpCode = 403

    // 鉴权正常播放的时间戳 - start
    // let tokenStartTime = null

    let currentTimeTag = 0

    // m3u8文件加载事件
    const onHlsEventsManifestLoaded = (event, { audioTracks }) => {
      console.log('audioTracks', audioTracks)
      emit('onManifestLoaded', {
        audioTracks,
      })
    }

    // m3u8文件解析完毕事件
    const onHlsEventsManifestParsed = () => {
      console.log('manifest parsed', startPosition.value)
      emit('onManifestParsed')
      if (hls.media) {
        hls.media.currentTime = startPosition.value
          ? startPosition.value
          : currentTimeTag
        if (currentTimeTag) {
          setTimeout(() => {
            currentTimeTag = 0
          }, 600)
        }
      }
    }

    // m3u8文件初始播放位置事件
    const onHlsEventsInitPtsFound = () => {
      console.log('INIT_PTS_FOUND')
      emit('onInitPtsFound')
      if (hls.media) {
        hls.media.currentTime = startPosition.value
      }
    }

    // 视频音轨切换事件
    const onHlsEventsAudioTrackSwitched = (event, { id }) => {
      console.log('AUDIO_TRACK_SWITCHED', id)
      emit('onAudioTracksSwitched', id)
    }

    // hls挂载video dom对象完毕
    const onHlsEventsMediaAttached = () => {
      console.log('MEDIA_ATTACHED')
      hls.loadSource(src.value)
      emit('init', hls)
    }

    // hls卸载
    const onHlsEventsMediaDetaching = () => {
      console.log('MEDIA_DETACHING')
      emit('onMediaDetaching', hls)
    }

    // hls 切片开始下载
    // { frag : fragment object, targetBufferTime: number | null [The unbuffered time that we expect to buffer with this fragment] }
    const onHlsEventsFragLoading = (name, data) => {
      emit('onFragLoading', data)
    }

    const onHlsEventsFragLoaded = (name, data) => {
      emit('onFragLoaded', data)
    }

    const onHlsEventsFragLoadEmergencyAborted = (name, data) => {
      emit('onFragLoadEmergencyAborted', data)
    }

    // 监控播放源地址 如改变重新loadSource
    watch(src, (val) => {
      if (val) {
        hls.loadSource(val)
      }
    })

    // watch(token, (val) => {
    //   if (val) {
    //     // 监控token 如改变尝试重新修复load资源
    //     // 响应提前后暂不需要监测token重新load
    //     // hls.startLoad()

    //     // 监测token 设置时间戳 - 上报异常token鉴权失败
    //     // tokenStartTime = new Date()
    //   }
    // })

    // 异常token鉴权失败 - 数据上报
    // const sendEvent = (error) => {
    //   if (!tokenStartTime) return
    //   const currDate = new Date()
    //   // 这块token鉴权的过期时间不太稳定 需沟通是否需容错10s
    //   const ts = differenceInMilliseconds(currDate, tokenStartTime) < 1000 * tokenExp.value
    //   // console.log(
    //   //   differenceInMilliseconds(currDate, tokenStartTime),
    //   //   1000 * tokenExp.value
    //   // )
    //   if (ts) {
    //     emit('error', error, '403')
    //   }
    // }

    // 错误处理
    const onHlsEventsError = (event, data) => {
      const resCode = get(data, 'response.code', 0)
      // 监测到code为403时，直接重试，加速响应
      if (resCode === retryHttpCode) {
        // token失效重新请求
        emit('retryToken', data)
        // hls.startLoad()
        // sendEvent(data) // 改为使用服务端时间进行过期时间判断
        return
      }
      emit('error', data)
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            if (notCanRecoverHttpCodeList.includes(resCode)) {
              // 恢复不了通知上游
              emit('unrecover', data)
            } else {
              // 尝试恢复
              console.log('fatal network error encountered, try to recover')
              hls.startLoad()
            }
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            // 尝试恢复
            console.log('fatal media error encountered, try to recover')
            hls.recoverMediaError()
            break
          default:
            // 可能有一些报错内部hls会自动恢复，所以此处不做默认处理，避免处理方法被重复调用
            break
        }
      } else {
        switch (data.details) {
          case Hls.ErrorDetails.INTERNAL_EXCEPTION:
            // 这种情况下token可能已经失效
            // emit('retryToken', data)
            hls.startLoad()
            break
          case Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL:
          case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            // 这块hls这个组件貌似有些bug - 解决播放BUFFER_STALLED_ERROR卡住的问题
            hls.startLoad()
            break
          default:
            break
        }
      }
    }

    // 将hls挂载到video dom
    const attachMedia = () => {
      console.log('hls挂载')
      video = document.getElementById('video-player')
      // 参见文档 https://github.com/video-dev/hls.js/blob/master/docs/API.md
      hls = new Hls({
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 0,
        maxBufferSize: 20,
        xhrSetup: (xhr, url) => {
          // TODO 添加鉴权参数
          // console.log('xhrSetup', url)
          // xhr.withCredentials = true
          xhr.setRequestHeader('Content-Type', 'text/html')
          xhr.setRequestHeader('Authorization', `Bearer ${token.value}`)
        },
      })
      hls.on(Hls.Events.MEDIA_ATTACHED, onHlsEventsMediaAttached)
      hls.on(Hls.Events.MANIFEST_LOADED, onHlsEventsManifestLoaded)
      hls.on(Hls.Events.MANIFEST_PARSED, onHlsEventsManifestParsed)
      hls.on(Hls.Events.INIT_PTS_FOUND, onHlsEventsInitPtsFound)
      hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, onHlsEventsAudioTrackSwitched)
      hls.on(Hls.Events.MEDIA_DETACHING, onHlsEventsMediaDetaching)
      hls.on(Hls.Events.ERROR, onHlsEventsError)
      hls.on(Hls.Events.FRAG_LOADING, onHlsEventsFragLoading)
      hls.on(Hls.Events.FRAG_LOADED, onHlsEventsFragLoaded)
      hls.on(
        Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED,
        onHlsEventsFragLoadEmergencyAborted
      )
      hls.attachMedia(video)
    }

    // 音轨切换
    const handleSwitchAudioTrack = (audioTrack) => {
      console.log('handleSwitchAudioTrack', audioTrack)
      if (hls) {
        hls.audioTrack = audioTrack.id
      }
    }

    // 播放
    const handleControlVideoPlay = () => {
      hls.media.play()
      if (video) {
        video.play()
      }
    }

    // 暂停
    const handleControlVideoPause = () => {
      if (video) {
        video.pause()
      }
    }

    // 静音
    const setMuted = (value) => {
      if (video) {
        video.muted = value
      }
    }

    // 重播
    const handleControlVideoReplay = () => {
      if (hls) {
        hls.media.currentTime = 0
        hls.media.paused && hls.media.play()
      }
    }

    // video canplay事件
    const handleCanPlay = () => {
      console.log('muted', muted.value)
      if (hls && hls.media) {
        //此设置需单独提出 放入下面的条件里会影响默认暂停播放的情况
        hls.media.muted = muted.value
      }
      if (autoplay.value) {
        // 快唱3s后播放视频
        setTimeout(() => {
          hls.media.play()
        }, delay.value * 1000)
        hls.media.volume = 0.4
      }
      if (from.value !== 'climax') {
        handleSwitchAudioTrack({ id: 0 })
      }
      console.log('canplay')
      emit('canplay')
    }

    // video ended事件
    const handleVideoEnded = () => {
      if (hls && hls.media) {
        emit('ended', hls.media)
      }
    }

    // video timeupdate事件
    const handleTimeupdate = () => {
      let t = 0
      let duration = 0 // 新增变量用于存储总时长
      if (hls && hls.media) {
        t = hls.media.currentTime
        duration = hls.media.duration // 获取总时长
      }
      emit('timeupdate', { currentTime: t, duration }) // 发送当前时间和总时长
    }

    const handleChangeQualityTag = () => {
      currentTimeTag = hls.media.currentTime
      console.log('currentTimeTag', currentTimeTag)
    }

    // 释放媒体资源
    const handleDetachMedia = () => {
      if (hls) {
        hls.detachMedia()
        hls.off(Hls.Events.MEDIA_ATTACHED, onHlsEventsMediaAttached)
        hls.off(Hls.Events.MANIFEST_LOADED, onHlsEventsManifestLoaded)
        hls.off(Hls.Events.MANIFEST_PARSED, onHlsEventsManifestParsed)
        hls.off(Hls.Events.INIT_PTS_FOUND, onHlsEventsInitPtsFound)
        hls.off(Hls.Events.AUDIO_TRACK_SWITCHED, onHlsEventsAudioTrackSwitched)
        hls.off(Hls.Events.MEDIA_DETACHING, onHlsEventsMediaDetaching)
        hls.off(Hls.Events.ERROR, onHlsEventsError)
        hls.off(Hls.Events.FRAG_LOADING, onHlsEventsFragLoading)
        hls.off(Hls.Events.FRAG_LOADED, onHlsEventsFragLoaded)
        hls.off(
          Hls.Events.FRAG_LOAD_EMERGENCY_ABORTED,
          onHlsEventsFragLoadEmergencyAborted
        )
      }
    }

    const attachVideoPlayerEvents = () => {
      eventBus.on('switch-audio-track', handleSwitchAudioTrack)
      eventBus.on('video-control-play', handleControlVideoPlay)
      eventBus.on('video-control-pause', handleControlVideoPause)
      eventBus.on('video-control-replay', handleControlVideoReplay)
      eventBus.on('video-quality-change', handleChangeQualityTag)
    }

    const detachVideoPlayerEvents = () => {
      console.log('detach-video-player-events')
      eventBus.off('switch-audio-track', handleSwitchAudioTrack)
      eventBus.off('video-control-play', handleControlVideoPlay)
      eventBus.off('video-control-pause', handleControlVideoPause)
      eventBus.off('video-control-replay', handleControlVideoReplay)
      eventBus.off('video-quality-change', handleChangeQualityTag)
    }

    onMounted(() => {
      attachMedia()
      attachVideoPlayerEvents()
    })
    onUnmounted(() => {
      console.log('onUnmounted: VideoPlayer')
      handleDetachMedia()
      detachVideoPlayerEvents()
      hls.destroy()
      console.log('videoplayer: hls: destory')
    })

    // 暴露方法给父组件
    expose({
      setMuted,
      handleControlVideoPlay,
      handleControlVideoPause,
    })

    return {
      handleCanPlay,
      handleVideoEnded,
      handleTimeupdate,
      hls,
      handleControlVideoPlay,
      handleSwitchAudioTrack,
    }
  },
}
</script>
<style lang="stylus" scoped>
.video-player
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  width 100%
  height 100%
  background #000
  @media screen and (max-width 1200px) and (min-height 1200px)
    background #000
    &-bottom
      position absolute
      bottom 120px
      display flex !important
      flex-direction column
      justify-content center
      align-items center
      img
        // width 100px
        width 300px
      p
        color #999999
        font-size 30px
        margin-top 40px
  @media screen and (max-width 1180px) and (min-height 1200px)
    &-bottom
      bottom 50px !important
  &-bottom
    display none
  video
    width 100%
    height 100%
    z-index 5
    background black
    position absolute
    transition all 0.3s cubic-bezier(0.4, 0, 0.2, 1)
    @media screen and (max-width 1200px) and (min-height 1421px)
      width 100vw !important
      height 675px !important
      top 172px !important
    @media screen and (max-width 1180px) and (min-height 1200px)
      width 100vw !important
      height 675px !important
      top 378px !important
  &-plugins
    position absolute
    top 0
    bottom 0
    right 0
    left 0
    width 100vw
    height 100vh
    z-index 10
  .full-video
    top 0
    // left 0
  .mini-video-left
    top 225px
    left 0
    width 1120px
    height 630px
  .mini-video-right
    top 225px
    right 0
    width 1120px
    height 630px
</style>
