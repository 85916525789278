<template>
  <div
    :style="{ top: layerTop + 'px', left: layerLeft + 'px' }"
    @touchstart="startDrag"
    @touchmove="doDrag"
    @touchend="endDrag"
    @click.stop="phoneSongShow"
    class="phone-song"
  >
    <img
      src="https://qncweb.ktvsky.com/20241128/vadd/0709646c792e75005b55f6d966cc83f9.png"
      class="icon"
    />
    <div class="label">手机点歌</div>
  </div>
</template>

<script>
import { useShareBrowserSize } from '@/composables/sharedComposable'
import { sendLog } from '@/directives/v-log/log'
import { computed, inject, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Index',
  props: {},
  setup() {
    const store = useStore()
    const { browserType } = useShareBrowserSize()

    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const $mobileOrderQrcodeModal = inject('$mobileOrderQrcodeModal')

    // 悬浮框尺寸
    const boxWidth = 120
    const boxHeight = 120

    // 悬浮框位置
    const layerTop = ref(0)
    const layerLeft = ref(0)

    // 拖动状态
    let dragging = false
    let mouseX
    let mouseY

    // 窗口尺寸和缩放比例
    let windowWidth = window.innerWidth
    let windowHeight = window.innerHeight
    let rate = windowWidth / 1920

    // 初始化悬浮框位置
    const initializePosition = () => {
      rate = windowWidth / 1920
      layerTop.value = windowWidth > 1200 ? 720 * rate : 1750 * rate
      layerLeft.value = 0 * rate
      ensurePositionIsValid(0)
    }

    // 确保悬浮框位置有效
    const ensurePositionIsValid = (diffX) => {
      if (layerTop.value < 0) {
        layerTop.value = 0
      } else if (layerTop.value > windowHeight - boxHeight) {
        layerTop.value = windowHeight - boxHeight
      }

      layerLeft.value += diffX
      if (layerLeft.value < 0) {
        layerLeft.value = 0
      } else if (layerLeft.value > windowWidth - boxWidth) {
        layerLeft.value = windowWidth - boxWidth
      }
    }

    // 触摸开始
    const startDrag = (e) => {
      dragging = true
      mouseX = e.touches[0].clientX
      mouseY = e.touches[0].clientY
    }

    // 拖动中
    const doDrag = (e) => {
      if (dragging) {
        const diffX = e.touches[0].clientX - mouseX
        const diffY = e.touches[0].clientY - mouseY

        layerTop.value += diffY
        ensurePositionIsValid(diffX)

        mouseX = e.touches[0].clientX
        mouseY = e.touches[0].clientY
      }
    }

    // 拖动结束
    const endDrag = () => {
      dragging = false

      // 磁吸效果
      const threshold = windowWidth / 2
      if (layerLeft.value < threshold) {
        layerLeft.value = 0
      } else {
        layerLeft.value = windowWidth - boxWidth
      }
    }

    // 监听窗口大小变化
    window.addEventListener('resize', () => {
      windowWidth = window.innerWidth
      windowHeight = window.innerHeight
      initializePosition()
    })

    // 组件卸载时移除事件监听
    onUnmounted(() => {
      window.removeEventListener('resize', initializePosition)
    })

    // 手机点歌显示方法
    const phoneSongShow = () => {
      $mobileOrderQrcodeModal.show()
      sendLog({
        event_type: '10000~50000',
        event_name: 30241,
        event_data: {
          str1: '通用',
          str2: '手机点歌悬浮',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
        },
      })
    }

    // 初始化位置
    initializePosition()

    return {
      phoneSongShow,
      layerTop,
      layerLeft,
      startDrag,
      doDrag,
      endDrag,
    }
  },
}
</script>

<style lang="stylus" scoped>
.phone-song
  width 160px
  height 160px
  border 2px solid rgba(0, 0, 0, 0.1)
  background rgba(255, 255, 255, 0.2)
  backdrop-filter blur(150px)
  position fixed
  z-index 102
  display flex
  flex-direction column
  align-items center
  justify-content center
  border-radius 30px
  left 28px !important
  .icon
    width 56px
    height 56px
  .label
    font-size var(--font-size-medium)
    margin-top 12px
    color rgba(29, 29, 31, 1)
</style>
