import useLowerVipQrcode from './create.js';
import LowerVipQrcodeComponent from './m.vue';

const Plugin = (app, props = {}, slots = {}) => {
  const lowerVipQrcode = useLowerVipQrcode(props, slots);
  app.config.globalProperties.$lowerVipQrcode = lowerVipQrcode;
  app.provide('$lowerVipQrcode', lowerVipQrcode)
};

LowerVipQrcodeComponent.install = Plugin;

export default LowerVipQrcodeComponent;
