<template>
  <div
    v-if="showQR && isShow"
    class="operation-bottom-qr-container"
    :style="{ backgroundImage: `url(${currBg})` }"
  >
    <div @click="handleCloseModal" class="close"></div>

    <p class="title">{{ msg }}</p>

    <p class="qr-text" :class="[isLogin && 'short']">{{ qrText }}</p>

    <div class="qrcode">
      <img :src="qrCodeURL" />
    </div>
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { getRetainModalTag, getUserRoleTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onActivated, onMounted, ref, toRefs, watch } from 'vue'

export default {
  name: 'OperationBottomQr',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    msg: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { from, isShow, closeCallback } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const isLogin = computed(() => !!unionid.value)
    const userType = computed(() => store.state.userInfo.userType)
    const isVip = computed(() => !!store.state.vipInfo.end_time)

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(false)
    const showQR = ref(false)
    const currSelected = ref(0)
    const pkg = [
      {
        id: 22,
        value: '1年',
        img: 'https://qncweb.ktvsky.com/20241231/vadd/edd7ae5cb3fbe70d99ff6d316f227679.png',
        // img: 'https://qncweb.ktvsky.com/20241209/vadd/7498211ad77acc41f885f1535c3a8177.png',
      },
    ]

    const currBg = computed(() => pkg[currSelected.value].img)

    const qrText = computed(() => {
      return isLogin.value ? '微信扫码支付' : '微信扫码登录支付'
    })

    const getZdVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: pkg[currSelected.value].id,
          fr: from.value === 'search' ? 1903 : 1905,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getMobileVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&log=${from.value === 'search' ? 1902 : 1904}&ut=${
            userType.value
          }`
        )
        console.log('getVipQrcode qrCodeData:', qrCodeData.value)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const getVipQrcode = async () => {
      console.log('operation qr mounted')
      if (!getRetainModalTag()) {
        showQR.value = false
        return
      }

      if (isLogin.value) {
        getZdVipQrcode()
        return
      }
      getMobileVipQrcode()
    }

    const handleCloseModal = () => {
      showQR.value = false
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }

    watch(isVip, (val) => {
      if (val) {
        showQR.value = false
      }
    })

    watch([showQR, isShow], ([showQR, isShow]) => {
      if (showQR && isShow) {
        sendLog({
          event_type: '30000～35000',
          event_name: from.value === 'search' ? 30327 : 30328,
          event_data: {
            str1:
              from.value === 'search' ? '搜索结果页运营位' : '歌手详情页运营位',
            str2: from.value === 'search' ? '搜索结果页' : '歌手详情页',
            str3: '展示',
            str4: 'show',
            str5: isLogin.value ? '已登录' : '未登录',
            str7: isNewUser.value === 2 ? '2' : '1',
          },
        })
      }
    })

    onActivated(() => {
      console.log('operation qr onActivated', isVip.value)
      isNewUser.value = getUserRoleTag()
      if (!isVip.value) {
        showQR.value = true
        getVipQrcode()
        return
      }
      showQR.value = false
    })

    onMounted(() => {
      console.log('operation qr mounted')
      isNewUser.value = getUserRoleTag()
      if (!isVip.value) {
        showQR.value = true
        getVipQrcode()
        return
      }
      showQR.value = false
    })

    return {
      showQR,
      qrText,
      qrCodeURL,
      currBg,
      isLogin,
      handleCloseModal,
    }
  },
}
</script>

<style lang="stylus" scoped>
.operation-bottom-qr
  &-container
    position fixed
    width 1230px
    height 271px
    bottom 114px
    z-index 5
    left 50%
    transform translateX(-50%)
    background url('') no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.9
      bottom 125px
    .close
      position absolute
      top 8px
      right 9px
      width 32px
      height 32px
    .title
      position absolute
      top 135px
      left 130px
      font-size 36px
      font-weight 700
      line-height 1
      background linear-gradient(180deg, #931F0E 0%, #D74A37 100%)
      -webkit-background-clip text
      -webkit-text-fill-color transparent
    .qr-text
      position absolute
      top 208px
      right 58px
      font-size 20px
      font-weight 500
      color rgba(0, 0, 0, 0.6)
    .short
      right 80px

    .qrcode
      position absolute
      top 60px
      right 70px
      width 140px
      height 140px
      display flex
      justify-content center
      align-items center
      background #FFFFFF
      border-radius 10px
      img
        width 132px
        height 132px
        border-radius 5px
</style>
