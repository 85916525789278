import { getMiniDefaultList } from "@/service/playlist";

const state = {
  defaultList: [],
  defaultSong: {},
  muted: true,
  isShowMini: true,
};

const getters = {};

const actions = {
  async fetchDefaultList({ commit }) {
    const { data_list = [] } = await getMiniDefaultList();
    await commit("SET_DEFAULT_LIST", data_list);
    commit("SET_DEFAULT_SONG", data_list[0]);
  },
  setCurrentDefaultSong({ commit }, songData) {
    commit("SET_DEFAULT_SONG", songData);
  },
  setMuted({ commit }, muted) {
    commit("SET_MUTED", muted);
  },
  setIsShowMini({ commit }, isShowMini) {
    commit("SET_IS_SHOW_MINI", isShowMini);
  }
};

const mutations = {
  SET_DEFAULT_LIST(state, defaultList) {
    state.defaultList = defaultList;
  },
  SET_DEFAULT_SONG(state, defaultSong) {
    state.defaultSong = defaultSong;
    console.log("SET_DEFAULT_SONG", defaultSong);
  },
  SET_MUTED(state, muted) {
    state.muted = muted;
  },
  SET_IS_SHOW_MINI(state, isShowMini) {
    state.isShowMini = isShowMini;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
