<template>
  <div class="page">
    <HeadBar
      class="mine-headbar"
      title="我的"
      :isShowRight="true"
      :isShowSinging="false"
      :isShowSetting="false"
    />

    <div class="mine">
      <!-- <MineVip v-if="browserType !== 'landscape'" class="mine-vip-v" /> -->
      <MineVipc />
      <div class="mine-singed">
        <div class="mine-singed-title"><p>唱过的歌</p></div>
        <div class="mine-singed-list" @click.stop>
          <LoadMore
            class="song-list"
            v-if="dataList.length"
            @load-more="fetchData"
            safeAreaHeight="0"
          >
            <SongItem
              className="sheet-list-song-item"
              v-for="(songItem, index) in dataList"
              :key="index"
              :songItem="songItem"
              :log-from="{
                song_list_source: 3,
              }"
            />
          </LoadMore>
          <div v-else class="mine-singed-empty">
            <h3>近一年没有点歌记录，快去点歌吧～</h3>
            <GuessSonglist
              class="mine-guess-songlist"
              :pageSize="12"
              renderType="block"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MineVip from '@/components/vip/index.vue'
import { sendLog } from '@/directives/v-log/log'
import MineVipc from '@/pages/mine/components/vipc.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useShareBrowserSize } from '@/composables/sharedComposable'
import { getSingsingList } from '@/service/singing'

import GuessSonglist from '@/components/guess-song/songlist.vue'
import SongItem from '@/components/song-item/index.vue'

export default {
  name: 'Mine',
  components: {
    // MineVip,
    MineVipc,
    SongItem,
    GuessSonglist,
  },
  setup() {
    const store = useStore()
    const { browserType } = useShareBrowserSize()

    const router = useRouter()
    const unionid = computed(() => store.state.userInfo.unionid)

    const dataList = ref([])
    let isRequest = false

    const requestSingingData = async () => {
      let responseData = []
      responseData = await getSingsingList({
        unionid: unionid.value,
      })
      return responseData
    }

    const fetchData = async () => {
      if (!unionid.value) return
      if (isRequest) {
        return
      }
      isRequest = true
      const singingResponseData = await requestSingingData()
      dataList.value = singingResponseData
      isRequest = false
    }

    watch(unionid, (val) => {
      if (val) {
        fetchData()
      }
    })

    onMounted(() => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10026,
        event_data: {
          str1: '我的',
          str2: '我的页',
          str3: '进入我的页',
          str4: 'show',
        },
      })
      fetchData()
    })

    return {
      dataList,
      browserType,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding-top calc(150px + 32px) !important
.mine
  width 100%
  padding 0 100px
  height calc(100vh - 164px)
  overflow-y scroll
  ::-webkit-scrollbar
    display none
  &-singed
    margin-top 0
    padding-bottom 250px
    &-title
      color rgba(29, 29, 31, 0.5)
      padding 64px 0 20px
      border-bottom 2px solid rgba(255, 255, 255, 0.1)
      span
        font-size 32px
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 64px 20px 0
    .song-list
      padding 0
      height auto!important
      @media screen and (max-width 1200px) and (min-height 1200px)
        min-height 304px
        overflow hidden
        padding-top 0px
        ::v-deep .song-block, ::v-deep .song-item
          .name
            font-size 26px
          .desc
            font-size 20px
          .song-block-vip
            width 42px
            height 22px
    &-empty
      padding-bottom 50px
      h3
        width 480px
        text-align center
        color rgba(29, 29, 31, 0.5)
        font-size: 28px;
        padding-top 130px
        background url(https://qncweb.ktvsky.com/20241220/vadd/7da55e72e3205d06488247e61f845513.png) no-repeat top center
        background-size 90px auto
        margin 96px auto 80px
      ::v-deep .sec-gusse-sing
        padding 0!important
        .section-container-header-title
          color rgba(29,29,31,0.5)
          font-size 28px
          font-weight 400
        &-list
          display grid
          grid-template-columns repeat(3, 493px)
          justify-content space-between
          @media screen and (max-width 1200px) and (min-height 1200px)
            grid-template-columns repeat(3, 344px)
            min-height 304px
            overflow hidden
            ::v-deep .song-item
              width 344px
              height 140px
              margin-bottom 24px
              .name
                font-size 26px
              .desc
                font-size 20px
              .song-block-vip
                width 42px
                height 22px
          .song-block
            width 100%
  .mine-guess-songlist
    ::v-deep .song-item-block
      margin-bottom 40px
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 0
    .mine-guess-songlist
    ::v-deep .sec-gusse-sing-list
      grid-template-columns repeat(2, 520px)
      .song-item-block
        margin-bottom 32px !important
      .song-block
        width 520px !important
        height 160px
        margin-bottom 0 !important

    ::v-deep .song-item-block
      height 160px
      margin-bottom 40px
.page
  height 100vh
  overflow hidden
  .search-bar
    background: none
  .mine-headbar
    background: none
</style>
