<template>
  <CommonModal ref="root" :onCancel="handleCancel">
    <div
      class="lower-vip-modal-content"
      :style="{ backgroundImage: `url(${currBg})` }"
    >
      <div @click="handleCloseModal" class="close"></div>
      <div class="qrcode">
        <img :src="qrCodeURL" />
        <p>{{ isLogin ? '微信扫码支付' : '微信扫码登录支付' }}</p>
      </div>
    </div>
  </CommonModal>
</template>

<script>
import CommonModal from '@/components/modal/common/component.vue'
import useQRCode from '@/composables/useQRCode'
import { sendLog } from '@/directives/v-log/log'
import { getCarplayInfo } from '@/service/carplay-info'
import { clearVipQueryPolling, getVipPkgQr, getVipQuery } from '@/service/vip'
import store from '@/store'
import { getUserRoleTag } from '@/utils/historyCache'
import Toast from '@/utils/toast'
import get from 'lodash/get'
import { computed, onBeforeMount, onMounted, ref, toRefs } from 'vue'

export default {
  name: 'lowerVipModal',
  components: {
    CommonModal,
  },
  props: {
    closeCallback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { closeCallback } = toRefs(props)
    const unionid = computed(() => store.state.userInfo.unionid)
    const { getQRCodeURL } = useQRCode()
    const root = ref(null)
    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const isNewUser = ref(false)
    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const userType = computed(() => store.state.userInfo.userType)

    const imgs = {
      old: 'https://qncweb.ktvsky.com/20241206/vadd/bec13aa129bb3e5c167814c9d0392aef.png',
      new: 'https://qncweb.ktvsky.com/20241206/vadd/aca7d912b98c738e339b2123437221bf.png',
    }

    const currBg = computed(() => imgs[isNewUser.value !== 2 ? 'new' : 'old'])

    const getZdVipQrcode = async () => {
      try {
        const data = await getVipPkgQr({
          unionid: unionid.value,
          pkg_id: 22,
          fr: 1899,
        })
        const qr = get(data, 'qr', '')
        if (qr) {
          qrCodeURL.value = await getQRCodeURL(qr)
          await getVipQuery(data.order_id)
          store.dispatch('getCarplayInfo')
          root.value.hide()
          clearVipQueryPolling()
        }
      } catch (error) {
        clearVipQueryPolling()
      }
    }

    const getMobileVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(
          `${data.pay_qr}&log=${1898}&ut=${userType.value}`
        )
        console.log('getVipQrcode qrCodeData:', qrCodeData.value)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const getVipQrcode = async () => {
      sendLog({
        event_type: '30000～35000',
        event_name: isLogin.value ? 30324 : 30323,
        event_data: {
          str1: '通用',
          str2: `新挽留弹窗${isLogin.value ? '2' : '1'}`,
          str3: '展示',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: isNewUser.value === 2 ? '2' : '1',
        },
      })

      if (isLogin.value) {
        getZdVipQrcode()
        return
      }
      getMobileVipQrcode()
    }

    const handleCloseModal = () => {
      clearVipQueryPolling()
      store.dispatch('getCarplayInfo')
      root.value.hide()

      closeCallback.value && closeCallback.value()
    }

    const handleCancel = () => {
      // 点击其他区域关闭弹窗时，也去刷一次
      store.dispatch('getCarplayInfo')

      closeCallback.value && closeCallback.value()
    }

    onBeforeMount(getVipQrcode)

    onMounted(() => {
      isNewUser.value = getUserRoleTag()
      // console.log(tag)
    })

    return {
      qrCodeURL,
      root,
      currBg,
      isLogin,
      handleCancel,
      handleCloseModal,
    }
  },
}
</script>

<style lang="stylus" scoped>
.lower-vip-modal
  &-content
    position relative
    width 800px
    height 636px
    padding-top 0 !important
    background url() no-repeat
    background-size 100% 100%
    background-position center
    @media screen and (max-width 1200px)
      zoom 0.8
    .pkg
      position absolute
      bottom 65px
      left 63px
      width 420px
      height 234px
      display flex
      .pkg-item
        width 210px
        height 234px
    .close
      position absolute
      top 136px
      right 30px
      width 32px
      height 32px
    .qrcode
      position absolute
      bottom 78px
      right 113px
      width 180px
      height 217px
      display flex
      flex-direction column
      justify-content center
      align-items center
      border-radius 10px
      img
        width 160px
        height 160px
        border-radius 5px
        margin-bottom 8px
        margin-top 0px
      p
        font-size 22px
        color rgba(0, 0, 0, 0.6)
        font-weight 500
        line-height 1
</style>
