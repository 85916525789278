// images resources
const IMAGE_URLS = {
  VIP_MODAL: {
    CLOSE_ICON:
      "https://qncweb.ktvsky.com/20240910/vadd/d4b941b2305173339042fe90d78e62bd.png",
    BACKGROUND:
      "https://qncweb.ktvsky.com/20241205/vadd/c56401b3e4dea207ca8986a56f2028bb.png",
      // "https://qncweb.ktvsky.com/20241204/vadd/c1fa0a36712e21797ac17a3d6cda03dd.png",
      // "https://qncweb.ktvsky.com/20241029/vadd/a75bf2298b15469fdf4528271d284c4d.png",
      // "https://qncweb.ktvsky.com/20241024/vadd/4161e810c82320217613d8856e91e20b.jpeg",
      // "https://qncweb.ktvsky.com/20241024/vadd/031c7d8d4068a2e2043008e0c5acfb1b.png",
      // "https://qncweb.ktvsky.com/20241018/vadd/489af50f378f560147f3d9d3cf22b1b1.png",
    // "https://qncweb.ktvsky.com/20240920/vadd/c2ec09558fbd83cd43585d43b2e724d7.png",
    // "https://qncweb.ktvsky.com/20240926/vadd/8c6666de7cf1b2c7c7f70971a8e72c6a.png",
    DEFAULT_QR_CODE:
      "https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png",
  },
  // 可以继续添加其他模块的图片URL
};

export default IMAGE_URLS;
