<template>
  <div
    class="album-mv"
    :class="{ fullscreen: isFullScreen }"
    @click="handleMvClick"
  >
    <div class="album-mv-video" @click="handleControlFullScreen">
      <!-- :class="isScale && 'scale'" -->
      <video-player
        v-if="song?.video_url"
        from="album"
        ref="videoPlayerRef"
        :autoplay="true"
        :startPosition="0"
        :src="song.video_url"
        :poster="''"
        :token="token"
        :muted="muted"
        @ended="handleVideoEnded"
        @canplay="handleCanPlay"
        @timeupdate="handleTimeupdate"
        @retryToken="handleRetryToken"
      />
    </div>
    <VideoProgress :currentTime="currentTime" :duration="duration" />
    <div
      v-show="isShowCenterControl"
      class="album-mv-center-control"
      @click="handleControlClick"
    >
      <div class="album-mv-center-control-back" @click.stop="handleClickBack">
        <img
          src="https://qncweb.ktvsky.com/20250117/vadd/5d8e7a5d775f651789375d3a4782d8d7.png"
        />
      </div>
      <div class="buyad" @click="openVipToast">
        <img
          src="https://qncweb.ktvsky.com/20250117/vadd/5c9368d12f2d3fdc232821ae99c67a93.png"
        />
      </div>
      <div class="buyad2" @click="openVipToast2">
        <btnani></btnani>
      </div>
      <div class="album-mv-center-control-center">
        <div
          class="album-mv-center-control-center-pre"
          @click.stop="handleVideoControlReplay"
          :class="{ disabled: !canUsePre }"
        >
          <img
            src="https://qncweb.ktvsky.com/20231214/other/1357d97c550111adc5a8bc4c0eaeb625.svg"
          />
          <span>重唱</span>
        </div>
        <div
          v-if="isPlaying"
          class="album-mv-center-control-center-play"
          @click.stop="handleVideoControlPause(2)"
        >
          <img
            src="https://qncweb.ktvsky.com/20240903/vadd/f960e8f4d42266ceebe7a867e151367f.png"
            alt=""
          />
          <span>暂停</span>
        </div>
        <div
          v-else
          class="album-mv-center-control-center-play"
          @click.stop="handleVideoControlPlay"
        >
          <img
            src="https://qncweb.ktvsky.com/20240903/vadd/6788e639c0483da47aaa7e84c7475391.png"
            alt=""
          />
          <span>播放</span>
        </div>
        <div
          class="album-mv-center-control-center-next"
          @click.stop="handleVideoControlAudio"
          :class="{ disabled: !canUseNext }"
        >
          <!-- <img
            src="https://qncweb.ktvsky.com/20240903/vadd/ce29888e55ebbc9ce855835761652bf6.png"
            alt=""
          /> -->
          <img :src="iconConfig[audiokey]" />
          <span>{{ audiokey === 'acc' ? '原唱' : '伴唱' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VideoPlayer from '@/components/video-player/index.vue'
import useVip from '@/composables/useVip'
import logParams from '@/log'
import { sendLog } from '@/directives/v-log/log'
import { getSongToken } from '@/service/song'
import { getUserRoleTag } from '@/utils/historyCache'
import eventBus from '@/utils/event-bus'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import btnani from './btnani.vue'
import VideoProgress from './VideoProgress.vue'

const store = useStore()
const router = useRouter()
const isNewUser = ref(false)

const emit = defineEmits([
  'controlMvScreen',
  'controlNext',
  'controlPlay',
  'controlPause',
  'controlPre',
  'controlCanPlay',
])

const props = defineProps({
  isPlaying: {
    type: Boolean,
    default: false,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
  song: {
    type: Object,
    default: () => {},
  },
  canUsePre: {
    type: Boolean,
    default: true,
  },
  canUseNext: {
    type: Boolean,
    default: true,
  },
})

const { showVipQrcode } = useVip()
const muted = ref(false)
const userInfo = computed(() => store.state.userInfo)
const isLogin = computed(() => !!userInfo.value.unionid)
const currentTime = ref(0)
const duration = ref(0) // 假设视频总时长为 5 分钟
let token = ref('')

let isShowCenterControl = ref(true)
let controlTimer = null
const iconConfig = {
  org: 'https://qncweb.ktvsky.com/20231214/other/2d680ecf3ee5faf2edd30c46c02d5ef1.svg',
  acc: 'https://qncweb.ktvsky.com/20231214/other/8686717d1d0f8e06c2d90668cef93b50.svg',
}
let audiokey = ref('org')

const showControl = () => {
  isShowCenterControl.value = true
  clearTimeout(controlTimer)
  controlTimer = setTimeout(() => {
    isShowCenterControl.value = false
  }, 6000)
}
const handleTimeupdate = (info) => {
  duration.value = info.duration
  currentTime.value = info.currentTime
  handleUpdateCurrentTime(info.currentTime)
}

const auditionCurrentTime = computed(() => store.state.auditionCurrentTime)

const handleUpdateCurrentTime = (currentTime) => {
  // abs
  if (Math.abs(auditionCurrentTime.value - currentTime) > 1) {
    if (currentTime != 0) {
      store.commit('SET_AUDITION_CURRENT_TIME', currentTime)
    }
  }
}

const hideControl = () => {
  isShowCenterControl.value = false
  clearTimeout(controlTimer)
}
const handleClickBack = () => {
  // 返回
  sendLog(logParams.get(30337))
  store.commit('SET_VIP_AUDITION_SONG', {})

  router.go(-1)

  // resume
  store.commit('RESUME_VIDEO_PLAYER_HLS')

  // delay 3s reset auditionCurrentTime
  setTimeout(() => {
    store.commit('SET_AUDITION_CURRENT_TIME', 0)
  }, 3000)
}
const handleMvClick = () => {
  sendLog({
    event_type: '10000~50000',
    event_name: 30280,
    event_data: {
      str1: '小屏mv',
      str2: '小屏mv',
      str3: '点击',
      str4: 'click',
    },
  })
  if (!isShowCenterControl.value) {
    showControl()
  }
}
const handleVideoControlReplay = async () => {
  // 重唱上报
  sendLog(logParams.get(30334))
  console.log('replay')
  eventBus.emit('video-control-replay')
  eventBus.emit('irc-control-replay')
}
const handleControlClick = (event) => {
  event.stopPropagation()
  hideControl()
}

const videoPlayerRef = ref(null)

// 添加新方法来控制视频播放器
const playVideo = () => {
  // 播放 暂停
  sendLog(logParams.get(30335))
  if (videoPlayerRef.value && videoPlayerRef.value.handleControlVideoPlay) {
    videoPlayerRef.value.handleControlVideoPlay()
  }
}

// 左下角广告位
const openVipToast = () => {
  // 支付弹窗展示  视频暂停
  handleVideoControlPause()
  if (isLogin.value) {
    showVipQrcode({
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1912 : 1913,
      closeCallback: () => {
        // 视频重新播放
        handleVideoControlPlay()
      },
    })
    sendLog({
      ...logParams.get(30332),
      event_data: {
        ...logParams.get(30332).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
  } else {
    showVipQrcode({
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1910 : 1911,
      closeCallback: () => {
        // 视频重新播放
        handleVideoControlPlay()
      },
    })
    sendLog({
      ...logParams.get(30333),
      event_data: {
        ...logParams.get(30333).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
  }
}
// 视频播放完成后弹窗
const PlayEndToast = () => {
  if (isLogin.value) {
    sendLog({
      ...logParams.get(30339),
      event_data: {
        ...logParams.get(30339).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
    showVipQrcode({
      isVideoEnd: true,
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1912 : 1913,
      closeCallback: () => {
        // 点击关闭 返回上一页
        handleClickBack()
      },
    })
  } else {
    sendLog({
      ...logParams.get(30338),
      event_data: {
        ...logParams.get(30338).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
    showVipQrcode({
      isVideoEnd: true,
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1910 : 1911,
      closeCallback: () => {
        // 点击关闭 返回上一页
        handleClickBack()
      },
    })
  }
}
// 左下角广告位
const openVipToast2 = () => {
  // 支付弹窗展示  视频暂停
  handleVideoControlPause()
  if (isLogin.value) {
    showVipQrcode({
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1912 : 1913,
      closeCallback: () => {
        // 视频重新播放
        handleVideoControlPlay()
      },
    })
    sendLog({
      ...logParams.get(30332),
      event_data: {
        ...logParams.get(30332).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
  } else {
    showVipQrcode({
      log: isNewUser.value !== 2 ? '试唱新用户付费弹窗' : '试唱老用户付费弹窗',
      isLogin: isLogin.value,
      fr: isNewUser.value !== 2 ? 1910 : 1911,
      closeCallback: () => {
        // 视频重新播放
        handleVideoControlPlay()
      },
    })
    sendLog({
      ...logParams.get(30333),
      event_data: {
        ...logParams.get(30333).event_data,
        str5: isLogin.value ? '已登录' : '未登录',
        str7: isNewUser.value === 2 ? '2' : '1',
      }
    })
  }
}

const pauseVideo = () => {
  // 播放 暂停
  sendLog(logParams.get(30335))
  if (videoPlayerRef.value && videoPlayerRef.value.handleControlVideoPause) {
    videoPlayerRef.value.handleControlVideoPause()
  }
}

const handleControlFullScreen = () => {
  console.log('handleControlFullScreen')
  if (!props.isFullScreen) {
    handleControlMvScreen(1)
  }
}

const handleControlMvScreen = (type) => {
  console.log('handleControlMvScreen', type)
  if (type === 1) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30272,
      event_data: {
        str1: '车载DJ',
        str2: '小屏幕mv-全屏',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  if (type === 2) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30277,
      event_data: {
        str1: '车载DJ',
        str2: '全屏幕mv-退出',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  emit('controlMvScreen')
}

const handleVideoControlPre = () => {
  console.log('handleVideoControlPre')
  sendLog({
    event_type: '10000~50000',
    event_name: 30278,
    event_data: {
      str1: '车载DJ',
      str2: '全屏幕mv-上一首',
      str3: '点击',
      str4: 'click',
    },
  })
  emit('controlPre')
}

const handleVideoControlAudio = debounce(() => {
  // 原唱/伴唱
  sendLog(logParams.get(30336))
  eventBus.emit('switch-audio-track', {
    id: audiokey.value === 'acc' ? 0 : 1,
  })
  audiokey.value = audiokey.value === 'acc' ? 'org' : 'acc'
}, 500)

const handleVideoControlPlay = () => {
  console.log('handleVideoControlPlay')
  // 调用播放方法
  emit('controlPlay', () => {
    playVideo()
  })
}

const handleVideoControlPause = (type) => {
  console.log(type)
  emit('controlPause', () => {
    pauseVideo()
  })
}

const handleVideoEnded = () => {
  console.log('handleVideoEnded')
  PlayEndToast()
}

const handleCanPlay = () => {
  console.log('handleCanPlay')
  emit('controlCanPlay')
}

const handleRetryToken = async (error) => {
  const songData = await getSongToken()
  // const currServerTime = get(songData, 'serverTime', 0)
  token.value = get(songData, 'token', '')
  console.log('token', token.value)
}

watch(
  () => props.isPlaying,
  (newVal) => {
    if (newVal) {
      playVideo()
    } else {
      pauseVideo()
    }
  }
)

watch(
  () => props.canUsePre,
  (newVal) => {
    console.log('canUsePre changed:', newVal)
  }
)

onMounted(async () => {
  isNewUser.value = getUserRoleTag()
  const songData = await getSongToken()
  token.value = get(songData, 'token', '')
  console.log('token', token.value)
  showControl() // 初始显示控制区域
})

onUnmounted(() => {
  clearTimeout(controlTimer)
})
</script>

<style lang="stylus" scoped>
.album-mv
  width 100%
  height 100%
  position relative

  @media screen and (max-width 1200px) and (min-height 1200px)
    background url(https://qncweb.ktvsky.com/20250120/vadd/bc36cd5d65cdf093b712ce3d94f7b84a.png) no-repeat
    background-size 100%
    ::v-deep .video-player video
      top 0 !important

  &.fullscreen
    position fixed
    top 0
    left 0
    width 100vw !important
    height 100vh !important
    z-index 9999
    @media screen and (max-width 1200px) and (min-height 1200px)
      ::v-deep .video-player video
        top 168px !important

  .album-mv-video
    width 100%
    height 100%
    border-radius 0!important
    overflow hidden
    background-color #000

    .fullscreen &
      border-radius 0

  .album-mv-mini-control
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    .mv-bar
      position absolute
      display flex
      top 0
      left 0
      padding 24px
      justify-content space-between
      width 100%
      z-index 6
      span
        display flex
      .mv-bar-btn
        width 90px
        height 64px
        background rgba(17, 20, 24, 0.3)
        border-radius 12px
        display flex
        align-items center
        justify-content center

        @media screen and (max-width 1200px) and (min-height 1200px)
          width 99px
          height 70px

        &+.mv-bar-btn
          margin-left 12px
        .icon
          width 44px
          height 44px
          object-fit scale-down

          @media screen and (max-width 1200px) and (min-height 1200px)
            width 44px
            height 44px
  .album-mv-center-control
    width 100vw
    height 100vh
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    display flex
    align-items center
    justify-content center
    z-index 10
    .album-mv-center-control-back
      position absolute
      top 36px
      left 36px
      width 130px
      height 130px
      display flex
      justify-content center
      align-items center
      background rgba(30, 31, 33, 0.7)
      border-radius 10px
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 104px
        height 104px
        top 32px
        left 32px
      img
        width 40px
        height 40px
    .buyad
      position fixed
      left 20px
      bottom 108px
      width 277px
      height 60px
      @media screen and (max-width 1200px) and (min-height 1200px)
        bottom 1093px!important
      img
        width 100%
    .buyad2
      position fixed
      width 461px
      top 51px
      right 40px
      @media screen and (max-width 1200px) and (min-height 1200px)
        top 30px
    .album-mv-center-control-center
      width 828px
      height 120px
      // background rgba(17, 20, 24, 0.3)
      // border-radius 12px
      display flex
      align-items center
      justify-content space-between
      @media screen and (max-width 1200px) and (min-height 1200px)
        position absolute
        bottom 729px
        left calc(50% - 414px)
      .album-mv-center-control-center-pre
        width 260px
        height 120px
        background rgba(30, 31, 33, 0.7)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
        &.disabled
          opacity 0.3
          pointer-events none
      .album-mv-center-control-center-play
        width 260px
        height 120px
        background rgba(30, 31, 33, 0.7)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
      .album-mv-center-control-center-next
        width 260px
        height 120px
        background rgba(30, 31, 33, 0.7)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
</style>
