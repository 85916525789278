const logParams = new Map([
  [30300, {
    event_type: "10000~50000",
    event_name: 30300,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐",
      str3: "展示",
      str4: "show",
    },
  }],
  [30301, {
    event_type: "10000~50000",
    event_name: 30301,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-收起",
      str3: "点击",
      str4: "click",
    },
  }],
  [30302, {
    event_type: "10000~50000",
    event_name: 30302,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-换一批",
      str3: "点击",
      str4: "click",
    },
  }],
  [30303, {
    event_type: "10000~50000",
    event_name: 30303,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-点歌",
      str3: "点击",
      str4: "click",
    },
  }],
  [30304, {
    event_type: "10000~50000",
    event_name: 30304,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗",
      str3: "展示",
      str4: "show",
    },
  }],
  [30305, {
    event_type: "10000~50000",
    event_name: 30305,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-取消",
      str3: "点击",
      str4: "click",
    },
  }],
  [30306, {
    event_type: "10000~50000",
    event_name: 30306,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-减频确定",
      str3: "点击",
      str4: "click",
    },
  }],
  [30307, {
    event_type: "10000~50000",
    event_name: 30307,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-从不确定",
      str3: "点击",
      str4: "click",
    },
  }],
  [30308, {
    event_type: "10000~50000",
    event_name: 30308,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐",
      str3: "展示",
      str4: "show",
    },
  }],
  [30309, {
    event_type: "10000~50000",
    event_name: 30309,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-收起",
      str3: "点击",
      str4: "click",
    },
  }],
  [30310, {
    event_type: "10000~50000",
    event_name: 30310,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-换一批",
      str3: "点击",
      str4: "click",
    },
  }],
  [30311, {
    event_type: "10000~50000",
    event_name: 30311,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-点歌",
      str3: "点击",
      str4: "click",
    },
  }],
  [30312, {
    event_type: "10000~50000",
    event_name: 30312,
    event_data: {
      str1: "首页",
      str2: "随便听听",
      str3: "点击",
      str4: "click",
    },
  }],
  [30313, {
    event_type: "10000~50000",
    event_name: 30313,
    event_data: {
      str1: "首页",
      str2: "匹配对唱弹窗-立即预约",
      str3: "点击",
      str4: "click",
    },
  }],
  [30314, {
    event_type: "10000~50000",
    event_name: 30314,
    event_data: {
      str1: "首页",
      str2: "匹配对唱弹窗-无操作关闭",
      str3: "点击",
      str4: "click",
    },
  }],
  [30332, {
    event_type: "10000~50000",
    event_name: 30332,
    event_data: {
      str1: "试唱页",
      str2: "开通会员",
      str3: "点击",
      str4: "click",
    },
  }],
  [30333, {
    event_type: "10000~50000",
    event_name: 30333,
    event_data: {
      str1: "试唱页",
      str2: "开通会员",
      str3: "点击",
      str4: "click",
    },
  }],
  [30334, {
    event_type: "10000~50000",
    event_name: 30334,
    event_data: {
      str1: "试唱页",
      str2: "重唱",
      str3: "点击",
      str4: "click",
    },
  }],
  [30335, {
    event_type: "10000~50000",
    event_name: 30335,
    event_data: {
      str1: "试唱页",
      str2: "暂停/播放",
      str3: "点击",
      str4: "click",
    },
  }],
  [30336, {
    event_type: "10000~50000",
    event_name: 30336,
    event_data: {
      str1: "试唱页",
      str2: "原唱/伴唱",
      str3: "点击",
      str4: "click",
    },
  }],
  [30337, {
    event_type: "10000~50000",
    event_name: 30337,
    event_data: {
      str1: "试唱页",
      str2: "返回",
      str3: "点击",
      str4: "click",
    },
  }],
  [30338, {
    event_type: "10000~50000",
    event_name: 30338,
    event_data: {
      str1: "试唱页",
      str2: "播放结束付费弹窗",
      str3: "展示",
      str4: "show",
    },
  }],
  [30339, {
    event_type: "10000~50000",
    event_name: 30339,
    event_data: {
      str1: "试唱页",
      str2: "播放结束付费弹窗",
      str3: "展示",
      str4: "show",
    },
  }],
  [30340, {
    event_type: "10000~50000",
    event_name: 30340,
    event_data: {
      str1: "通用",
      str2: "试唱",
      str3: "点击",
      str4: "click",
    },
  }],
  [30341, {
    event_type: "10000~50000",
    event_name: 30341,
    event_data: {
      str1: "通用",
      str2: "试唱",
      str3: "点击",
      str4: "click",
    },
  }]
]);

export default logParams;