import axios from "axios";
import get from 'lodash/get';
import getHttpConfig from "./request/config";
import { reportError, showErrorToast } from "./request/error-handler";
import getRequestCommonParams from "./request/request-common-params";
import injectToken from "./request/response-token";

const http = axios.create(
  getHttpConfig({
    urls: {
      pro: '//x.ktvsky.com',
      pre: '//x.ktvsky.com',
      test: '//xpre.ktvsky.com' // x.stage
    },
  })
);

http.interceptors.request.use((request) => {
  if (request.method === "POST" || request.method === "DELETE") {
    if (!request.url.includes("/stb")) {
      request.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
  }
  if (request.url.includes("/stb") || request.url.includes("carplay")) {
    const commonParams = getRequestCommonParams(
      "84dcb88598ee46cebdd26b8b744625b1",
      (paramsQueryString) => {
        return paramsQueryString.replace(/[&]/g, "");
      }
    );
    request.params = {
      ...request.params,
      ...commonParams,
    };
  }
  return request;
});

http.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.errcode === 200 || res.errcode === 21001 || res.code === 200) {
      res.data = injectToken(response.headers, res.data);
      return res;
    }
    // 过滤签到活动的toast提示
    if (!get(res, 'errmsg', '').includes('签到')) showErrorToast(res.errmsg);
    return res;
  },
  (error) => {
    reportError(error);
    if (error.toJSON().message.includes("timeout of 15000ms")) {
      showErrorToast("页面加载超时，请退出后重试");
      return;
    }
    showErrorToast();
  }
);

export default http;
