import { computed, inject, watch } from 'vue'
// import { useStore } from 'vuex'
import { sendLog } from '@/directives/v-log/log'
import store from '@/store'
import { getRetainModalTag } from '@/utils/historyCache'
import get from "lodash/get"

let fromType = ''
let xifenQrcodeInstance = null
let lowerVipQrcodeInstance = null
let vipQrcodeInstance = null

export default function useVip() {
  // const store = useStore()
  const $vipQrcode = inject('$vipQrcode')
  const $xifenQrcode = inject('$xifenQrcode')
  const $lowerVipQrcode = inject('$lowerVipQrcode')
  
  // const vipQrcodeInstance = ref(null)

  const isLogin = computed(() => !!store.state.userInfo.unionid)
  const userType = computed(() => store.state.userInfo.userType)

  const isVipUser = computed(() => {
    if (store.state.vipInfo?.end_time) {
      const vipEndDate = new Date(store.state.vipInfo.end_time).getTime()
      const now = Date.now()
      return now <= vipEndDate
    }
    return false
  })

  const showLowerVipQrcode = (obj) => {
    console.log('showLowerVipQrcode', lowerVipQrcodeInstance)
    if (lowerVipQrcodeInstance) return

    lowerVipQrcodeInstance = $lowerVipQrcode.show({
      ...obj,
      isLogin: isLogin.value,
      onHide: () => {
        lowerVipQrcodeInstance = null
      }
    })
  }

  const showNormalVipQrcode = (obj) => {
    console.log('showNormalVipQrcode', vipQrcodeInstance)
    if (vipQrcodeInstance) return

    console.log("showVipQrcode 1",$vipQrcode)
    vipQrcodeInstance = $vipQrcode.show({
      ...obj,
      onHide: () => {
        vipQrcodeInstance = null
        obj && obj.onHide && obj?.onHide();
      }
    })
  }

  const showVipQrcode = (obj) => {
    if (vipQrcodeInstance) {
      return
    }
    fromType = obj?.fromType
    let songData = get(obj, 'songid', false) ? {
      song_id: obj.songid
    } : {}
    sendLog({
      event_type: 'show',
      event_name: 256,
      event_data: {
        source: get(obj, 'src', ''), // 新增来源场景字段
        ...songData,
      }
    })
    if (obj?.fromType === 'vipHighQuality') {
      sendLog({
        event_type: '10000~50000',
        event_name: 30179,
        event_data: {
          str1: '欢唱页',
          str2: '画质选择',
          str3: '1080P按钮',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
    }
    
    // 已触发过挽留弹窗的非vip用户，显示低价码，否则显示正常vip码
    if (getRetainModalTag()) {
      showLowerVipQrcode(obj)
    } else {
      showNormalVipQrcode(obj)
    }

    store.dispatch('startCheckLoginStatus')
  }

  const handleIsLoginChange = (val) => {
    if (val && vipQrcodeInstance && fromType != 'climax') {
      console.log('handleIsLoginChange', vipQrcodeInstance, fromType)
      vipQrcodeInstance.hide()
    }
  }

  const handleIsVipChange = (val) => {
    if (val && vipQrcodeInstance) {
      console.log('handleIsVipChange', vipQrcodeInstance)
      vipQrcodeInstance.hide()
    }
    if (val && lowerVipQrcodeInstance) {
      console.log('handleIsVipChange', lowerVipQrcodeInstance)
      lowerVipQrcodeInstance.hide()
    }
  }

  const showXifenQrcode = (payload) => {
    console.log('showXifenQrcode', xifenQrcodeInstance)
    if (xifenQrcodeInstance) return

    xifenQrcodeInstance = $xifenQrcode.show({
      payload,
      onHide: () => {
        xifenQrcodeInstance = null
      }
    })
  }

  watch(isLogin, handleIsLoginChange)

  watch(isVipUser, handleIsVipChange)

  return {
    isLogin,
    isVipUser,
    showVipQrcode,
    vipQrcodeInstance,
    showXifenQrcode,
  }
}
