<template>
  <div class="search-history" v-show="isShow">
    <div class="search-history-title">
      <span>搜索历史</span>
      <svg
        @click="handleDeleteSearchCache(-1)"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path d="M12.8008 3H19.2008V5H12.8008V3Z" fill="#1D1D1F" />
          <path d="M4 7.19995H28V9.19995H4V7.19995Z" fill="#1D1D1F" />
          <path
            d="M10.154 27.8L7.54062 8.19995H24.4563L21.8429 27.8H10.154Z"
            stroke="#1D1D1F"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <div class="search-history-content">
      <div
        class="search-history-content-item"
        :class="{
          'search-history-content-item-active': false, // keyword === searchItem
        }"
        :key="index"
        @click="handleClickSearchTab(searchItem)"
        v-for="(searchItem, index) in searchCacheList"
      >
        <span>{{ searchItem }}</span>
        <img
          class="search-history-content-item-d-img"
          @click.stop="handleDeleteSearchCache(searchItem, index)"
          src="https://qncweb.ktvsky.com/20241129/vadd/4ec7d6adea32e107dcce9ad1cdff688a.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import useOrder from '@/composables/useOrder'
import useSongItem from '@/composables/useSongItem'
import { setSearchCache } from '@/utils/historyCache'
import { Dialog } from 'vant'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SearchHistory',
  setup(props, { emit }) {
    const store = useStore()
    const { orderedListNumber, orderSong, addSong } = useOrder()
    const { validSong } = useSongItem()
    const searchCacheList = computed(() => store.state.search.searchCache)
    const searchSongList = computed(() => store.state.search.searchSong)
    const searchSong = computed(() => {
      let res = {}
      searchSongList.value.some((v) => {
        if (searchCacheList.value.includes(v.searchname)) {
          res = v
          return true
        }
        return false
      })
      return res
    })
    const isShow = computed(() => !!searchCacheList.value.length)

    const isCanOrder = (song) => {
      if (!song || !Object.keys(song).length || !song.songid) {
        // 无歌曲
        return false
      }
      return validSong(song)
    }

    const goSing = (song) => {
      // 歌曲数据校验
      if (isCanOrder(song)) {
        addSong(song, {
          from: {
            song_list_source: 5,
          },
        })
        orderSong(song, orderedListNumber.value - 1)
      }
    }

    const handleGoSing = (item) => {
      // 一键演唱
      if (searchSong.value.searchname === item) {
        goSing(searchSong.value)
      }
    }

    const handleClickSearchTab = (item) => {
      // 搜索
      emit('click-word', item)
    }

    const handleDeleteSearchCache = (searchItem, index) => {
      if (searchItem === -1) {
        Dialog.confirm({
          message: '您确定要删除所有的历史记录吗？',
        }).then(() => {
          store.dispatch('search/clearSearchCache')
          emit('delete-word', searchItem)
        })
        return
      }
      let newSearchCacheList = [...searchCacheList.value]
      newSearchCacheList.splice(index, 1)
      store.dispatch('search/updateSearchCache', newSearchCacheList)
      store.dispatch('search/deleteSearchSong', searchItem)
      setSearchCache(newSearchCacheList)
      emit('delete-word', searchItem)
    }

    return {
      isShow,
      searchSong,
      searchCacheList,
      handleGoSing,
      handleClickSearchTab,
      handleDeleteSearchCache,
    }
  },
}
</script>

<style lang="stylus" scoped>
.search-history
  width 100%
  margin-bottom 40px
  @media screen and (max-width 1200px) and (min-height 1200px)
    margin-top 40px
  &-title
    width 100%
    height 33px
    display flex
    flex-direction row
    align-items center
    color rgba(29, 29, 31, 0.6)
    span
      font-weight 600
      font-size var(--font-size-32)
      margin-right 12px
    svg
      width 36px
      height 36px
  &-content
    width 100%
    margin-top 40px
    display flex
    flex-direction row
    flex-wrap wrap
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 172px !important
    &-item
      max-width 700px
      height 74px
      display flex
      align-items center
      justify-content space-between
      border-radius 12px
      background rgba(29, 29, 31, 0.08)
      padding 0 24px
      margin-right 24px
      margin-bottom 24px
      &-active
        background rgba(255, 255, 255, 0.15)
      span
        max-width 447px
        height 70px
        line-height 70px
        font-size var(--font-size-32)
        color #1D1D1F99
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        margin-right 24px
      &-s-img
        width 30px
        height 30px
        margin-right 15px
      &-d-img
        width 24px
        height 24px
</style>
