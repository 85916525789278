import { useActivityRetainVip } from '@/components/modal/global/activity-modal/create';
// import createRetainModal from '@/components/modal/global/retain-modal/create';

export default function useRetainModal() {
    // const $retainModal = createRetainModal()
    // const retainModalInstance = ref(null)
    const showRetainModal = () => {
        // retainModalInstance.value = $retainModal.show()

        // 组件调整
        return showActivityRetainVip()
    }

    const showActivityRetainVip = () => {
        const $activityLowerVip = useActivityRetainVip()
        $activityLowerVip.show()
      }
      
    // 返回包含所有函数的对象
    return {
        showRetainModal,
    }
}